<template>
  <div class="Examine">
    <div style="position: fixed;top: 55px;right: 10px;">
      <el-button size="small" @click="dataadd" type="primary">{{$t('All.新增')}}</el-button>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" :label="$t('All.序号')" type="index" width="60">
          </el-table-column>
          <el-table-column align="center" prop="Date" :label="$t('All.创建日期')" width="180">
          </el-table-column>
          <el-table-column align="center" prop="Productname" :label="$t('All.品名规格')">
          </el-table-column>
          <el-table-column align="center" prop="Customer" :label="$t('All.客户')">
          </el-table-column>
          <el-table-column align="center" prop="Enteredby" :label="$t('All.录入人')" >
          </el-table-column>
          <el-table-column align="center" :label="$t('All.操作')" width="100" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="AdminDataSelect(scope.row.Productname)" type="primary">Review</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      factory: this.$store.state.Login.Factory
    }
  },
  created () {
    this.OQCAdminData(this.pageindex, this.page_size)
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData']),
    ...mapMutations(['Productname']),
    async OQCAdminData (index, size) {
      const { data: res } = await this.$http.get(`/api/User/OQCAdminData?pageindex=${index}&pageSize=${size}&factory=${this.factory}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.tableData = res.response.ZhFqc
    },
    handleSizeChange (val) {
      this.page_size = val
      this.OQCAdminData(this.pageindex, this.page_size)
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.OQCAdminData(this.pageindex, this.page_size)
    },
    async AdminDataSelect (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const postdata = {
          Name: row,
          Factory: this.$store.state.Login.Factory
        }
        const { data: res } = await this.$http.post('/api/Zh/AdminDataSelect', postdata)
        if (res.status !== 200) {
          loadingInstance.close()
          this.UserData(null)
          this.$message.success(this.$t('All.数据不存在'))
          return
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.即将进入编辑页面'))
        this.Productname(this.Noitem)
        this.UserData(res.response)
        this.$store.state.TabComponent = 'OQCAdmin'
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    dataadd () {
      this.$store.state.TabComponent = 'OQCSeleItemAdmin'
    }
  }
}
</script>
<style lang="less" scoped>

</style>
