import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './plugins/element.js'
import './assets/CSS/index.css'
import './assets/CSS/iconfont.css'
import './assets/CSS/QR/iconfont.css'
import PublicJs from './assets/JS/PublicJs.js'
import axios from 'axios'
import i18n from './i18n'
// 导入nprogress加载进度条包即样式
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import 'vue-virtual-scroller/dist/vue-virtual-scroller.css' // 引入它的 css
import VueVirtualScroller from 'vue-virtual-scroller' // 引入它
import Print from 'vue-print-nb'
import * as echarts from 'echarts'
import VueDirectiveWindow from 'vue-directive-window'
import '@wangeditor/editor/dist/css/style.css'
import JsonExcel from 'vue-json-excel'
// import locale from 'element-ui/lib/locale/lang/zh-CN' // lang i18n
// import locale from "element-ui/lib/locale/lang/en";

Vue.component('downloadExcel', JsonExcel)
Vue.prototype.$echarts = echarts // 将echarts引入与到vue的原型中，方便使用
Vue.use(VueVirtualScroller)
Vue.use(VueDirectiveWindow)
// 打印
Vue.use(Print)

Vue.prototype.$PublicJs = PublicJs
Vue.config.productionTip = false
Vue.prototype.$weburl = 'https://qc.nienyi.cn/'
Vue.prototype.$Upload = 'https://qcapi.nienyi.cn' // 'https://qcapi.nienyi.cn'
axios.defaults.baseURL = 'https://qcapi.nienyi.cn' // 'http://120.195.84.42:5006/' http://192.168.0.199:5006
Vue.prototype.$view = 'https://view.nienyi.cn/'
Vue.prototype.$http = axios
axios.interceptors.request.use(config => {
  NProgress.start()
  config.headers.Authorization = 'Bearer ' + window.sessionStorage.getItem('token')
  return config
})
axios.interceptors.response.use(config => {
  NProgress.done()
  return config
})
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

// locale.el.pagination = {
//   pagesize: this.$t('PublicJs.条页'),
//   total: `${this.$t('PublicJs.共')} {total} ${this.$t('PublicJs.条')}`,
//   goto: this.$t('PublicJs.前往'),
//   pageClassifier: this.$t('PublicJs.页')
// }
