<template>
    <div class="container">
        <div class="content">
            <div class="title">
                <h2>Report</h2>
            </div>
                <el-form ref="form" :model="Getdata" label-width="100px" style="max-width: 400px;">
                  <el-form-item label="Item：">
                    {{ Getdata.Item_No }}
                  </el-form-item>
                  <el-form-item label="Workorder：">
                    {{ Getdata.Order_no }}
                  </el-form-item>
                  <el-form-item label="Product：">
                    {{ Getdata.Product_name }}
                  </el-form-item>
                  <el-form-item label="Quantity：">
                    {{ Getdata.Boxquantity }}
                  </el-form-item>
                  <el-form-item label="Report：">
                    {{ Getdata.Numbers }}
                  </el-form-item>
                </el-form>
            <div class="btn" v-if="Btn">
                <el-button type="primary" @click="Login" >请登录后查看</el-button>
            </div>
            <div class="formcontent" v-if="isform">
                <el-collapse v-model="activeNames" @change="handleChange" class="zhedie">
                <el-collapse-item title="IPQC - Report" name="1">
                    <div class="ipqctitle">
                      <el-tabs v-if="IPQCreport" v-model="IPQCactiveName" style="width: 100%;height: 100%;" @tab-click="Selectipqc(IPQCactiveName)">
                        <el-tab-pane :label="item" :name="item" v-for="(item, index) in IPQCreport" :key="index">
                          <IPQCExamine v-if="Ipqcselet === item" :ipqcdata="ipqcdata"></IPQCExamine>
                        </el-tab-pane>
                      </el-tabs>
                    </div>
                </el-collapse-item>
                <el-collapse-item title="FQC - Report" name="2">
                    <div class="FQCform">
                      <el-tabs  v-if="FQCreport" v-model="FQCactiveName" style="width: 100%;height: 100%;" @tab-click="Selectfqc(FQCactiveName)">
                        <el-tab-pane :label="item" :name="item" v-for="(item, index) in FQCreport" :key="index">
                        <NewFQCexaminedata v-if="fqcselet === item" :fqcdata="fqcdata"></NewFQCexaminedata>
                        </el-tab-pane>
                      </el-tabs>
                    </div>
                </el-collapse-item>
                </el-collapse>
                <span style="font-size: 25px;color: red; position: relative; top: 5px;">*</span><span style="color: red;">建议复制网址至PC端查看完整报告</span> <br>
                <span style="font-size: 25px;color: red; position: relative; top: 5px;">*</span><span style="color: red;">Suggest copying the website to PC to view the complete report</span> <br>
                <span style="font-size: 25px;color: red; position: relative; top: 5px;">*</span><span style="color: red;">Đề nghị sao chép địa chỉ web đến PC để xem báo cáo đầy đủ</span>
            </div>
        </div>
        <el-dialog title="登录信息" :visible.sync="dialogFormVisible" append-to-body>
            <div class="formcontent">
                <el-form :model="form" class="formstyle" ref="form" :rules="rules">
                    <el-form-item>
                        <el-input v-model="form.User" autocomplete="off" placeholder="请输入账号" clearable></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.Password" autocomplete="off" type="Password" placeholder="请输入密码" clearable></el-input>
                    </el-form-item>
                    <el-form-item prop="pictureCode">
                    <el-input v-model="pictureCode" autocomplete="off" class="pictureCode"
                    placeholder="请输入验证码" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')"  @keyup.enter.native="UserLogin(form)">
                        <template slot="append">
                        <div style="border: none" @click="refreshCode">
                            <!-- 3. 使用 Sidentify 组件 -->
                            <Sidentify :identifyCode="identifyCode"></Sidentify>
                        </div>
                        </template>
                    </el-input>
                    </el-form-item>
                </el-form>
            </div>
        <div slot="footer" class="dialog-footer">
            <el-button @click="Close">取 消</el-button>
            <el-button type="primary" @click="UserLogin(form)">确 定</el-button>
        </div>
        </el-dialog>
    </div>
  </template>

<script>
import { mapMutations } from 'vuex'
import Sidentify from '../../components/Sidentify.vue'
import NewFQCexaminedata from './NewFQCexaminedata.vue'
import IPQCExamine from './IPQCExamine.vue'
export default {
  components: {
    Sidentify,
    NewFQCexaminedata,
    IPQCExamine
  },
  data () {
    return {
      FQCreport: [],
      IPQCreport: [],
      Fqcselet: '',
      Ipqcselet: '',
      IPQCactiveName: '',
      FQCactiveName: '',
      activeNames: [''],
      isform: false,
      Btn: true,
      dialogFormVisible: false,
      form: {
        User: '',
        Password: ''
      },
      formLabelWidth: '60px',
      pictureCode: '', //  输入input框的验证码
      identifyCode: '', //  存储的图片验证码
      rules: {
        User: [
          { required: true, message: '请输入账号', trigger: 'blur' }
        ],
        Password: [
          { required: true, message: '请输入密码', trigger: 'blur' }
        ]
      },
      Getdata: {},
      ipqcdata: {},
      fqcdata: {},
      fqcselet: ''
    }
  },
  methods: {
    ...mapMutations(['UserData']),
    handleChange (val) {
      console.log(val)
    },
    async Selectfqc (row) {
      const { data: res } = await this.$http.get('/api/FQC/NewFQCUserSelect?Numbers=' + row)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.fqcdata = res.response
      this.fqcselet = row
    },
    async Selectipqc (row) {
      const { data: res } = await this.$http.get('/api/IPQC/IPQCUserSelect?Numbers=' + row)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.ipqcdata = { ...res.response }
      this.Ipqcselet = row
    },
    Login () {
      this.dialogFormVisible = true
    },
    Close () {
      this.dialogFormVisible = false
    },
    // 获取随机图片验证码
    refreshCode () {
      var numCode = ''
      for (var i = 0; i < 4; i++) {
        numCode += Math.floor(Math.random() * 10)
      }
      this.identifyCode = numCode
    },
    UserLogin (row) {
      if (this.pictureCode !== this.identifyCode && this.pictureCode !== '') {
        this.refreshCode()
        this.$message.error('验证码有误，请重新输入')
      } else if (this.pictureCode === '') {
        this.$message.error('验证码不能为空')
      } else {
        this.$refs.form.validate(async (valid) => {
          if (!valid) return this.$message.error('账号密码不能为空')
          const { data: res } = await this.$http.post('/api/Authenticate/login', row)
          if (res.status !== 200) return this.$message.error('登录失败')
          this.$message.success('登录成功')
          this.dialogFormVisible = false
          this.isform = true
          this.Btn = false
        })
      }
    },
    // http://localhost:5006/api/FQC/Getnewfqcqrcode?Qrcode=TC202404155688480510941075020
    async Getnewfqcqrcode (Qrcode) {
      const { data: res } = await this.$http.post('/api/FQC/Getnewfqcqrcode?Qrcode=' + Qrcode)
      if (res.status !== 200) return this.$message.error('获取二维码数据失败')
      this.Getdata = res.response
      this.FQCreport = res.FQC
      this.IPQCreport = res.IPQC
    }
  },
  watch: {
  },
  created () {
    this.Getnewfqcqrcode(this.$route.params.numbers)
  },
  mounted () {
    this.refreshCode()
  }
}
</script>

<style lang="less" scoped>
.container {
    width: 100%;
}
.content {
  height: 100%;
}
.title {
    text-align: center;
}
.zhedie {
    padding: 15px;
}
.formcontent {
  padding: 15px;
}
.FQCform {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
.btn {
    text-align: center;
}
/deep/ .el-dialog {
    max-width: 400px;
    min-width: 360px;
}
::v-deep .pictureCode .el-input-group__append {
  padding: 0;
}
/deep/ .el-tabs__nav {
  overflow: hidden;
}
</style>
