<template>
  <div class="erpclass" style="height: 100%;">
    <el-container>
      <el-aside width="415px">
        <div style="text-align: center;">
            <img class="logoimg"  src="../assets/Img/NIENYI-2.png" alt="">
        </div>
        <div style="padding: 5px;">
          <el-input
            placeholder="请输入内容"
            v-model="text">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <el-table :data="tableData" style="width: 100% " height="calc(100vh - 159px)" @row-click="rowclick">
          <el-table-column prop="Date" sortable  label="日期" align="center">
            <template slot-scope="scope">
              {{ formatDate(scope.row.Date) }}
            </template>
          </el-table-column>
          <el-table-column prop="Wadoco" sortable label="工单" align="center"> </el-table-column>
          <el-table-column prop="Amountratio" sortable label="金额比例" align="center">
            <template slot-scope="scope">
              {{ scope.row.Amountratio}}%
            </template>
          </el-table-column>
          <el-table-column prop="Dosageratio" sortable label="用量比例" align="center">
            <template slot-scope="scope">
              {{ scope.row.Dosageratio }}%
            </template>
          </el-table-column>
        </el-table>
      </el-aside>
      <el-main>
        <div class="zhadminadd" id="printTest">
            <h2 style="text-align: center;">{{ this.$t('system.company') }}</h2>
            <h3 style="text-align: center;">ERP物料与生产使用物料</h3>
            <h4 style="text-align: center;">{{ erpdata.Wadoco }}</h4>
            <div style=" padding: 10px; text-align: center;">
              <div style="text-align: center;">
                <el-table :data="ERPMaterial" show-summary :summary-method="getSummaries" style="width: 100%;padding: 10px; text-align: center;">
                  <el-table-column type="expand">
                    <template slot-scope="scope">
                      <el-table :data="scope.row.Material">
                        <el-table-column type="index" width="50"> </el-table-column>
                        <el-table-column prop="IQCNumbers" width="50" align="center" label="报告">
                          <template slot-scope="scopes">
                            <i v-if="scopes.row.IQCNumbers.length > 0" @click="openfunurl(scopes.row.IQCNumbers)" class="el-icon-s-order" style="text-align: center;font-size: 20px;"></i>
                          </template>
                        </el-table-column>
                        <el-table-column prop="Supplier" sortable :label="$t('All.供应商')"> </el-table-column>
                        <el-table-column prop="Feeding_date" sortable label="进料日期"> </el-table-column>
                        <el-table-column prop="Person" sortable label="负责人"> </el-table-column>
                        <el-table-column prop="Quantity" sortable :label="$t('All.数量')" width="80"> </el-table-column>
                        <el-table-column prop="Unit" :label="$t('All.单位')"> </el-table-column>
                        <el-table-column prop="Lot_no" label="LOT NO"> </el-table-column>
                        <el-table-column prop="Username" sortable label="扫描者"> </el-table-column>
                      </el-table>
                    </template>
                  </el-table-column>
                  <el-table-column type="index" width="50"> </el-table-column>
                  <el-table-column prop="Wmcpil" :label="$t('All.料号')" align="center"></el-table-column>
                  <el-table-column prop="Wmdsc1" :label="$t('All.品名')" align="center">
                  </el-table-column>
                  <el-table-column prop="Wmdsc2" :label="$t('All.规格')" align="center">
                  </el-table-column>
                  <el-table-column prop="Wmuorg" label="标准量" align="center">
                  </el-table-column>
                  <el-table-column prop="Wmtrqt" label="已发量" align="center">
                  </el-table-column>
                  <el-table-column prop="Sjshiyongl" label="实际使用量" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.Wmum === 'GM' ? scope.row.Sjshiyongl * 1000 : scope.row.Sjshiyongl }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="Dosageratio" label="用量比例" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.Dosageratio }}%
                    </template>
                  </el-table-column>
                  <el-table-column prop="Standardamount" label="标准金额" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.Standardamount }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="Actualamount" label="实际金额" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.Actualamount }}
                    </template>
                  </el-table-column>
                  <el-table-column prop="Amountratio" label="金额比例" align="center">
                    <template slot-scope="scope">
                      {{ scope.row.Amountratio }}%
                    </template>
                  </el-table-column>
                  <el-table-column prop="Wmum" :label="$t('All.单位')" align="center">
                  </el-table-column>
                </el-table>
              </div>
            </div>
          </div>
      </el-main>
    </el-container>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Material: [{
        Numbers: '',
        Index: 0,
        Quantity: '',
        IQCNumbers: '',
        Material_name: '',
        Supplier: '',
        Feeding_date: '',
        Person: '',
        Lot_no: '',
        Name: '',
        Serialcode: ''
      }],
      TabComponent: '',
      MaterialData: {},
      ERPMaterial: [],
      isendbool: false,
      tableData: [],
      erpdata: {},
      text: ''
    }
  },
  components: {
  },
  created () {
    this.GetEndErp()
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['FQCNumerb', 'TabComponentFun']),
    Iscoder (row) {
      this.TabComponent = row
    },
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => { })
    },
    getCurrentTime () {
      // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const gettime = yy + '-' + mm + '-' + dd
      return gettime
    },
    async GetEndErp () {
      const { data: res } = await this.$http.get('/api/Semifinished/Huizong?')
      if (res.status !== 200) return this.$message.error(res.msg)
      this.tableData = res.response
    },
    //  时间字符串格式化 yyyy-mm-dd
    formatDate (date) {
      var d = new Date(date)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()
      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day
      return [year, month, day].join('-')
    },
    rowclick (row) {
      this.erpdata = row
      this.GetgutEndwuliao(row.Wadoco)
    },
    async GetgutEndwuliao (row) {
      const { data: res } = await this.$http.get(`/api/Semifinished/GetgutEndwuliao?gongdan=${row}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.ERPMaterial = res.response
    },
    openfunurl (url) {
      window.open('#/vnewiqc/' + url)
    },
    getSummaries (param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 1) {
          sums[index] = '合计'
          return
        }
        if ([0, 2, 3, 4, 12].includes(index)) {
          sums[index] = ''
          return
        }
        const values = data.map(item => Number(item[column.property]))
        if (!values.every(value => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
        } else {
          sums[index] = 'N/A'
        }
      })

      return sums
    }
  }
}
</script>
<style lang="less" scoped>
.wbobye {
  position: absolute/fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  height: 100%;
  background-color: rgba(255, 255, 255);
  min-width: 903px;
  width: 100%;
}

.el-col {
  border: 1px solid;
  margin-right: -1px;
  margin-bottom: -1px;
  line-height: 4.5vw;
  font-size: 2vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-content {
  min-height: 36px;
}

.bg-purple-light {}

.row-bg {
  background-color: #f9fafc;
}

table .td {
  border: none;
}
.logoimg{
    height: 100px;
    width: 350px;
    margin-top: 5px;
}
.el-aside {
    height: 100%;// 设置左侧 aside 高度
}
.el-container{
    height: 100%;
}
</style>
