<template>
  <div class="zhadminadd">
    <div style="position: fixed;top: 55px;right: 10px; z-index: 1000;">
      <el-button type="primary"  v-if="isdata"  @click="IQC_Admin()">{{ $t('All.提交') }}</el-button>
      <el-button type="primary"  v-if="!isdata" @click="UserDataUp()">{{$t('All.更新')}}</el-button>
      <el-button type="danger" v-if="!isdata" @click="Delete()">Delete</el-button>
    </div>
    <h2 style="text-align: center;">{{$t('All.年益实业股份有限公司')}}</h2>
    <h3 style="text-align: center;">{{$t('All.IQC进料检验资料')}}</h3>
    <h4 style="text-align: center;">{{$t('All.录入人')}}：{{form.Enteredby}}</h4>
    <div style=" padding: 10px; text-align: center; line-height: 2vw;">
      <table border="1"  align="center" cellpadding="10" style="width: 100%;"  cellspacing="0">
        <tr>
          <td>{{$t('All.料号')}}</td>
          <td class="tdd"><el-input v-model="form.Item_No"></el-input></td>
        </tr>
        <tr>
          <td>{{$t('All.品名规格')}}</td>
          <td class="tdd"><el-input v-model="form.Product_name"></el-input></td>
        </tr>
        <tr>
          <td>{{$t('All.工程图面')}}</td>
          <td class="tdd"><el-input v-model="form.Drawing_Surface"></el-input></td>
        </tr>
        <tr>
          <td>{{$t('All.承认书')}}</td>
          <td class="tdd"><el-input v-model="form.Acknowledgment"></el-input></td>
        </tr>
        <tr>
          <td>{{$t('All.检验基准表')}}</td>
          <td class="tdd"><el-input v-model="form.Inspection_Table"></el-input></td>
        </tr>
        <tr>
          <td>{{$t('All.测试仪器编号')}}</td>
          <td class="tdd"><el-input v-model="form.Yqi1"></el-input></td>
        </tr>
      </table>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      form: {
        Item_No: '',
        Product_name: '',
        Drawing_Surface: '',
        Acknowledgment: '',
        Inspection_Table: '',
        Yqi1: ''
      },
      isdata: false,
      value: localStorage.getItem('locale') || ''
    }
  },
  created () {
    if (this.$store.state.UserData === null) {
      this.isdata = true
      this.form.Enteredby = this.$store.state.Login.Username
      this.form.Item_No = this.$store.state.Productname
      this.form.Factory = this.$store.state.Login.Factory
    } else {
      this.isdata = false
      this.form = this.$store.state.UserData
    }
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['UserData', 'TabComponentFun']),
    getCurrentTime () {
      // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const gettime = yy + this.$t('All.年') + mm + this.$t('All.月') + dd + this.$t('All.日')
      return gettime
    },
    async IQC_Admin () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/IQC/IQCAdminFQC', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.TabComponentFun('IQCAdminDataFqc')
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async UserDataUp () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/IQC/IQCAdminFQCUp', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.提交成功'))
        this.TabComponentFun('IQCAdminData')
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async Delete () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/IQC/IQCAdminFQCDel', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.删除成功'))
        this.TabComponentFun('IQCAdminData')
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
  min-width: 903px;
}
  .el-col {
    border: 1px solid;
    margin-right: -1px;
     margin-bottom: -1px;
    line-height: 4.5vw;
    font-size: 2vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .grid-content {
    min-height: 36px;
  }
  .bg-purple-light{

  }
  .row-bg {
    background-color: #f9fafc;
  }
</style>
