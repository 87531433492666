<template>
  <div style="width:794px;margin: auto;">
    <div style="text-align: right;padding-top: 10px;">
      <el-button size="small" type="primary" v-print="printObj3">打印报告</el-button>
    </div>
    <el-tabs @tab-click="handleClick">
    <el-tab-pane v-for="(item,index) in form" :key="index" :label="item.Product_item" >
      <div :id="'printxrf' + index" style="width:794px;margin: auto;">
          <img src="../assets/Img/NIENYI-2.png" alt="年益集团" style="width: 100px;position: absolute;margin-top: 0px;margin-left: 150px;">
        <h2 style="text-align: center;">年益实业股份有限公司</h2>
        <h3 style="text-align: center;line-height: 10px;">实验报告(Test report)</h3>
        <h4 style="text-align: center;line-height: 10px;">通知单号：{{formData.Numbers}}</h4>
        <span style="width: 100px;position: absolute;margin-top: -20px;margin-left: 690px;">{{ formData.Gongdan }}</span>
        <table border="1"  style="width: 98%;  margin: 0 auto;" class="table-layout">
          <tr>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">样品名称/规格<br>Sample Name</p>
            </td>
            <td style="min-width: 397px;max-width: 397px;" colspan="3">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;word-wrap: break-word;">{{ item.Product_item }}</p>
            </td>
            <td style="width: 256px;text-align: center;" colspan="2" rowspan="5">
              <img :src="`${$Upload}/QCFile/Laboratory/${item.Numbers}/4/${item.Product_item}/0.png`" alt="年益集团" style="width: 100%;display: table-cell;vertical-align: middle;">
            </td>
          </tr>
          <tr>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">供应商<br>Supplier</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ item.Supplier }}</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">操作员<br>Operator</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ item.Name }}</p>
            </td>
          </tr>
          <tr>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">测量日期<br>Test Date</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ item.Test_date }}</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">测量时间<br>Test Time</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ item.Test_time }}</p>
            </td>
          </tr>
          <tr>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">仪器型号<br>Mode</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ item.Mode }}</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">工作曲线<br>WorkCurve</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ item.Workcurve }}</p>
            </td>
          </tr>
          <tr>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">管压<br>Voltage</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ item.Voltage }}</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">管流<br>Current</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ item.Current }}</p>
            </td>
          </tr>
          <tr>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">元素<br>Element</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">强度<br>Intensity</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">含量(ppm)<br>Content</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">误差(ppm)<br>Error</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">限定标准<br>Limits</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">判定<br>Results</p>
            </td>
          </tr>
          <tr v-for="(items,indexs) in formtable" :key="indexs">
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ items.Element }}</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ items.Intensity }}</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ items.Content }}</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ items.Error }}</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ items.Limits }}</p>
            </td>
            <td style="width: 132px;text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ items.Results }}</p>
            </td>
          </tr>
          <tr>
            <td style="text-align: center;" colspan="1">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">谱图<br>Spectrogram</p>
            </td>
            <td style="width: 256px;text-align: center;" colspan="5">
              <img :src="`${$Upload}/QCFile/Laboratory/${item.Numbers}/4/${item.Product_item}/1.png`" alt="年益集团" style="width: 100%;display: table-cell;vertical-align: middle;">
            </td>
          </tr>
          <tr>
            <td style="text-align: center;" colspan="6">
              <img :src="`${$Upload}/QCFile/Laboratory/${item.Numbers}/4/${item.Product_item}/2.png`" alt="年益集团" style="width: 100%;display: table-cell;vertical-align: middle;">
            </td>
          </tr>
          <tr>
            <td style="text-align: left;" colspan="6">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ item.Text1 }}</p>
            </td>
          </tr>
          <tr>
            <td style="text-align: left;" colspan="6">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ item.Text2 }}</p>
            </td>
          </tr>
          <tr>
            <td style="text-align: left;" colspan="6">
              <p style="margin: 0;line-height: 1.2;overflow:hidden;white-space:nowrap;">{{ item.Text3 }}</p>
            </td>
          </tr>
      </table>
      </div>
      <br>
    </el-tab-pane>
  </el-tabs>
  </div>
</template>
<script>
export default {
  components: {},
  data () {
    return {
      formData: {},
      form: {},
      formtable: {},
      printObj3: {
        id: 'printxrf0', // 这里是要打印元素的ID
        popTitle: '年益实业股份有限公司', // 打印的标题
        extraCss: '', // 打印可引入外部的一个 css 文件
        extraHead: '' // 打印头部文字
      }
    }
  },
  computed: {},
  watch: {},
  created () {
    this.Getlabdata(this.$route.params.numbers)
  },
  beforeDestroy () {
  },
  mounted () {
  },
  methods: {
    async Getlabdata (row) {
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/Getlabdata?Numbers=' + row)
        if (res.status !== 200) return
        this.formData = res.response
        this.Getxrfdata(res.response)
      } catch (error) {
        alert(error)
      }
    },
    async Getxrfdata (row) {
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/Getxrfdata', row)
        if (res.status !== 200) return
        this.form = res.response
        if (this.form.length > 0) {
          this.Getxrfdataxq(this.form[0])
        }
      } catch (error) {
        alert(error)
      }
    },
    async Getxrfdataxq (row) {
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/Getxrfdataxq', row)
        if (res.status !== 200) return
        this.formtable = res.response
      } catch (error) {
        alert(error)
      }
    },
    handleClick (tab, event) {
      this.printObj3.id = 'printxrf' + tab.index
      this.Getxrfdataxq(this.form[tab.index])
    }
  }
}

</script>
<style lang="less" scoped>
  </style>
