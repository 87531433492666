<template>
  <div class="page bgc-f4f4f4 wbobye">
    <div class="video-show" />
    <video id="video" ref="video" class="video vjs-fluid" autoplay />
    <el-upload
      class="upload-demo"
      action="/"
      :on-change="handlechange"
      multiple
      :limit="1"
      :auto-upload="false"
      :file-list="fileList">
      <span class="change1">
      <i class="icon iconfont icon-tupian" ></i>
    </span>
    </el-upload>
    <span class="change2" @click="qiechange">
      <i class="icon iconfont icon-qiehuanshexiangtou" ></i>
    </span>
    <span class="change3" @click="toggleTorch">
      <i :class="sdt" ></i>
    </span>
    <span class="change" @click="change">
      <i class="icon iconfont icon-return" ></i>
    </span>
    <div style="display: block !important;" class="scan-tip"> {{tipMsg}} </div>
  </div>
</template>

<script>
import { BrowserMultiFormatReader } from '@zxing/library'
// import { Dialog, Notify } from 'vant'
import { mapMutations } from 'vuex'
import QrcodeDecoder from '../../../node_modules/qrcode-decoder/dist/index'
export default {
  name: 'scanCodePage',
  data () {
    return {
      loadingShow: false,
      codeReader: null,
      scanText: '',
      vin: null,
      scanning: false,
      tipMsg: '年益QC系统正在尝试识别....',
      sdt: 'icon iconfont icon-shoudiantong_guan',
      fileList: []
    }
  },
  created () {
    this.codeReader = new BrowserMultiFormatReader()
    this.openScan()
  },
  destroyed () {
    this.codeReader.reset()
  },

  methods: {
    ...mapMutations(['Qcrode', 'TabComponentFun']),
    toggleTorch () {
      try {
        const track = this.$refs.video.srcObject.getVideoTracks()[0]
        if (track) {
          track.applyConstraints({
            focusMode: 'continuous' // 连续自动对焦
          })
        }
        const capabilities = track.getCapabilities()

        if ('torch' in capabilities) {
          if (track.getSettings().torch === false) {
            track.applyConstraints({ advanced: [{ torch: true }] })
              .then(() => {
                // 手电筒已打开
                this.sdt = 'icon iconfont icon-shoudiantong_kai'
              })
              .catch((error) => {
                alert('Error toggling torch:', error)
              })
          } else {
            track.applyConstraints({ advanced: [{ torch: false }] })
              .then(() => {
                // 手电筒已关闭
                this.sdt = 'icon iconfont icon-shoudiantong_guan'
              })
              .catch((error) => {
                alert('Error toggling torch:', error)
              })
          }
        } else {
          alert('您的设备不支持！')
        }
      } catch (error) {
        alert(error)
      }
    },
    async openScan () {
      this.codeReader.getVideoInputDevices().then((videoInputDevices) => {
        this.tipMsg = '正在调用摄像头...'
        console.log('videoInputDevices', videoInputDevices)
        // 默认获取第一个摄像头设备id
        let firstDeviceId = videoInputDevices[0].deviceId
        // 获取第一个摄像头设备的名称
        const videoInputDeviceslablestr = JSON.stringify(videoInputDevices[0].label)
        if (videoInputDevices.length > 1) {
          // 判断是否后置摄像头
          if (videoInputDeviceslablestr.indexOf('back') > -1) {
            firstDeviceId = videoInputDevices[0].deviceId
          } else {
            firstDeviceId = videoInputDevices[1].deviceId
          }
        }
        this.decodeFromInputVideoFunc(firstDeviceId)
      }).catch(err => {
        this.tipMsg = '调用摄像头失败'
        console.error(err)
      })
    },
    decodeFromInputVideoFunc (firstDeviceId) {
      if (this.scanning) return // Prevent multiple scans simultaneously
      this.scanning = true
      this.codeReader.reset() // 重置
      this.scanText = ''
      this.codeReader.decodeFromInputVideoDeviceContinuously(firstDeviceId, 'video', (result, err) => {
        this.tipMsg = '年益QC系统正在尝试识别...'
        this.scanText = ''
        if (result) {
          // 关闭扫码功能
          this.codeReader.reset()
          this.codeReader.stopContinuousDecode()
          this.scanning = false
          this.scanText = result.text
          this.onDecode(result.text)
          // if (result.numBits === 800) {
          // 这里是扫码后的结果
          //
          // alert(this.scanText)
          // 这是我扫码后拿到url截取的字段
          //  const qrcode = this.scanText.split('?')[1].split('#')[0].split('=')[1]
          // 通过子传父通知父组件
          //  this.$emit('qrcodes', qrcode)
          // 这部分接下去的代码根据需要，读者自行编写了
          // }
        }
        if (err && !(err)) {
          this.tipMsg = '识别失败'
          setTimeout(() => {

          }, 2000)
          console.error(err)
        }
      })
    },
    qiechange () {
      alert('暂不开放')
    },
    handlechange (file) {
      const urlfile = this.$PublicJs.getObjectURL(file.raw)
      var qr = new QrcodeDecoder()
      qr.decodeFromImage(urlfile).then((res) => {
        if (res.data) {
          this.onDecode(res.data)
          this.fileList = []
        } else {
          alert('系统无法识别图中二维码！')
          this.fileList = []
        }
      })
    },
    clickIndexLeft () { // 返回上一页
      this.codeReader = null
      this.$destroy()
      this.$router.back()
    },
    change () {
      this.$router.go(0)
    },
    onDecode (row) {
      const index = row.lastIndexOf('/')
      if (index === 27) {
        const dataindex = row.substring(index + 1, row.length)
        this.Qcrode(dataindex)
        this.$router.push('/Subcontractdataviews')
        return
      }
      if (row.length === 29) {
        this.Qcrode(row)
        this.$router.push('/Subcontractdataviews')
        return
      }
      const strArr = row.split('#')
      if (strArr.length === 11) {
        this.Qcrode(strArr[9])
        this.$router.push('/Subcontractdataviews')
      } else {
        this.codeReader = new BrowserMultiFormatReader()
        this.openScan()
        alert('此二维码非系统二维码，内容：' + row)
      }
    }
  }
}
</script>

<style lang="less" scoped>
  body{
    overflow: hidden;
    margin:  0 auto;
  }
  .wbobye{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
  overflow: hidden !important;
}
  .video {
    margin: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
  }
  .video-show {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50vw;
    height: 50vw;
    z-index: 2;
    background: linear-gradient(to left, #2dec2d, #2dec2d) left top no-repeat,
      linear-gradient(to bottom, #2dec2d, #2dec2d) left top no-repeat,
      linear-gradient(to left, #2dec2d, #2dec2d) right top no-repeat,
      linear-gradient(to bottom, #2dec2d, #2dec2d) right top no-repeat,
      linear-gradient(to left, #2dec2d, #2dec2d) left bottom no-repeat,
      linear-gradient(to bottom, #2dec2d, #2dec2d) left bottom no-repeat,
      linear-gradient(to left, #2dec2d, #2dec2d) right bottom no-repeat,
      linear-gradient(to left, #2dec2d, #2dec2d) right bottom no-repeat;
    background-size: 2px 20px, 20px 2px, 2px 20px, 20px 2px;
  }
  .tip {
    width: 10vw;
    height: 10vw;
    background-color: rgb(45, 236, 45);
    border: 1px solid #ffffff;
    border-radius: 50%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  .bgc-f4f4f4 {
    background-color: #363636;
  }

  .page {
    // position: relative;
    overflow: hidden;
    // position: relative;
    height: 100vh;
  }
  .change {
    z-index: 100;
    position: fixed;
    bottom: 40px;
    left: 73vw;
    transform: translateX(-50%);
    color: #fff;
    text-align: center;
    background-color: #282c3387;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    /* align-content: center; */
  }
  .el-icon-refresh {
    color: #000;
    font-size: 25px;
  }
  .scan-tip{
    position: absolute;
    left: 50vw;
    top: 25vw;
    transform: translate(-50%, -50%);
    color:#00ae10;
  }
  .change1 {
    z-index: 100;
    position: fixed;
    bottom: 40px;
    left: 28vw;
    transform: translateX(-50%);
    color: #fff;
    text-align: center;
    background-color: #282c3387;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    /* align-content: center; */
  }
  .change2 {
    z-index: 100;
    position: fixed;
    bottom: 40px;
    left: 43vw;
    transform: translateX(-50%);
    color: #fff;
    text-align: center;
    background-color: #282c3387;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    /* align-content: center; */
  }
  .change3 {
    z-index: 100;
    position: fixed;
    bottom: 40px;
    left: 58vw;
    transform: translateX(-50%);
    color: #fff;
    text-align: center;
    background-color: #282c3387;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    line-height: 50px;
    /* align-content: center; */
  }
</style>
