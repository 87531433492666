<template>
  <div style="padding: 10px;">
    <div style="padding-bottom: 10px;">
      <el-button type="primary" icon="el-icon-arrow-left" size="small" @click="$router.push('/Product')">返回</el-button>
    </div>
    <el-descriptions :title="'料号：' + this.$route.params.numbers" :column="2">
      <el-descriptions-item :labelStyle="{'min-width': '50px'}" :contentStyle="{'color': '#409eff'}" :label="`问题${ index + 1 }`" v-for="(item, index) in tableData" :key="index"><span style="cursor: pointer;" @click="rowclick(item)">{{ item.Sketch}}</span></el-descriptions-item>
    </el-descriptions>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  components: {
  },
  props: [],
  data () {
    return {
      tableData: []
    }
  },
  computed: {},
  watch: {},
  created () {
    if (this.$route.params.numbers === undefined || this.$route.params.numbers === '') {
      this.$router.push('/Product')
    } else {
      this.Getitem()
    }
  },
  mounted () { },
  methods: {
    ...mapMutations(['TabComponentFun', 'Productnumbersfun']),
    async Getitem () {
      const { data: res } = await this.$http.get('/api/Product/Getitem?Item=' + this.$route.params.numbers)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.tableData = res.response
    },
    rowclick (row) {
      this.$router.push('/Details/' + row.Numbers)
    }
  }
}

</script>
<style>
.el-upload__tip {
  line-height: 1.2;
}
</style>
