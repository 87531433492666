<template>
  <div style="margin: 0 auto; max-width: 500px;padding: 10px;">
    <h3 style="text-align: center;">铜线标识卡</h3>
    <el-form ref="elForm" :model="formData" :rules="rules" size="medium" label-width="90px"
      label-position="right">
      <el-form-item :label="$t('All.状态')">
        <el-tag :type="formData.State === 0?'success':'danger'" effect="dark">{{ formData.State === 0?'有效': formData.State === 2 ? formData.Order_no + '正在使用':'失效' }}</el-tag>
      </el-form-item>
      <el-form-item :label="$t('All.生产日期')" prop="Cdate">
        {{ formData.Cdate }}
      </el-form-item>
      <el-form-item :label="$t('All.品名')" prop="Product_name">
        {{ formData.Product_name }}
      </el-form-item>
      <el-form-item :label="$t('All.规格')" prop="Gg">
        {{ formData.Gg }}
      </el-form-item>
      <el-form-item :label="$t('All.料号')" prop="Item_no">
        {{ formData.Item_no }}
      </el-form-item>
      <el-form-item :label="$t('All.Lot号')" prop="Lot">
        {{ formData.Lot }}
      </el-form-item>
      <el-form-item :label="$t('All.工单号')" prop="Gd">
        {{ formData.Gd }}
      </el-form-item>
      <el-form-item :label="$t('All.客户')" prop="Jj">
        {{ formData.Kh }}
      </el-form-item>
      <el-form-item :label="$t('All.颜色')" prop="Jt">
        {{ formData.Ys }}
      </el-form-item>
      <el-form-item :label="$t('All.单位')" prop="Jt">
        {{ formData.Dw }}
      </el-form-item>
      <el-form-item :label="$t('All.班别')" prop="Bb">
        {{ formData.Bb }}
      </el-form-item>
      <el-form-item :label="$t('All.操作员')">
        {{ formData.Jyy }}
      </el-form-item>
      <el-form-item :label="$t('All.总数量')" prop="Scsl">
        {{ formData.Scsl }}
      </el-form-item>
      <el-form-item :label="$t('All.当前数量')" prop="Scsl">
        {{formData.DScsl}}
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
export default {
  components: {},
  props: [],
  data () {
    return {
      formData: {},
      rules: {
        Product_name: [{
          required: true,
          message: this.$t('All.请输入品名'),
          trigger: 'blur'
        }],
        Gg: [{
          required: true,
          message: this.$t('All.请输入规格'),
          trigger: 'blur'
        }],
        Item_no: [{
          required: true,
          message: this.$t('All.请输入料号'),
          trigger: 'blur'
        }],
        Lot: [{
          required: true,
          message: this.$t('All.请输入Lot号'),
          trigger: 'blur'
        }],
        Gd: [{
          required: true,
          message: this.$t('All.请输入工单号'),
          trigger: 'blur'
        }],
        Kh: [{
          required: true,
          message: '请输入客户',
          trigger: 'blur'
        }],
        Ys: [{
          required: true,
          message: this.$t('All.请输入颜色'),
          trigger: 'blur'
        }],
        Bb: [{
          required: true,
          message: this.$t('All.请输入班别'),
          trigger: 'blur'
        }],
        Scsl: [{
          required: true,
          message: this.$t('All.请输入生产数量'),
          trigger: 'blur'
        }],
        Cdate: [{
          required: true,
          message: this.$t('All.请选择生产日期'),
          trigger: 'change'
        }]
      },
      Printname: '',
      Coutl: ''
    }
  },
  computed: {},
  watch: {},
  created () {
    this.Subcontractset(this.$route.params.numbers)
  },
  mounted () {},
  methods: {
    submitForm () {
      this.$refs.elForm.validate(async valid => {
        if (!valid) return this.$message.error(this.$t('All.数据填写不完整'))
        const postdata = {
          ERClass: this.formData,
          Printname: this.Printname,
          Coutl: this.Coutl,
          UserData: this.$store.state.Login
        }
        const { data: res } = await this.$http.post('/api/Semifinished/CBERSeleUserData', postdata)
        if (res.status !== 200) return this.$message.error(res.msg)
        this.$message.success(res.msg)
        this.$store.state.TabComponent = 'Qecode'
      })
    },
    resetForm () {
      this.$refs.elForm.resetFields()
    },
    async Subcontractset (row) {
      const { data: res } = await this.$http.get('/api/Semifinished/GetCBERClass?Serialcode=' + row)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.formData = res.response
      this.$message.success(res.msg)
    }
  }
}

</script>
<style>
</style>
