<template>
    <el-container>
        <el-aside  :width="isCollapse ? '65px':'200px'">
            <div v-show="!isCollapse" style="text-align: center;">
                <img class="logoimg"  src="../../assets/Img/NIENYI-2.png" alt="">
            </div>
            <div v-show="isCollapse" style="text-align: center;">
                <img class="logoimg2"  src="../../assets/Img/adtu9-gnqbt-001.png" alt="">
            </div>
            <el-menu unique-opened default-active="1-4-1" class="el-menu-vertical-demo" @open="handleOpen" @close="handleClose" @select="handleSelect" :collapse="isCollapse">
                <el-menu-item index="Home">
                    <i class="el-icon-s-home"></i>
                    <span slot="title">{{ $t('All.主页') }}</span>
                </el-menu-item>
                <el-submenu index="统计">
                    <template slot="title">
                      <i class="el-icon-s-data"></i>
                      <span slot="title">{{ $t('All.统计') }}</span>
                    </template>
                    <el-menu-item index="IQCHome">{{  $t('All.IQC统计') }}</el-menu-item>
                    <el-menu-item index="Chartpage">{{ $t('All.IQC人员统计') }}</el-menu-item>
                    <el-menu-item index="FQCChartpage">{{ $t('All.FQC人员统计') }}</el-menu-item>
                    <el-menu-item index="IPQCChartpage">{{ $t('All.IPQC人员统计') }}</el-menu-item>
                    <el-menu-item index="OQCChartpage">{{ $t('All.OQC人员统计') }}</el-menu-item>
                </el-submenu>
                <el-menu-item index="UserAdmin">
                    <i class="el-icon-s-custom"></i>
                    <span slot="title">{{ $t('All.用户管理') }}</span>
                </el-menu-item>
                <el-menu-item index="Examine">
                    <i class="el-icon-s-check"></i>
                    <span slot="title">{{ $t('All.待审') }}</span>
                </el-menu-item>
                <el-submenu index="实验室">
                    <template slot="title">
                      <i class="el-icon-s-opportunity"></i>
                      <span slot="title">{{ $t('All.实验室') }}</span>
                    </template>
                    <el-menu-item index="TestExamine">{{ $t('All.待审区') }}</el-menu-item>
                    <el-menu-item index="Testsheet">{{ $t('All.送测单') }}</el-menu-item>
                    <el-menu-item index="Scheduling">{{ $t('All.实验排程') }}</el-menu-item>
                    <el-menu-item index="TestGrEData">{{ $t('All.个人记录') }}</el-menu-item>
                    <el-menu-item index="TestEData">{{ $t('All.历史记录') }}</el-menu-item>
                </el-submenu>
                <el-submenu index="检验单">
                    <template slot="title">
                        <i class="el-icon-s-order"></i>
                        <span slot="title">{{ $t('All.检验单') }}</span>
                    </template>
                    <el-menu-item v-if="(this.$store.state.Login.Department === 'FQC' || this.$store.state.Login.Department === 'All')" index="NewFQCSeleItem">{{ $t('All.新FQC检验单') }}</el-menu-item>
                    <!--
                      <el-menu-item v-if="(this.$store.state.Login.Department === 'FQC' || this.$store.state.Login.Department === 'All')" index="FQCSeleItem">{{ $t('All.FQC检验单') }}</el-menu-item>
                      <el-menu-item v-if="(this.$store.state.Login.Department === 'OQC' || this.$store.state.Login.Department === 'All')" index="OQCSeleItem">{{ $t('All.OQC检验单') }}</el-menu-item>
                      <el-menu-item v-if="(this.$store.state.Login.Department === 'All')" index="IQCSeleItem">IQC{{ $t('All.检验单') }}</el-menu-item>
                     -->
                    <el-menu-item v-if="(this.$store.state.Login.Department === 'IQC' || this.$store.state.Login.Department === 'All')" index="NewIQCSeleItem">{{ $t('All.新IQC检验单') }}</el-menu-item>
                    <el-menu-item v-if="(this.$store.state.Login.Department === 'IPQC' || this.$store.state.Login.Department === 'All')" index="IPQCSeleItem">{{ $t('All.IPQC检验单') }}</el-menu-item>
                    <el-menu-item v-if="(this.$store.state.Login.Department === 'OQC' || this.$store.state.Login.Department === 'All')" index="NewOQCSeleItem">{{ $t('All.新OQC检验单') }}</el-menu-item>
                </el-submenu>
                <el-submenu index="数据录入">
                    <template slot="title">
                        <i class="el-icon-menu"></i>
                        <span slot="title">{{ $t('All.数据录入') }}</span>
                    </template>
                    <!--
                    <el-menu-item v-if="(this.$store.state.Login.Department === 'FQC' || this.$store.state.Login.Department === 'All')" index="FQCAdminData">{{ $t('All.FQC模版') }}</el-menu-item>
                    -->
                    <el-menu-item v-if="(this.$store.state.Login.Department === 'FQC' || this.$store.state.Login.Department === 'All')" index="NewFQCAdminData">{{ $t('All.新FQC模版') }}</el-menu-item>
                     <!--
                    <el-menu-item v-if="(this.$store.state.Login.Department === 'OQC' || this.$store.state.Login.Department === 'All')" index="OQCAdminData">{{ $t('All.OQC模版') }}</el-menu-item>
                      <el-menu-item v-if="(this.$store.state.Login.Department === 'IQC' || this.$store.state.Login.Department === 'All')" index="IQCAdminData">{{ $t('All.IQC模版') }}</el-menu-item>
                    -->
                    <el-menu-item v-if="(this.$store.state.Login.Department === 'IQC' || this.$store.state.Login.Department === 'All')" index="NewIQCAdminData">{{ $t('All.新IQC模版') }}</el-menu-item>
                    <el-menu-item v-if="(this.$store.state.Login.Department === 'IPQC' || this.$store.state.Login.Department === 'All')" index="IPQCAdminData">{{ $t('All.IPQC模版') }}</el-menu-item>
                </el-submenu>
                <el-submenu index="数据记录">
                    <template slot="title">
                        <i class="icon iconfont icon-00-shujujilu-05"></i>
                        <span slot="title">{{ $t('All.数据记录') }}</span>
                    </template>
                    <el-menu-item index="FQCQuery">{{ $t('All.FQC记录') }}</el-menu-item>
                    <el-menu-item index="OQCQuery">{{ $t('All.OQC记录') }}</el-menu-item>
                    <el-menu-item index="IQCQuery">{{ $t('All.IQC记录') }}</el-menu-item>
                    <el-menu-item index="IPQCQuery">{{ $t('All.IPQC记录') }}</el-menu-item>
                    <el-menu-item index="NewIQCQuery">{{ $t('All.NewIQC记录') }}</el-menu-item>
                    <el-menu-item index="NewScheduling">{{ $t('All.NewIQC月报') }}</el-menu-item>
                    <el-menu-item index="NewOQCdata"> {{ $t('All.NewOQC记录') }}</el-menu-item>
                    <el-menu-item index="NewFQCQuery"> {{ $t('All.新FQC记录') }}</el-menu-item>
                </el-submenu>
                <el-submenu index="点检表">
                    <template slot="title">
                        <i class="icon iconfont icon-00-shujujilu-05"></i>
                        <span slot="title">{{ $t('All.设备点检表') }}</span>
                    </template>
                    <el-menu-item index="Airblowertfrom">{{ $t('All.点检表') }}</el-menu-item>
                    <el-menu-item index="Airblowertemplatefrom">{{ $t('All.点检表模版') }}</el-menu-item>
                </el-submenu>
                <el-submenu index="标识卡">
                    <template slot="title">
                        <i class="el-icon-s-order"></i>
                        <span slot="title">{{ $t('All.标识卡') }}</span>
                    </template>
                    <el-menu-item v-if="(this.$store.state.Login.Department === 'ZL' || this.$store.state.Login.Department === 'SCYB' || this.$store.state.Login.Department === 'All')" index="ZL">{{ $t('All.制粒') }}</el-menu-item>
                    <el-menu-item v-if="(this.$store.state.Login.Department === 'CT' || this.$store.state.Login.Department === 'SCYB' || this.$store.state.Login.Department === 'All')" index="CT">{{ $t('All.铜线') }}</el-menu-item>
                    <el-menu-item v-if="(this.$store.state.Login.Department === 'CY' || this.$store.state.Login.Department === 'All')" index="CY">{{ $t('All.冲压') }}/{{ $t('All.注塑') }}</el-menu-item>
                    <el-menu-item v-if="(this.$store.state.Login.Department === 'SCEB' || this.$store.state.Login.Department === 'All' || this.$store.state.Login.Department === 'FQC' || this.$store.state.Login.Department === 'SJ' || this.$store.state.Login.Department === 'SCYB' || this.$store.state.Login.Department === 'SCSANB' || this.$store.state.Login.Department === 'SCSIB' || this.$store.state.Login.Department === 'SCWUB' || this.$store.state.Login.Department === 'SCLIUB')" index="ER">{{ $t('All.标识卡') }}</el-menu-item>
                    <el-menu-item index="ZLtable">{{ $t('All.制粒数据') }}</el-menu-item>
                    <el-menu-item index="CTtable">{{ $t('All.铜线数据') }}</el-menu-item>
                    <el-menu-item index="CYtable">{{ $t('All.冲压') }}/{{ $t('All.注塑数据') }}</el-menu-item>
                    <el-menu-item index="ERtable">{{ $t('All.标识卡数据') }}</el-menu-item>
                </el-submenu>
                <el-submenu index="补标识卡" v-if="((this.$store.state.Login.Department === 'CK' || this.$store.state.Login.Department === 'All') && this.$store.state.Login.Factory !== 'VN')" >
                    <template slot="title">
                        <i class="el-icon-s-order"></i>
                        <span slot="title">{{ $t('All.补标识卡') }}</span>
                    </template>
                    <el-menu-item index="BPASS">{{ $t('All.补PASS单') }}</el-menu-item>
                    <el-menu-item index="BZL">{{ $t('All.补制粒') }}</el-menu-item>
                    <el-menu-item index="BCT">{{ $t('All.补铜线') }}</el-menu-item>
                    <el-menu-item index="BCY">{{ $t('All.补冲压') }}/{{ $t('All.注塑') }}</el-menu-item>
                    <el-menu-item index="BYB">{{ $t('All.补一部标识卡') }}</el-menu-item>
                    <el-menu-item index="BER">{{ $t('All.补二部标识卡') }}</el-menu-item>
                    <el-menu-item index="BZLtable">{{ $t('All.补制粒数据') }}</el-menu-item>
                    <el-menu-item index="BCTtable">{{ $t('All.补铜线数据') }}</el-menu-item>
                    <!--
                    <el-menu-item index="BPASStable">{{ $t('All.补PASS单数据') }}</el-menu-item>
                    -->
                    <el-menu-item index="BCYtable">{{ $t('All.补冲压') }}/{{ $t('All.注塑数据') }}</el-menu-item>
                    <el-menu-item index="BYBtable">{{ $t('All.补一部标识数据') }}</el-menu-item>
                    <el-menu-item index="BERtable">{{ $t('All.补二部标识数据') }}</el-menu-item>
                </el-submenu>
                <el-submenu index="物料追溯">
                    <template slot="title">
                      <i class="el-icon-s-management"></i>
                    <span slot="title">{{ $t('All.物料追溯') }}</span>
                    </template>
                    <el-menu-item index="Subcontract" :disabled="!(this.$store.state.Login.Department === 'CK' || this.$store.state.Login.Department === 'All')">{{ $t('All.物料分包') }}</el-menu-item>
                    <el-menu-item index="LSeleItem" >{{ $t('All.领用物料') }}</el-menu-item>
                    <el-menu-item index="Material" >{{ $t('All.追溯记录') }}</el-menu-item>
                    <el-menu-item index="TraceQuery" >{{ $t('All.PASS单数据') }}</el-menu-item>
                    <el-menu-item index="Record" >{{ $t('All.操作记录') }}</el-menu-item>
                </el-submenu>
                <el-submenu index="产品注意事项">
                    <template slot="title">
                      <i class="el-icon-s-opportunity"></i>
                    <span slot="title">{{ $t('All.产品注意事项') }}</span>
                    </template>
                    <el-menu-item index="Producttable" >{{ $t('All.产品列表') }}</el-menu-item>
                </el-submenu>
                <el-submenu index="个性化设置">
                    <template slot="title">
                        <i class="icon iconfont icon-dayinji"></i>
                        <span slot="title">{{ $t('All.个性化设置') }}</span>
                    </template>
                    <el-menu-item index="Printersytem">{{ $t('All.打印机') }}</el-menu-item>
                </el-submenu>
                <el-submenu index="语言类型">
                    <template slot="title">
                        <i class="icon iconfont icon-yuyan"></i>
                        <span slot="title">{{ $t('All.语言类型') }}</span>
                    </template>
                    <el-menu-item index="zh">{{ $t('All.简体中文') }}</el-menu-item>
                    <el-menu-item index="en">{{ $t('All.英文') }}</el-menu-item>
                    <el-menu-item index="vn">{{ $t('All.越南文') }}</el-menu-item>
                </el-submenu>
            </el-menu>
        </el-aside>
        <el-container>
          <el-header>
                <i @click="isCollapse = true" v-show="!isCollapse" class="el-icon-s-fold asieico"></i>
                <i @click="isCollapse = false" v-show="isCollapse"  class="el-icon-s-unfold asieico"></i>
                <div style="height: 50px;box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);">
                    <div class="topleft">
                        <el-breadcrumb separator="/">
                            <el-breadcrumb-item >{{ $t('All.首页') }}</el-breadcrumb-item>
                            <!-- <el-breadcrumb-item v-for="(item,index) in braderrunm" :key="index">{{item}}</el-breadcrumb-item> -->
                        </el-breadcrumb>
                    </div>
                    <div class="topright">
                        <span style="padding: 10px;">{{ $store.state.Login.Username }}</span>
                        <el-dropdown @command="handleCommand">
                          <el-avatar style="vertical-align: middle;" size="large" src="https://cube.elemecdn.com/0/88/03b0d39583f48206768a7534e55bcpng.png"></el-avatar>
                          <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="Exit">{{ $t('All.退出') }}</el-dropdown-item>
                          </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                </div>
            </el-header>
            <el-main>
                <el-tabs v-model="editableTabsValue" type="card" closable @tab-remove="RemoveTab" style="padding-left: 5px;height: 100%; overflow:hidden;">
                    <el-tab-pane
                        v-for="item in editableTabs"
                        :key="item.name"
                        :label="item.title"
                        :name="item.name"
                    >
                    <transition name="fade-transform" mode="out-in">
                        <component :ref="item.name" :is="item.content" :Addtab="addTab" :RemoveTab="RemoveTab" :Rendw="Rendw" :Renewtargename="Renewtargename" :filedata="item.filedata" ></component>
                    </transition>
                    </el-tab-pane>
                  </el-tabs>
            </el-main>
        </el-container>
        <transition mode="out-in">
          <component :is="this.$store.state.ConductIs"></component>
        </transition>
        <Upuserpassd v-if="Upuserpassdisbool" :Upuserpassdisbool="Upuserpassdisbool"/>
    </el-container>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      isCollapse: false,
      editableTabsValue: this.$t('All.主页'),
      editableTabs: [{
        title: this.$t('All.主页'),
        name: this.$t('All.主页'),
        content: 'Home',
        filedata: {}
      }],
      TabComponent: '',
      braderrunm: [],
      value: localStorage.getItem('locale') || '',
      Upuserpassdisbool: false
    }
  },
  mounted () {
    if (this._isMobile()) {
      this.$message.success(this.$t('All.系统检测到当前设备是移动端自动关闭左侧菜单可点击Logo旁三横图标展开'))
      this.isCollapse = true
    } else {
      this.$message.success(this.$t('All.系统检测到当前设备是PC端可点击Logo旁三横图标隐藏菜单'))
      this.isCollapse = false
    }
  },
  created () {
    if (this.$store.state.Login.Update_time === null) {
      this.Upuserpassdisbool = true
      return this.$notify({
        title: this.$t('Upuserpassd.温馨提示'),
        message: this.$t('Upuserpassd.系统强制要求您更新密码'),
        offset: 40,
        type: 'error'
      })
    }
    this.Printerdata()
    if (this.$store.state.Login.User !== 'admin') {
      this.checkPasswordStatus()
    }
  },
  components: {
    Home: () => import('../Computer/Home.vue'),
    Examine: () => import('../Examine.vue'),
    FQCexaminedata: () => import('../FQC/FQCexaminedata.vue'),
    NewFQCexaminedata: () => import('../NewFQC/NewFQCexaminedata.vue'),
    UserAdmin: () => import('../UserAdmin.vue'),
    FQCUser: () => import('../FQC/FQCUser.vue'),
    FQCAdmin: () => import('../FQC/FQCAdmin.vue'),
    FQCAdminData: () => import('../FQC/FQCAdminData.vue'),
    FQCSeleItem: () => import('../FQC/FQCSeleItem.vue'),
    FQCSeleItemAdmin: () => import('../FQC/FQCSeleItemAdmin.vue'),
    NewFQCUser: () => import('../NewFQC/NewFQCUser.vue'),
    NewFQCAdmin: () => import('../NewFQC/NewFQCAdmin.vue'),
    NewFQCAdminData: () => import('../NewFQC/NewFQCAdminData.vue'),
    NewFQCSeleItem: () => import('../NewFQC/NewFQCSeleItem.vue'),
    NewFQCSeleItemAdmin: () => import('../NewFQC/NewFQCSeleItemAdmin.vue'),
    OQCUser: () => import('../OQCUser.vue'),
    OQCAdmin: () => import('../OQCAdmin.vue'),
    OQCAdminData: () => import('../OQCAdminData.vue'),
    OQCSeleItem: () => import('../OQCSeleItem.vue'),
    OQCSeleItemAdmin: () => import('../OQCSeleItemAdmin.vue'),
    SeleFunInput: () => import('../SeleFunInput.vue'),
    Query: () => import('../Query.vue'),
    OQCQuery: () => import('../OQCQuery.vue'),
    FQCQuery: () => import('../FQC/FQCQuery.vue'),
    NewFQCQuery: () => import('../NewFQC/NewFQCQuery.vue'),
    OQC1Admin: () => import('../OQC1Admin.vue'),
    IQC: () => import('../IQC.vue'),
    IQCUser: () => import('../IQCUser.vue'),
    IQCSeleItem: () => import('../IQCSeleItem.vue'),
    IQCSeleItemadmin: () => import('../IQCSeleItemadmin.vue'),
    IQCSeleItemadminfqc: () => import('../IQCSeleItemadminfqc.vue'),
    IQCQuery: () => import('../IQCQuery.vue'),
    IQCExamine: () => import('../IQCExamine.vue'),
    FQCUpload: () => import('../FQC/Upload/FQCUpload.vue'),
    FQCAdminUpload: () => import('../FQC/Upload/FQCAdminUpload.vue'),
    NewFQCUpload: () => import('../NewFQC/Upload/NewFQCUpload.vue'),
    NewFQCAdminUpload: () => import('../NewFQC/Upload/NewFQCAdminUpload.vue'),
    IQCUpload: () => import('../Upload/IQCUpload.vue'),
    IQCAdminUpload: () => import('../Upload/IQCAdminUpload.vue'),
    IQCAdmin: () => import('../IQCAdmin.vue'),
    IQCAdminFQC: () => import('../IQCAdminFQC.vue'),
    IQCAdminData: () => import('../IQCAdminData.vue'),
    IQCAdminDataFqc: () => import('../IQCAdminDataFqc.vue'),
    TraceQuery: () => import('../Trace/TraceQuery.vue'),
    Subcontract: () => import('../Trace/Subcontract.vue'),
    Subcontractdata: () => import('../Trace/Subcontractdata.vue'),
    Printersytem: () => import('../Trace/Printersytem.vue'),
    Qecode: () => import('../Trace/Qrcode.vue'),
    IPQCAdminData: () => import('../Ipqc/IPQCAdminData.vue'),
    IPQCAdmin: () => import('../Ipqc/IPQCAdmin.vue'),
    IPQCSeleItemAdmin: () => import('../Ipqc/IPQCSeleItemAdmin.vue'),
    IPQCSeleItem: () => import('../Ipqc/IPQCSeleItem.vue'),
    IPQCUser: () => import('../Ipqc/IPQCUser.vue'),
    IPQCExamine: () => import('../Ipqc/IPQCExamine.vue'),
    IPQCQuery: () => import('../Ipqc/IPQCQuery.vue'),
    NewIQCAdminData: () => import('../../components/Newiqc/NewIQCAdminData.vue'),
    NewIQCAdmin: () => import('../../components/Newiqc/NewIQCAdmin.vue'),
    NewUseIQC: () => import('../../components/Newiqc/NewUseIQC.vue'),
    NewScheduling: () => import('../../components/Newiqc/NewScheduling.vue'),
    NewIQCSeleItem: () => import('../../components/Newiqc/NewIQCSeleItem.vue'),
    NewIQCExamine: () => import('../../components/Newiqc/NewIQCExamine.vue'),
    NewIQCSeleItemAdmin: () => import('../../components/Newiqc/NewIQCSeleItemAdmin.vue'),
    ConductIs: () => import('../../views/ConductIs.vue'),
    Testsheet: () => import('../../components/Test/Testsheet.vue'),
    TestExamine: () => import('../../components/Test/TestExamine.vue'),
    Testflow: () => import('../../components/Test/Testflow.vue'),
    TestEData: () => import('../../components/Test/TestEData.vue'),
    TestGrEData: () => import('../../components/Test/TestGrEData.vue'),
    Editor: () => import('../../views/Editor.vue'),
    NewIQCQuery: () => import('../../components/Newiqc/NewIQCQuery.vue'),
    Scheduling: () => import('../../components/Test/Scheduling.vue'),
    ZL: () => import('../../components/ZLCTCY/ZL.vue'),
    CT: () => import('../../components/ZLCTCY/CT.vue'),
    CY: () => import('../../components/ZLCTCY/CY.vue'),
    Zlfb: () => import('../../components/ZLCTCY/Zlfb.vue'),
    Ctfb: () => import('../../components/ZLCTCY/Ctfb.vue'),
    Cyfb: () => import('../../components/ZLCTCY/Cyfb.vue'),
    CYtable: () => import('../../components/ZLCTCY/CYtable.vue'),
    CTtable: () => import('../../components/ZLCTCY/CTtable.vue'),
    ZLtable: () => import('../../components/ZLCTCY/ZLtable.vue'),
    ER: () => import('../../components/ZLCTCY/ER.vue'),
    ERtable: () => import('../../components/ZLCTCY/ERtable.vue'),
    Erfb: () => import('../../components/ZLCTCY/Erfb.vue'),
    Xbcsjkfb: () => import('../../components/ZLCTCY/Xbcsjkfb.vue'),
    Material: () => import('../../components/ZLCTCY/Material.vue'),
    // 单独工单指定料号
    ICUser: () => import('../../components/Trace/L/ICUser.vue'),
    // 查询工单物料
    LSeleItem: () => import('../../components/Trace/L/LSeleItem.vue'),
    BZL: () => import('../../components/Bdan/BZL.vue'),
    BCT: () => import('../../components/Bdan/BCT.vue'),
    BCY: () => import('../../components/Bdan/BCY.vue'),
    BER: () => import('../../components/Bdan/BER.vue'),
    BYB: () => import('../../components/Bdan/BXbcsjkfb.vue'),
    BPASS: () => import('../../components/Bdan/BPASS.vue'),
    BERtable: () => import('../../components/Bdan/BERtable.vue'),
    BCYtable: () => import('../../components/Bdan/BCYtable.vue'),
    BCTtable: () => import('../../components/Bdan/BCTtable.vue'),
    BZLtable: () => import('../../components/Bdan/BZLtable.vue'),
    Record: () => import('../../components/Trace/Record.vue'),
    Producttable: () => import('../../components/Productissues/Producttable.vue'),
    Editproductissues: () => import('../../components/Productissues/Editproductissues.vue'),
    // OQC
    NewOQCUser: () => import('../../components/OQC/NewOQCUser.vue'),
    NewOQCSeleItem: () => import('../../components/OQC/NewOQCSeleItem.vue'),
    NewOQCExamine: () => import('../../components/OQC/NewOQCExamine.vue'),
    NewOQCdata: () => import('../../components/OQC/NewOQCdata.vue'),
    IQCHome: () => import('../Computer/IQCHome.vue'),
    Airblowertfrom: () => import('../../components/Airblower/Airblower/Airblowertfrom.vue'),
    Airblowertemplatefrom: () => import('../../components/Airblower/Airblowertemplate/Airblowertemplatefrom.vue'),
    Upuserpassd: () => import('./Upuserpassd.vue'),
    Chartpage: () => import('../../views/IQCsum/Chartpage.vue'),
    FQCChartpage: () => import('../../views/FQCsum/Chartpage.vue'),
    IPQCChartpage: () => import('../../views/IPQCsum/Chartpage.vue'),
    OQCChartpage: () => import('../../views/OQCsum/Chartpage.vue')
  },
  methods: {
    checkPasswordStatus () {
      const now = new Date() // 当前时间
      const updateTime = new Date(this.$store.state.Login.Update_time) // 获取的更新时间

      // 计算时间差，以天为单位
      const diffTime = Math.abs(now - updateTime)
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)) // 将毫秒转换为天数
      // 判断是否超过 30 天
      if (diffDays >= 30) {
        this.Upuserpassdisbool = true
        return this.$notify({
          title: this.$t('Upuserpassd.温馨提示'),
          message: this.$t('Upuserpassd.系统强制要求您更新密码'),
          offset: 40,
          type: 'error'
        })
      } else if (diffDays >= 20) {
        return this.$notify({
          title: this.$t('Upuserpassd.温馨提示'),
          message: `${this.$t('Upuserpassd.距离系统强制要求您更新密码时间剩余')}${30 - diffDays}${this.$t('Upuserpassd.天')}`,
          offset: 40,
          duration: 0,
          type: 'warning'
        })
      }
    },
    ...mapMutations(['PrinterData']),
    handleOpen (key, keyPath) {
      // console.log(key, keyPath)
    },
    handleClose (key, keyPath) {
      // console.log(key, keyPath)
    },
    handleSelect (key, keyPath) {
      if (key === 'Fileform') {
        this.addTab(this.$t('All.料号总览'), 'Fileform', [])
      }
      if (key === 'Userform') {
        this.addTab(this.$t('All.用户管理'), 'Userform', [])
      }
      if (key === 'Documentsdata') {
        this.addTab(this.$t('All.文件管理'), 'Documentsdata', [])
      }
      switch (key) {
        case 'zh':
          this.value = key
          this.$i18n.locale = this.value
          localStorage.setItem('locale', this.value)
          this.$router.go(0)
          break
        case 'en':
          this.value = key
          this.$i18n.locale = this.value
          localStorage.setItem('locale', this.value)
          this.$router.go(0)
          break
        case 'vn':
          this.value = key
          this.$i18n.locale = this.value
          localStorage.setItem('locale', this.value)
          this.$router.go(0)
          break
        case 'Home':
          this.addTab(this.$t('All.主页'), 'Home', [])
          break
        case 'IQCHome':
          this.addTab(this.$t('All.IQC统计'), 'IQCHome', [])
          break
        case 'UserAdmin':
          this.addTab(this.$t('All.用户管理'), 'UserAdmin')
          break
        case 'Examine':
          this.addTab(this.$t('All.待审'), 'Examine')
          break
        case 'TestExamine':
          this.addTab(this.$t('All.实验室') + this.$t('All.待审区'), 'TestExamine')
          break
        case 'Testsheet':
          this.addTab(this.$t('All.送测单'), 'Testsheet')
          break
        case 'Scheduling':
          this.addTab(this.$t('All.实验排程'), 'Scheduling')
          break
        case 'TestGrEData':
          this.addTab(this.$t('All.个人记录'), 'TestGrEData')
          break
        case 'TestEData':
          this.addTab(this.$t('All.历史记录'), 'TestEData')
          break
        case 'FQCSeleItem':
          this.addTab(this.$t('All.FQC检验单'), 'FQCSeleItem')
          break
        case 'FQCAdminData':
          this.addTab(this.$t('All.FQC模版'), 'FQCAdminData')
          break
        case 'NewOQCSeleItem':
          this.addTab(this.$t('All.新OQC检验单'), 'NewOQCSeleItem')
          break
        case 'NewIQCSeleItem':
          this.addTab(this.$t('All.新IQC检验单'), 'NewIQCSeleItem')
          break
        case 'IPQCSeleItem':
          this.addTab(this.$t('All.IPQC检验单'), 'IPQCSeleItem')
          break
        case 'NewIQCAdminData':
          this.addTab(this.$t('All.新IQC模版'), 'NewIQCAdminData')
          break
        case 'IPQCAdminData':
          this.addTab(this.$t('All.IPQC模版'), 'IPQCAdminData')
          break
        case 'FQCQuery':
          this.addTab(this.$t('All.FQC记录'), 'FQCQuery')
          break
        case 'NewFQCQuery':
          this.addTab(this.$t('All.新FQC记录'), 'NewFQCQuery')
          break
        case 'NewFQCSeleItem':
          this.addTab(this.$t('All.新FQC检验单'), 'NewFQCSeleItem')
          break
        case 'NewFQCAdminData':
          this.addTab(this.$t('All.新FQC模版'), 'NewFQCAdminData')
          break
        case 'OQCQuery':
          this.addTab(this.$t('All.OQC记录'), 'OQCQuery')
          break
        case 'IQCQuery':
          this.addTab(this.$t('All.IQC记录'), 'IQCQuery')
          break
        case 'IPQCQuery':
          this.addTab(this.$t('All.IPQC记录'), 'IPQCQuery')
          break
        case 'NewIQCQuery':
          this.addTab(this.$t('All.NewIQC记录'), 'NewIQCQuery')
          break
        case 'NewScheduling':
          this.addTab(this.$t('All.NewIQC月报'), 'NewScheduling')
          break
        case 'NewOQCdata':
          this.addTab(this.$t('All.NewOQC记录'), 'NewOQCdata')
          break
        case 'ZL':
          this.addTab(this.$t('All.制粒'), 'ZL')
          break
        case 'CT':
          this.addTab(this.$t('All.铜线'), 'CT')
          break
        case 'CY':
          this.addTab(this.$t('All.冲压') + '/' + this.$t('All.注塑'), 'CY')
          break
        case 'ER':
          this.addTab(this.$t('All.标识卡'), 'ER')
          break
        case 'ZLtable':
          this.addTab(this.$t('All.制粒数据'), 'ZLtable')
          break
        case 'CTtable':
          this.addTab(this.$t('All.铜线数据'), 'CTtable')
          break
        case 'CYtable':
          this.addTab(this.$t('All.冲压') + '/' + this.$t('All.注塑数据'), 'CYtable')
          break
        case 'ERtable':
          this.addTab(this.$t('All.标识卡数据'), 'ERtable')
          break
        case 'BPASS':
          this.addTab(this.$t('All.补PASS单'), 'BPASS')
          break
        case 'BZL':
          this.addTab(this.$t('All.补制粒'), 'BZL')
          break
        case 'BCT':
          this.addTab(this.$t('All.补铜线'), 'BCT')
          break
        case 'BCY':
          this.addTab(this.$t('All.补冲压') + '/' + this.$t('All.注塑'), 'BCY')
          break
        case 'BYB':
          this.addTab(this.$t('All.补一部标识卡'), 'BYB')
          break
        case 'BER':
          this.addTab(this.$t('All.补二部标识卡'), 'BER')
          break
        case 'BZLtable':
          this.addTab(this.$t('All.补制粒数据'), 'BZLtable')
          break
        case 'BCTtable':
          this.addTab(this.$t('All.补铜线数据'), 'BCTtable')
          break
        case 'BPASStable':
          this.addTab(this.$t('All.补PASS单数据'), 'BPASStable')
          break
        case 'BCYtable':
          this.addTab(this.$t('All.补冲压') + '/' + this.$t('All.注塑数据'), 'BCYtable')
          break
        case 'BYBtable':
          this.addTab(this.$t('All.补一部标识数据'), 'BYBtable')
          break
        case 'BERtable':
          this.addTab(this.$t('All.补二部标识数据'), 'BERtable')
          break
        case 'Subcontract':
          this.addTab(this.$t('All.物料分包'), 'Subcontract')
          break
        case 'LSeleItem':
          this.addTab(this.$t('All.领用物料'), 'LSeleItem')
          break
        case 'Material':
          this.addTab(this.$t('All.追溯记录'), 'Material')
          break
        case 'TraceQuery':
          this.addTab(this.$t('All.PASS单数据'), 'TraceQuery')
          break
        case 'Record':
          this.addTab(this.$t('All.操作记录'), 'Record')
          break
        case 'Printersytem':
          this.addTab(this.$t('All.打印机'), 'Printersytem')
          break
        case 'Producttable':
          this.addTab(this.$t('All.产品列表'), 'Producttable')
          break
        case 'Chartpage':
          this.addTab(this.$t('All.IQC人员统计'), 'Chartpage')
          break
        case 'FQCChartpage':
          this.addTab(this.$t('All.FQC人员统计'), 'FQCChartpage')
          break
        case 'IPQCChartpage':
          this.addTab(this.$t('All.IPQC人员统计'), 'IPQCChartpage')
          break
        case 'OQCChartpage':
          this.addTab(this.$t('All.OQC人员统计'), 'OQCChartpage')
          break
        default:
          break
      }
    },
    addTab (targetName, content, row) {
      for (let index = 0; index < this.editableTabs.length; index++) {
        if (this.editableTabs[index].name === targetName) {
          this.editableTabsValue = this.editableTabs[index].name
          return
        }
      }
      this.editableTabs.push({
        title: targetName,
        name: targetName,
        content: content,
        filedata: row
      })
      this.editableTabsValue = targetName
    },
    RemoveTab (targetName) {
      const tabs = this.editableTabs
      let activeName = this.editableTabsValue
      if (activeName === targetName) {
        tabs.forEach((tab, index) => {
          if (tab.name === targetName) {
            const nextTab = tabs[index + 1] || tabs[index - 1]
            if (nextTab) {
              activeName = nextTab.name
            }
          }
        })
      }
      this.editableTabsValue = activeName
      this.editableTabs = tabs.filter(tab => tab.name !== targetName)
    },
    // 如果页面存在则更新标签页但不定位到标签页
    Rendw (targetName, content, methodName) {
      for (let index = 0; index < this.editableTabs.length; index++) {
        if (this.editableTabs[index].content === content) {
          this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
            this.$refs[targetName][0][methodName]()
          })
          return
        }
      }
    },
    // 更新标签页并定位到新标签页
    Renewtargename (targetName, content, methodName) {
      var targetbool = false
      for (let index = 0; index < this.editableTabs.length; index++) {
        if (this.editableTabs[index].content === content) {
          targetbool = true
          this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
            this.$refs[targetName][0][methodName]()
          })
          break
        }
      }
      if (!targetbool) {
        this.editableTabs.push({
          title: targetName,
          name: targetName,
          content: content,
          filedata: []
        })
      }
      this.editableTabsValue = targetName
    },
    handleCommand (command) {
      if (command === 'Exit') {
        this.$router.push('/Login')
      }
    },
    // App.vue
    _isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    exit () {
      this.$store.state.TabComponent = 'Home'
      this.$router.push('/Login')
    },
    async Printerdata () {
      const { data: res } = await this.$http.post('/api/Trace/Printerdata', this.$store.state.Login)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.PrinterData(res.response)
    }
  }
}
</script>
<style lang="less" scoped>
.el-container{
    height: 100%;
    background-color: rgb(255, 255, 255);
}
.el-aside{
    background-color: rgb(255, 255, 255);
    margin: 0 auto;
    overflow: hidden;
}
.el-main{
    padding: 0 !important;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.topleft{
    float: left;
    font-size: 20px;
}
.topright{
    float: right;
    padding-right: 10px;
    line-height: 45px;
}
.el-breadcrumb{
    line-height: 50px;
    font-size: 16px;
}
.logoimg{
    height: 60px;
    width: 170px;
    margin-top: 5px;
}
.iconunfoled{
    font-size: xx-large;
}
.el-header{
    height: 60px !important;
    padding: 0px !important;
}
.tagsclass{
    cursor: default;
    float: left;
    width: 100%;
    height: 40px !important;
    padding: 0;
    line-height: 40px;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.asieico{
    font-size: 25px;
    float: left;
    line-height: 50px;
    margin-left: 10px;
    margin-right: 10px;
}
.logoimg2{
    padding-top: 10px;
}
.el-tag{
   margin-left: 10px;
}
.el-menu{
  border-right: solid 1px #e6e6e669;
  height: calc(100vh - 70px);
  overflow-y: auto;
  overflow-x: hidden;
}
// global transition css

/* fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .4s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* breadcrumb transition */
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all .4s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.breadcrumb-move {
  transition: all .4s;
}

.breadcrumb-leave-active {
  position: absolute;
}

.el-tab-pane {
  height: 100%;
}
</style>
