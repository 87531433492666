<template>
  <div>
    <div style="position: fixed;top: 55px;right: 10px; z-index: 1000;">
      <el-button  size="small" style="text-align: right;"  type="primary" @click="isqpisboot = !isqpisboot">{{isqpisboot ? $t('All.取消全屏'): $t('All.全屏')}}</el-button>
      <el-button size="small" style="text-align: right;"  type="primary" v-print="'#printTest'">{{ $t('All.打印') }}</el-button>
      <el-button  size="small" style="text-align: right;" v-if="form.State == null" type="primary" @click="ZhUserAdd(form, 2)">{{ $t('All.提交') }}
      </el-button>
      <el-button size="small" style="text-align: right;" v-if="form.State == null" type="success" @click="ZancunZhUserAdd(form, 3)">
        {{ $t('All.暂存') }}</el-button>
      <el-button size="small" style="text-align: right;" v-if="form.State == 3 || form.State == 1" type="primary"
        @click="ZhAUserDataUp(form, 2)">{{ $t('All.送呈') }}</el-button>
      <el-button size="small" style="text-align: right;" v-if="form.State == 3 || form.State == 1" type="success"
        @click="ZhAUserDataUp(form, 3)">{{ $t('All.暂存') }}</el-button>
      <el-button  size="small" style="text-align: right;" v-if="form.State == 3 || form.State == 1 || this.$store.state.Login.Jurisdiction == 0"  type="danger"
        @click="DelNumbers(form)">Delete</el-button>
    </div>
    <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd'" id="printTest">
    <h2 style="text-align: center;">{{ $t('All.年益实业股份有限公司') }}</h2>
    <h3 style="text-align: center;">{{ $t('All.成品抽检') }}（{{ $t('All.包装') }}）</h3>
    <h4 style="text-align: center;">{{ $t('All.录入人') }}：{{ form.Enteredby }}，{{ $t('All.检验员') }}：{{ this.$store.state.Login.Username }}</h4>
    <h4 style="text-align: center;">{{ $t('All.系统单号') }}：{{ form.Numbers }}</h4>
    <div style=" padding: 10px; text-align: center; line-height: 2vw;">
      <table border="1" style="width:100%;" align="center" cellpadding="10"
        cellspacing="0">
        <tr>
          <td style="min-width:30px">{{ $t('All.制造班别') }}</td>
          <td>{{ $t('All.客户') }}</td>
          <td>{{ $t('All.品名') }}</td>
          <td>{{ $t('All.三位码') }}</td>
          <td>{{ $t('All.图面编号') }}</td>
          <td>{{ $t('All.入库箱数') }}</td>
          <td colspan="2">{{ $t('All.检验时间') }}</td>
        </tr>
        <tr>
          <td class="tdd" v-if="form.State == 0 || form.State == 2">{{ form.Determine }}</td>
          <td class="tdd" v-if="form.State == 3 || form.State == 1 || form.State == null">
            <el-select v-model="form.Manufacturing">
              <el-option v-for="item in options1" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </td>
          <td class="tdd">{{ form.Customer }}</td>
          <td class="tdd">{{ form.Productname }}</td>
          <td class="tdd">{{ form.Three }}</td>
          <td class="tdd">{{ form.Drawingnumber }}</td>
          <td class="tdd">{{ form.Number }}</td>
          <td class="tdd" colspan="2">{{ form.Inspectiontime }}</td>
        </tr>
        <tr>
          <td>{{ $t('All.抽箱基准') }}</td>
          <td class="tdd" colspan="3">{{ form.Benchmark }}</td>
          <td>{{ $t('All.参考文件') }}</td>
          <td class="tdd">{{ form.Reference }}</td>
          <td>{{ $t('All.判定') }}</td>
          <td class="tdd" v-if="form.State == 0 || form.State == 2">{{ form.Determine }}</td>
          <td class="tdd" v-if="form.State == 3 || form.State == 1 || form.State == null">
            <el-select v-model="form.Determine">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
          </td>
        </tr>
      </table>
      <div class="uploaddise admin">
        <h3 style="text-align: center;">{{ $t('All.参考图面') }}</h3>
        <el-upload :action="`${$Upload}/api/Zh/Imgadmin/`" list-type="picture-card" :data="filedata"
          :file-list="fileList">
          <div slot="file" slot-scope="{file}">
            <img class="el-upload-list__item-thumbnail" :src="file.url" :alt="file.name">
            <span class="el-upload-list__item-actions" :alt="file.name">
              <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span v-if="false">
                <i class="el-icon-delete"></i>
              </span>
            </span>
            <p>{{ file.name }}</p>
          </div>
        </el-upload>
      </div>
      <div :class="form.State == 0 ? 'uploaddisejy uploaddise': form.State == 2 ? 'uploaddisejy uploaddise':'uploaddisejy'">
        <h3 style="text-align: center;">{{ $t('All.检验图面') }}</h3>
        <el-upload :action="`${$Upload}/api/Zh/Imgbehinduser/`" list-type="picture-card" :on-remove="handleRemove" :on-success="handsuccess" :data="filedata"
          :file-list="fileList1">
          <i slot="default" class="el-icon-plus"></i>
          <div slot="file" slot-scope="{file}">
            <img class="el-upload-list__item-thumbnail" :src="file.url" :alt="file.name">
            <span class="el-upload-list__item-actions" :alt="file.name">
              <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(file)">
                <i class="el-icon-zoom-in"></i>
              </span>
              <span v-if="form.State == 3 || form.State == 1  || form.State == null" @click="handleRemove(file)">
                <i class="el-icon-delete"></i>
              </span>
            </span>
            <p>{{ file.name }}</p>
          </div>
        </el-upload>
      </div>
      <el-dialog :visible.sync="dialogVisible" width="80%" append-to-body>
        <img width="100%" :src="dialogImageUrl" alt="">
      </el-dialog>
    </div>
  </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      form: {
        Manufacturing: '', // 制造班别
        Customer: '', // 客户
        Productname: '', // 品名
        Three: '', // 三位吗
        Drawingnumber: '', // 图面编号
        Number: '', // 入库箱数
        Benchmark: '', // 抽箱基准
        Reference: '', // 参考文件
        Determine: '', // 判定
        Inspectiontime: '', // 校验时间
        Enteredby: '' // 录入姓名
      },
      dialogVisible: false,
      filedata: {
        Workorder: ''
      },
      fileList: [],
      fileList1: [],
      isdata: false,
      dialogImageUrl: '',
      options: [{
        value: 'NG',
        label: 'NG'
      }, {
        value: 'PASS',
        label: 'PASS'
      }],
      options1: [{
        value: this.$t('All.一部'),
        label: this.$t('All.一部')
      }, {
        value: this.$t('All.二部'),
        label: this.$t('All.二部')
      }, {
        value: this.$t('All.三部'),
        label: this.$t('All.三部')
      }, {
        value: this.$t('All.四部'),
        label: this.$t('All.四部')
      }],
      dialogImageUrle: '',
      isqpisboot: false
    }
  },
  created () {
    this.form = this.$store.state.UserData
    if (this.form.MBNumbers.length > 0) {
      this.GetAllFiles(this.form.MBNumbers)
    }
    this.filedata.Workorder = this.form.Numbers
    this.GetAllFilesuser(this.form.Numbers)
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['UserData']),
    ...mapMutations(['Productname', 'TabComponentFun']),
    async handleRemove (file) {
      try {
        const { data: res } = await this.$http.post('/api/Zh/ImgDeluser', file)
        if (res.status !== 200) return this.$message.error(this.$t('All.删除失败'))
        for (let index = 0; index < this.fileList1.length; index++) {
          if (this.fileList1[index].name === file.name) {
            this.fileList1.splice(index, 1)
            return this.$message.success(this.$t('All.删除成功'))
          }
        }
      } catch (error) {
        alert(error)
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handsuccess (response) {
      const data = {
        name: '',
        url: ''
      }
      if (response.status !== 200) return this.$message.error(this.$t('All.图片上传失败'))
      data.name = response.name
      data.url = response.url
      this.fileList1.push(data)
    },
    async ZhUserAdd (row, state) {
      row.State = state
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Zh/ZhUserAdd', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.提交成功'))
        this.UserData(null)
        this.Productname(null)
        this.TabComponentFun('OQCSeleItem')
      } catch (error) {
        loadingInstance.close()
        alert(error)
      }
    },
    async ZancunZhUserAdd (row, state) {
      row.State = state
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Zh/ZhUserAdd', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.暂存成功'))
        this.UserData(null)
        this.Productname(null)
        this.TabComponentFun('OQCSeleItem')
      } catch (error) {
        loadingInstance.close()
        alert(error)
      }
    },
    async GetAllFiles (row) {
      try {
        const { data: res } = await this.$http.post('/api/Zh/GetAllFiles?Workorder=' + row)
        if (res.status !== 200) return this.$message.error(this.$t('All.数据异常'))
        this.fileList = res.response
      } catch (error) {
        alert(error)
      }
    },
    async GetAllFilesuser (row) {
      try {
        const { data: res } = await this.$http.get('/api/Zh/GetAllFilesuser?Workorder=' + row)
        if (res.status !== 200) return
        this.fileList1 = res.response
      } catch (error) {
        alert(error)
      }
    },
    async ZhAUserDataUp (row, state) {
      row.State = state
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Zh/ZhAUserDataUp', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.成功'))
        this.UserData(null)
        this.Productname(null)
        this.TabComponentFun('OQCSeleItem')
      } catch (error) {
        loadingInstance.close()
        alert(error)
      }
    },
    async DelNumbers (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Zh/DelNumbers', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.删除成功'))
        this.UserData(null)
        this.Productname(null)
        this.TabComponentFun('OQCSeleItem')
      } catch (error) {
        loadingInstance.close()
        alert(error)
      }
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => { })
    }
  }
}
</script>
<style lang="less" scoped>
.wbobye {
  position: absolute/fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: #fff;
}

.el-col {
  border: 1px solid;
  margin-right: -1px;
  margin-bottom: -1px;
  line-height: 4.5vw;
  font-size: 2vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-content {
  min-height: 36px;
}

.bg-purple-light {}

.row-bg {
  background-color: #f9fafc;
}

.el-upload-list__item-thumbnail {
  height: 146px !important;
}

p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.admin {
  border: 1px solid grey;
  border-top: 0px;
  padding-top: 15px;
  padding-left: 15px;
  text-align: left;
}
.uploaddisejy{
  border: 1px solid grey;
  border-top: 0px;
  padding-top: 15px;
  padding-left: 15px;
  text-align: left;
}
</style>
