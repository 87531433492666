<template>
  <div class="Examine">
    <div>
      <el-select v-if="$store.state.Login.Jurisdiction == 0" size="medium" v-model="Factory" style="width: 110px; padding: 0px 0px 0px 10px;" clearable :placeholder="$t('system.选择厂区')">
        <el-option
          v-for="item in $PublicJs.factory"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-input size="medium" clearable v-model="Text" style="width: 200px; padding: 0px 10px 0px 10px;" @keyup.enter.native="Butsele()" :placeholder="$t('All.品名') + '/' + $t('All.工单号')+ '/' + $t('All.系统单号')"></el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="Butsele()">{{$t('All.搜索')}}</el-button>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" :label="this.$t('system.序号')" type="index" width="85">
          </el-table-column>
          <el-table-column align="center" prop="Date" :label="this.$t('system.创建日期')" width="180">
          </el-table-column>
          <el-table-column align="center" prop="Numbers" :label="this.$t('system.系统单号')" width="180">
          </el-table-column>
          <el-table-column align="center" prop="Username" :label="this.$t('system.检验员')" >
          </el-table-column>
          <el-table-column align="center" prop="Product_name" :label="this.$t('system.品名规格')" width="190">
          </el-table-column>
          <el-table-column align="center" prop="Type" :label="this.$t('system.类型')">
          </el-table-column>
          <el-table-column align="center" prop="Workorder" :label="this.$t('system.工单号')">
          </el-table-column>
          <el-table-column align="center" prop="State" :label="this.$t('system.状态')">
            <template slot-scope="scope">
                <samp>{{ $PublicJs.Result(scope.row.Result) }}</samp>
            </template>
          </el-table-column>
          <el-table-column align="center"  :label="this.$t('system.操作')" width="100" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" v-if="scope.row.Type == 'FQC'" type="primary" @click="FQCChaYue(scope.row.Numbers)">Review</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Text: '',
      Factory: '',
      tableData: [],
      total: 0,
      page_size: 10,
      pageindex: 1
    }
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
    this.FQCQuery()
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData', 'TabComponentFun']),
    async FQCQuery () {
      const intfs = {
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login,
        Text: this.Text,
        Factory: this.Factory
      }
      const { data: res } = await this.$http.post('/api/FQC/FQCQuery', intfs)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.tableData = res.response.Pgapproval
    },
    async Butsele () {
      this.pageindex = 1
      this.page_size = 10
      this.FQCQuery()
    },
    Setquer () {
      this.pageindex = 1
      this.FQCQuery()
    },
    async FQCChaYue (row) {
      const { data: res } = await this.$http.get('/api/User/FQCUserSelect?Numbers=' + row)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.UserData(res.response)
      this.Addtab(this.$t('All.FQC记录') + '-' + row, 'FQCexaminedata')
    },
    handleSizeChange (val) {
      this.page_size = val
      this.FQCQuery()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.FQCQuery()
    }
  }
}
</script>
<style lang="less" scoped>
/** talbe表头置顶**/
/deep/ .el-table {
    overflow: visible;
 }

 /deep/ .el-table__header-wrapper {
    position: sticky;
    top: 0px;
    z-index: 10;
 }
</style>
