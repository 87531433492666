<template>
  <div>
  <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd'" id="fqcprintTest">
    <div style="text-align: right; z-index: 1000;">
      <el-button size="small" style="text-align: right;"  type="primary" @click="isqpisboot = !isqpisboot">{{isqpisboot ? $t('All.取消全屏'): $t('All.全屏')}}</el-button>
      <el-button size="small" style="text-align: right;"  type="primary" v-print="'#fqcprintTest'">{{$t('All.打印')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="(form.State == 0 && $store.state.Login.Jurisdiction < 6) || $store.state.Login.Jurisdiction == 0" type="warning" @click="qianhe = true">{{this.$t('All.签核')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="(form.State == 1 || form.State == 3) && $store.state.Login.Username == form.Jyy" type="primary" @click="UserDataUp(form)">{{this.$t('system.songceng')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="(form.State == 1 || form.State == 3) && $store.state.Login.Username == form.Jyy" type="primary" @click="UserDataZanCun(form)">{{this.$t('system.zancun')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="$store.state.Login.Username == form.Jyy && (form.State == 1 || form.State == 3) ||  $store.state.Login.Jurisdiction == 0" type="danger" @click="UserDataDel(form)">{{this.$t('system.zuofei')}}</el-button>
      <el-button size="small" type="primary" v-if="!isdisabled"   @click="dialogVisible = true">{{this.$t('system.fjian')}}</el-button>
      <el-button size="small" type="primary" v-if="isdisabled"   @click="dialogVisible = true">{{this.$t('system.chakanfj')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="$store.state.Login.Username == form.Jyy && (form.State == 1 || form.State == 3) && isdisabled" type="success" @click="isdisabled = false">{{this.$t('system.unlock')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="$store.state.Login.Username == form.Jyy && (form.State == 1 || form.State == 3) && !isdisabled" type="primary" @click="isdisabled = true" >{{this.$t('system.lock')}}</el-button>
    </div>
    <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
    <h3 style="text-align: center;">{{this.$t('system.成品检验记录')}}</h3>
    <h4 style="text-align: center;">{{this.$t('system.lururen')}}：{{form.Enteredby}}</h4>
    <h4 style="text-align: center;">{{this.$t('system.systemid')}}：{{form.Numbers}}</h4>
    <div style="text-align: center;">
      <span v-if="form.State == 6 || form.State == 2" style="text-align: center;font-size: 28px;color: red;">{{$PublicJs.Result(form.Result)}}</span><br>
      <span style="text-align: center;font-size: 28px;color: red;">{{$PublicJs.State(form.State)}}</span>
    </div>
    <h3 style="text-align: right;padding-right: 10px;">{{this.$t('system.InspectionDate')}}：{{form.Timsdate}}</h3>
    <h5 style="text-align: right;padding-right: 10px; ">{{$t('system.料号')}}:{{form.Item_no}}</h5>
    <div style=" padding: 10px; text-align: center; line-height: 2vw;" >
      <table border="1"  align="center" cellpadding="10" style="width: 100%;"  cellspacing="0">
         <tr>
              <td style="min-width: 80px;">{{this.$t('FQC.制造班别')}}</td>
              <td style="min-width: 80px;">{{this.$t('FQC.工单号')}}</td>
              <td style="min-width: 80px;">{{this.$t('FQC.客户')}}</td>
              <td colspan="3">{{this.$t('FQC.品名')}}</td>
              <td style="min-width: 80px;" colspan="2">{{this.$t('FQC.图面编号')}}</td>
              <td colspan="3">LOT NO.</td>
          </tr>
          <tr>
              <td class="tdd" v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Banbi"></el-input></td>
              <td class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Banbi" allow-create filterable :placeholder="this.$t('FQC.请选择')">
                  <el-option
                    v-for="i in $PublicJs.Banbi"
                    :key="i.value"
                    :label="i.label"
                    :value="i.value">
                  </el-option>
                </el-select>
              </td>
              <td class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Workorder"></el-input></td>
              <td class="tdd"><el-input disabled v-model="form.Customer"></el-input></td>
              <td colspan="3" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Product_name"></el-input></td>
              <td class="tdd" colspan="2">
                <el-tooltip effect="dark" :content="form.Drawing_number" placement="top-start">
                  <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                    <span @click="fileopen(form.Drawing_numberurl)">{{ form.Drawing_number }}</span>
                  </div>
                </el-tooltip>
              </td>
              <td colspan="3" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Lot_NO"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('FQC.第三方检测报告有效性')}}</td>
            <td style="text-align: left;" colspan="6"><el-input type="textarea" :disabled = 'isdisabled' :rows="5" v-model="form.Effectiveness"></el-input></td>
            <td colspan="3">{{this.$t('FQC.外观')}}</td>
          </tr>
          <tr>
            <td class="tdd">{{this.$t('FQC.抽样数')}}</td>
            <td class="tdd">{{this.$t('FQC.尺寸')}}</td>
            <td class="tdd">{{this.$t('FQC.包装')}}</td>
            <td class="tdd">{{this.$t('FQC.适配')}}</td>
            <td class="tdd">{{this.$t('FQC.交货数')}}</td>
            <td class="tdd">{{this.$t('FQC.入库数')}}</td>
            <td class="tdd">{{this.$t('FQC.箱数')}}</td>
            <td class="tdd">{{this.$t('FQC.颜色')}}</td>
            <td class="tdd">{{this.$t('FQC.插头')}}</td>
            <td class="tdd">CABLE/MOLD</td>
          </tr>
          <tr>
              <td class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Sampling"></el-input></td>
              <td class="tdd" v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Size"></el-input></td>
              <td class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Size" :placeholder="this.$t('FQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
                </el-select>
              </td>
              <td class="tdd" v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Packing"></el-input></td>
              <td class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Packing" :placeholder="this.$t('FQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
                </el-select>
              </td>
              <td class="tdd" v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Other"></el-input></td>
               <td class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Other" :placeholder="this.$t('FQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
                </el-select>
              </td>
              <td class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Delivery"></el-input></td>
              <td class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Warehousing"></el-input></td>
              <td class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Case_number"></el-input></td>
              <td class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Colour"></el-input></td>
              <td class="tdd" v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Plug"></el-input></td>
              <td class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Plug" :placeholder="this.$t('FQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
                </el-select>
              </td>
              <td class="tdd" v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Cable_MOLD"></el-input></td>
              <td class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Cable_MOLD" :placeholder="this.$t('FQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
                </el-select>
              </td>
          </tr>
          <tr>
              <td class="tdd">{{this.$t('FQC.AQL标准')}}</td>
              <td colspan="5">{{this.$t('FQC.抽样标准')}}</td>
              <td class="tdd">{{this.$t('FQC.参考文件')}}</td>
              <td colspan="1" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Standard2"></el-input></td>
              <td class="tdd">{{this.$t('IQC.检验基准表')}}</td>
              <td colspan="2" class="tdd">
                <el-tooltip effect="dark" :content="form.Inspection_benchmark" placement="top-start">
                  <div style="overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;color: #006bda;">
                    <span @click="fileopen(form.Inspection_benchmarkurl)">{{ form.Inspection_benchmark }}</span>
                  </div>
                </el-tooltip>
              </td>
          </tr>
          <tr>
              <td class="tdd" rowspan="2" colspan="2">{{this.$t('FQC.可靠的实验编号')}}</td>
              <td class="tdd" colspan="8">{{this.$t('FQC.电性测试')}}</td>
          </tr>
          <tr>
              <td class="tdd" colspan="3">{{this.$t('FQC.参数设定线位')}}</td>
              <td class="tdd" colspan="3">{{this.$t('FQC.测试仪器编号')}}</td>
              <td class="tdd">{{this.$t('FQC.不良数')}}</td>
              <td class="tdd">{{this.$t('FQC.判定')}}</td>
          </tr>
          <tr>
              <td class="tdd" colspan="2"><el-input :disabled = 'isdisabled' v-model="form.Xrf"><template slot="prepend">XRF</template></el-input></td>
              <td class="tdd" rowspan="2" colspan="3"><el-input disabled v-model="form.Canshu1" type="textarea" :rows="5"></el-input></td>
              <td class="tdd" rowspan="2" colspan="3"><el-input :disabled = 'isdisabled' v-model="form.Yqi1" type="textarea" :rows="5"></el-input></td>
              <td class="tdd" rowspan="3"><el-input :disabled = 'isdisabled' v-model="form.Bniang"></el-input></td>
              <td class="tdd" rowspan="3" v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Pding"></el-input></td>
              <td class="tdd" rowspan="3" v-show="!isdisabled">
                <el-select v-model="form.Pding" :placeholder="this.$t('FQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
                </el-select>
              </td>
          </tr>
          <tr>
              <td class="tdd" colspan="2"><el-input :disabled = 'isdisabled' v-model="form.Icp"><template slot="prepend">ICP</template></el-input></td>
          </tr>
          <tr>
              <td class="tdd"  colspan="2"><el-input :disabled = 'isdisabled' v-model="form.Kkaoqta" type="textarea" :rows="5"></el-input></td>
              <td class="tdd"  colspan="3"><el-input disabled v-model="form.Canshu2" type="textarea" :rows="5"></el-input></td>
              <td class="tdd"  colspan="3"><el-input :disabled = 'isdisabled' v-model="form.Yqi2" type="textarea" :rows="5"></el-input></td>
          </tr>
          <tr>
              <td class="tdd" rowspan="2">{{this.$t('FQC.规格要求')}}</td>
              <td class="tdd" colspan="2">{{this.$t('FQC.量测仪器编号')}}</td>
              <td colspan="7" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Yqish1"></el-input></td>
          </tr>
          <tr>
            <td colspan="1" class="tdd" >{{$t('All.单位') + '/' + $t('All.标注')}}</td>
              <td colspan="5" class="tdd" >{{this.$t('FQC.尺寸检验记录')}}</td>
              <td colspan="1" class="tdd">{{this.$t('FQC.最大值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('FQC.最小值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('FQC.判定')}}</td>
          </tr>
          <tr v-for="(text, index) in form.Parcss" :key="index">
              <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text" ></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text1"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text2" v-enter-next-input></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text3" v-enter-next-input></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text4" v-enter-next-input></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text5" v-enter-next-input></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text6" v-enter-next-input name="Text5" @change="Keyenter(text,index)"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text7"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text8"></el-input></td>
              <td class="tdd" v-if="!isdisabled">
                <el-select v-model="text.Text9" :disabled = 'isdisabled' :placeholder="$t('All.请选择')" @change="addlist(text,index,$event)">
                    <el-option label="Pass" value="Pass"></el-option>
                    <el-option label="Fail" value="Fail"></el-option>
                    <el-option :label="$t('All.判定')" :value="$t('All.判定')"></el-option>
                    <el-option :label="$t('All.增加')" :value="$t('All.增加')"></el-option>
                    <el-option :disabled="form.Parcss.length == 1" :label="$t('All.删除')" :value="$t('All.删除')"></el-option>
                </el-select>
              </td>
              <td class="tdd" v-if="isdisabled">
                  <span :style="text.Text9 == 'Fail'?'color: red;':''">{{ text.Text9 }}</span>
              </td>
          </tr>
          <tr>
            <td class="tdd"  colspan="5"><el-input :disabled = 'isdisabled' :placeholder="$t('FQC.请输入异常描述')" v-model="form.Ycms" type="textarea" :rows="5"></el-input></td>
            <td class="tdd" style="text-align: left;line-height: 2vw;"  colspan="5">{{form.Beizhu}}</td>
        </tr>
      </table>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('FQC.核准')}}: {{form.Hz}}</span></el-col>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('FQC.检验员')}}: {{form.Jyy}}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><h6></h6></el-col>
        <el-col :span="12" style="border: 0px solid transparent;padding-right: 20px;text-align: right;"><span style="font-size: 1.5vw;">NYP1001-02-J</span></el-col>
      </el-row>
    </div>
    <el-dialog
    :title="this.$t('system.fjian')"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose" append-to-body>
      <fqcUpload :isdata = "isdisabled" :fqcadmin = "form.Product_name"></fqcUpload>
    </el-dialog>
  </div>
  <FQCqianhe v-if="qianhe" :Qianhecolse = "Qianhecolse" :UserDataJiean="UserDataJiean" :UserDataBohui="UserDataBohui"/>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import fqcUpload from './Upload/FQCUpload'
import FQCqianhe from './Upload/FQCqianhe'
import Vue from 'vue'
// 自定义指令，实现按下enter后，光标自动跳转下一个输入框
Vue.directive('enterNextInput', {
  inserted: function (el) {
    el.addEventListener('keypress', function (e) {
      e = e || window.event
      const charcode = typeof e.charCode === 'number' ? e.charCode : e.keyCode
      if (charcode === 13) {
        try {
          var dom = document.getElementsByTagName('input')
          for (var i = 0; i < dom.length; i++) {
            if (dom[i] === document.activeElement) {
              if (dom[i].name === 'Text5') {
                dom[i + 6].focus()
              } else {
                dom[i + 1].focus()
              }
              return
            }
          }
        } catch (error) {

        }
      }
    })
  }
})
export default {
  data () {
    return {
      form: {
        Id: 0,
        Timsdate: '', // 检验员填写日期
        Date: new Date(), // 写入时间
        Banbi: '', // 班别
        Workorder: '', // 工单号
        Customer: '', // 客户
        Product_name: '', // 品名规格
        Drawing_number: '', // 图面编号
        Edition: '', // 版本
        LOT_NO: '', // LOT_NO
        Delivery: '', // 交货数
        Warehousing: '', // 入库数
        Case_number: '', // 箱号
        Standard1: '', // AQL标准1
        Standard2: '', //  AQL标准2
        Sampling: '', // 抽样数
        Colour: '', // 颜色
        Plug: '', // 插头
        CABLE_MOLD: '', // CABLE_MOLD
        Size: '', // 尺寸
        Packing: '', // 包装
        Other: '', // 其它
        Xrf: '', // XRF
        Icp: '', // ICQ
        Kkaoqta: '', // 可靠的实验其他
        Canshu1: '', // 参数设定1
        Canshu2: '', // 参数设定2
        Yqi1: '', // 测试仪器编号1
        Yqi2: '', // 测试仪器编号2
        Bniang: '', // 不良数
        Pding: '', // 判定
        Yqish1: '', // 量测仪器/编号1
        Yqish2: '', // 量测仪器/编号2
        Ycms: '', // 异常描述
        Beizhu: '', // 备注
        Hz: '', // 核准人
        Jyy: '', // 检验员
        Enteredby: '' // 录入人
      },
      isdisabled: true,
      dialogVisible: false,
      dialogVisiblee: false,
      dialogImageUrl: 'http://127.0.0.1:5006/QCUser/11235/202109123514.png',
      filedata: {
        Workorder: '11235'
      },
      fileList: [],
      value: localStorage.getItem('locale') || '',
      reset: false,
      isqpisboot: false,
      qianhe: false
    }
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename'],
  created () {
    if (this.$store.state.UserData === null) {
      this.form.Enteredby = this.$store.state.Login.Username
    } else {
      this.form = this.$store.state.UserData
      this.FQCNumerb(this.form.Numbers)
    }
  },
  mounted () {

  },
  components: {
    fqcUpload,
    FQCqianhe
  },
  methods: {
    Keyenter (item, index) {
      this.form.Parcss[index].Text9 = ''
      this.indeexDomain(item, index)
    },
    ...mapMutations(['UserData']),
    ...mapMutations(['FQCNumerb', 'TabComponentFun', 'ConductIs']),
    Qianhecolse () {
      this.qianhe = false
    },
    fileopen (url) {
      this.ConductIs('')
      this.$store.state.Url = ''
      this.$nextTick(() => { // 异步关闭
        setTimeout(() => {
          // 在延迟2秒后执行的代码
          this.$store.state.Url = this.$Upload + '/' + url
          this.ConductIs('ConductIs')
        }, 500)
      })
      // window.open(url, '_blank')
    },
    indeexDomain (text, index) {
      this.form.Parcss[index].Text9 = ''
      const valany = []
      if (text.Text2 !== null && text.Text2 !== '' && text.Text2 !== undefined) {
        valany.push(parseFloat(text.Text2))
      }
      if (text.Text3 !== null && text.Text3 !== '' && text.Text3 !== undefined) {
        valany.push(parseFloat(text.Text3))
      }
      if (text.Text4 !== null && text.Text4 !== '' && text.Text4 !== undefined) {
        valany.push(parseFloat(text.Text4))
      }
      if (text.Text5 !== null && text.Text5 !== '' && text.Text5 !== undefined) {
        valany.push(parseFloat(text.Text5))
      }
      if (text.Text6 !== null && text.Text6 !== '' && text.Text6 !== undefined) {
        valany.push(parseFloat(text.Text6))
      }
      // this.form.Parcss[index].Text7 = Math.max.apply(null, valany)
      // this.form.Parcss[index].Text8 = Math.min.apply(null, valany)
      // 最大值
      // 最小值
      this.form.Parcss[index].Text7 = Math.max.apply(Math, valany)
      this.form.Parcss[index].Text8 = Math.min.apply(Math, valany)
      this.form.Parcss[index].Text9 = this.$PublicJs.Calculation(text.Text, this.form.Parcss[index].Text7, this.form.Parcss[index].Text8)
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'))
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    addDomain (item, index) {
      const contr = {
        Date: new Date(),
        Index: index + 1,
        Numbers: item.Numbers,
        Number: this.form.Number,
        Product_name: item.Product_name, // 品名规格
        Text: item.Text,
        Text1: item.Text1,
        Text2: '',
        Text3: '',
        Text4: '',
        Text5: '',
        Text6: '',
        Text7: '',
        Text8: '',
        Text9: ''
      }
      this.form.Parcss.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Parcss.length; i++) {
        this.form.Parcss[i].Index = i
      }
    },
    removeDomain (item) {
      var index = this.form.Parcss.indexOf(item)
      if (index !== -1) {
        this.form.Parcss.splice(index, 1)
      }
      for (let i = 0; i < this.form.Parcss.length; i++) {
        this.form.Parcss[i].Index = i
      }
    },
    addlist (item, index, value) {
      if (value === this.$t('All.增加')) {
        this.addDomain(item, index)
        this.form.Parcss[index].Text9 = ''
        return
      }
      if (value === this.$t('All.删除')) {
        this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
          confirmButtonText: this.$t('All.确认'),
          cancelButtonText: this.$t('All.取消'),
          type: 'warning'
        })
          .then(() => {
            this.removeDomain(item)
            this.form.Parcss[index].Text9 = ''
          })
          .catch(() => {
            this.form.Parcss[index].Text9 = ''
          })
        return
      }
      if (value === this.$t('All.判定')) {
        this.form.Parcss[index].Text9 = ''
        this.indeexDomain(item, index)
      }
    },
    getCurrentTime () {
      // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const gettime = yy + '-' + mm + '-' + dd
      return gettime
    },
    async UserDataUp (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Admin/UserDataUp', row)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(this.$t('All.数据异常'))
      }
      loadingInstance.close()
      this.$message.success(this.$t('All.送呈成功'))
      this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
      this.RemoveTab(this.$t('All.FQC检验单') + '-' + row.Numbers)
    },
    async UserDataZanCun (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Admin/UserDataUpZancun', row)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(this.$t('All.数据异常'))
      }
      loadingInstance.close()
      this.$message.success(this.$t('All.暂存成功'))
      this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
      this.RemoveTab(this.$t('All.FQC检验单') + '-' + row.Numbers)
    },
    async UserDataBohui () {
      this.form.Hz = this.$store.state.Login.Username
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Admin/UserDataBohui', this.form)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(res.msg)
      }
      loadingInstance.close()
      this.qianhe = false
      this.$message.success(this.$t('All.驳回成功'))
      this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
      this.RemoveTab(this.$t('All.FQC检验单') + '-' + this.form.Numbers)
    },
    async UserDataJiean (row) {
      const fqcformpost = {
        UserData: this.$store.state.Login,
        FQC_User: this.form,
        Qianheclass: row
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Admin/UserDataJiean', fqcformpost)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(res.msg)
      }
      loadingInstance.close()
      this.qianhe = false
      this.$message.success(this.$t('All.核可成功'))
      this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
      this.RemoveTab(this.$t('All.FQC检验单') + '-' + this.form.Numbers)
    },
    async UserDataDel (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Admin/UserDataDel', row)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(this.$t('All.数据异常'))
      }
      loadingInstance.close()
      this.$message.success(this.$t('All.Del成功'))
      this.Renewtargename(this.$t('All.待审'), 'Examine', 'UserJyySele')
      this.RemoveTab(this.$t('All.FQC检验单') + '-' + row.Numbers)
    },
    handleRemove (file, fileList) {
      console.log(file, fileList)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handSuccess (response, file, fileList) {
      this.fileList.push(response)
    },
    text9pass (index, state) {
      this.form.Parcss[index].Text9 = state
    },
    printContent () {
      const subOutputRankPrint = document.getElementById('print') // 要打印区域的元素id
      console.log(subOutputRankPrint.innerHTML)
      const newContent = subOutputRankPrint.innerHTML
      const oldContent = document.body.innerHTML
      document.body.innerHTML = newContent
      window.print()
      window.location.reload()
      document.body.innerHTML = oldContent
      return false
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
  min-width: 903px;
}
  .el-col {
    border: 1px solid;
    margin-right: -1px;
     margin-bottom: -1px;
    line-height: 4.5vw;
    font-size: 2vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .grid-content {
    min-height: 36px;
  }
  .bg-purple-light{

  }
  .row-bg {
    background-color: #f9fafc;
  }
</style>
