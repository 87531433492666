<template>
  <div class="login-page">
    <div class="background-image"></div>
    <!--<div class="header">年益集团</div>-->
    <div class="login-form">
      <div class="login-form-content">
        <h2 style="text-align: center;color: rgb(29 103 214);">{{$t('login.company')}}</h2>
        <h3 style="text-align: center;color: rgb(29 103 214);">{{$t('login.mintite')}}</h3>
        <div v-show="isqrcode" id="login_container" style="height: 300px; width: 300px;display: contents; padding: 60px;"></div>
        <el-form ref="form" style="padding: 10px;" :model="form">
          <el-form-item >
            <el-input style="width: 300px;" v-model="form.User" clearable :placeholder="this.$t('login.user')"></el-input>
          </el-form-item>
          <el-form-item >
            <el-input style="width: 300px;" show-password v-model="form.Password" @keyup.enter.native.native="UserLogin(form)" :placeholder="this.$t('login.password')"></el-input>
          </el-form-item>
          <el-form-item prop="pictureCode">
            <el-input style="width: 300px;" v-model="pictureCode" autocomplete="off" class="pictureCode"
            :placeholder="$t('login.请输入验证码')" maxlength="4" onkeyup="value=value.replace(/[^\d]/g,'')"  @keyup.enter.native.native="UserLogin(form)">
              <template slot="append">
                <div style="border: none" @click="refreshCode">
                  <!-- 3. 使用 Sidentify 组件 -->
                  <Sidentify :identifyCode="identifyCode"></Sidentify>
                </div>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item >
            <el-select style="width: 300px;" v-model="value" @change="languageXZ" :placeholder="this.$t('login.placeholder')">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
            </el-select>
        </el-form-item>
        <el-form-item >
          <el-button style="width: 300px;" @click="UserLogin(form)" type="primary">{{this.$t('login.clicklogin')}}</el-button>
          <br>
          <el-button style="width: 300px;margin-top: 10px;" >{{this.$t('login.APP下载')}}</el-button>
        </el-form-item>
        </el-form>
      </div>
    </div>
    <transition mode="out-in">
      <component :is="componetis" :Userid="Userid" :UserLogin="UserLogin"></component>
    </transition>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
export default {
  name: 'LoginPage',
  data () {
    return {
      form: {
        User: '',
        Password: ''
      },
      options: [{
        value: 'zh',
        label: this.$t('login.中文')
      }, {
        value: 'en',
        label: 'English'
      }, {
        value: 'vn',
        label: 'ViệtName'
      }],
      value: localStorage.getItem('locale') || 'zh',
      authCode: '',
      componetis: '',
      Userid: '',
      isqrcode: false,
      pictureCode: '', //  输入input框的验证码
      identifyCode: '' //  存储的图片验证码
    }
  },
  components: {
    Userediet: () => import('../views/User.vue'),
    Sidentify: () => import('./Sidentify.vue')
  },
  created () {
    this.Userediet = ''
  },
  // 监听路有变化获取code参数值
  watch: {
    '$route.query': {
      handler (newVal, oldVal) {
        this.authCode = this.$route.query.code
        this.authCode && this.getStaffInfo() // 获取到code，调用后端接口换取token
      }
    }
  },
  mounted () {
    this.refreshCode()
  },
  methods: {
    async getStaffInfo () {
      const { data: res } = await this.$http.get(`/api/Authenticate/Getwxtoken?code=${this.authCode}`)
      if (res.status !== 200) {
        this.Userid = res.Userid
        this.componetis = 'Userediet'
        return
      }
      this.$message.success(this.$t('login.loginmessageyes'))
      this.loginlist(res.Userdata)
      window.sessionStorage.setItem('token', res.token)
      this.$router.push('/Main')
    },
    ...mapMutations(['loginlist']),
    async UserLogin (row) {
      try {
        if (this.pictureCode !== this.identifyCode) {
          this.refreshCode()
          return this.$message.error(this.$t('login.验证码错误'))
        }
        const { data: res } = await this.$http.post('/api/Authenticate/login', row)
        if (res.status !== 200) return this.$message.error(this.$t('login.loginmessageno'))
        this.$message.success(this.$t('login.loginmessageyes'))
        this.loginlist(res.Userdata)
        window.sessionStorage.setItem('token', res.token)
        this.$router.push('/Main')
      } catch (error) {
        this.$message.error(this.$t('login.loginmessageno'))
      }
    },
    languageXZ () {
      this.$i18n.locale = this.value
      localStorage.setItem('locale', this.value)
      this.$router.go(0)
    },
    refreshCode () {
      var numCode = ''
      for (var i = 0; i < 4; i++) {
        numCode += Math.floor(Math.random() * 10)
      }
      this.identifyCode = numCode
    }
  }
}
</script>

<style lang="less" scoped>
/* Reset default margin and padding */
/* Make the body take up the full screen */
.login-page {
  margin: 0;
  padding: 0;
  height: 100vh;
  overflow: hidden;
}

/* Set the background image to cover the entire screen */
.background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: gradientAnimation 88s infinite alternate;
}

@keyframes gradientAnimation {
  0% {
    background: linear-gradient(45deg, #409eff, #af4261);
  }
  25% {
    background: linear-gradient(45deg, #af4261, #409eff);
  }
  50% {
    background: linear-gradient(-45deg, #409eff, #af4261);
  }
  75% {
    background: linear-gradient(45deg, #af4261, #409eff);
  }
  100% {
    background: linear-gradient(-45deg, #409eff, #af4261);
  }
}
/* Center the "年益集团" text above the login form */
.header {
  position: absolute;
  top: 40%;
  left: 0;
  width: calc(95% - 340px);
  transform: translateY(-50%);
  text-align: center;
  color: #ffffff;
  font-size: 8rem;
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* Center the login form on the right
.login-form {
  position: absolute;
  top: 50%;
  right: 5%;
  transform: translateY(-50%);
  width: 340px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}
*/
.login-form {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 340px;
  background-color: #ffffff;
  padding: 20px;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  border-radius: 5px;
}

/* Center the contents of the login form */
.login-form-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 5%;
}

/* Style the login form inputs */
.login-form input {
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
  box-sizing: border-box;
  font-size: 1.1rem;
}

/* Style the login form button */
.login-form button {
  padding: 10px;
  background-color: #3a7bcf;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 1.1rem;
  width: 100%;
}
::v-deep .pictureCode .el-input-group__append {
  padding: 0;
}
</style>
