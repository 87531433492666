<template>
  <div>
    <div style="position: fixed;top: 55px;right: 10px; z-index: 1000;">
      <el-button size="small" style="text-align: right;"  type="primary" @click="isqpisboot = !isqpisboot">{{isqpisboot ? $t('All.取消全屏'): $t('All.全屏')}}</el-button>
      <el-button size="small" style="text-align: right;"  type="primary" v-print="'#printTest'">{{$t('All.打印')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="form.State == 0 && $store.state.Login.Jurisdiction < 6" type="primary" @click="UserDataJiean(form)">{{this.$t('system.yshou')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="form.State == 0 && $store.state.Login.Jurisdiction < 6" type="primary" @click="IQCUsertuihuo(form)">{{this.$t('system.tuihuo')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="form.State == 0 && $store.state.Login.Jurisdiction < 6" type="primary" @click="IQCUsertecai(form)">{{this.$t('system.tecai')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="form.State == 0 && $store.state.Login.Jurisdiction < 6" type="danger" @click="UserDataBohui(form)">{{this.$t('system.bohui')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="(form.State == 1 || form.State == 3) && $store.state.Login.Username == form.Jyy" type="primary" @click="dialogVisibleprin = true">{{this.$t('system.songceng')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="form.State == 3 && $store.state.Login.Username == form.Jyy" type="primary" @click="UserDataZanCun(form)">{{this.$t('system.zancun')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="($store.state.Login.Username == form.Jyy || $store.state.Login.Jurisdiction == 0)  && (form.State == 1 || form.State == 3) ||  $store.state.Login.Jurisdiction == 0" type="danger" @click="UserDataDel(form)">{{this.$t('system.zuofei')}}</el-button>
      <el-button size="small" type="primary" v-if="!isdisabled"   @click="dialogVisible = true">{{this.$t('system.fjian')}}</el-button>
      <el-button size="small" type="primary" v-if="isdisabled"   @click="dialogVisible = true">{{this.$t('system.chakanfj')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="$store.state.Login.Username == form.Jyy && (form.State == 1 || form.State == 3) && isdisabled" type="success" @click="isdisabled = false">{{this.$t('system.unlock')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="$store.state.Login.Username == form.Jyy && (form.State == 1 || form.State == 3) && !isdisabled" type="primary" @click="isdisabled = true" >{{this.$t('system.lock')}}</el-button>
    </div>
    <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd'" id="printTest">
    <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
    <h3 style="text-align: center;">{{this.$t('system.jinliaojy')}}</h3>
    <h4 style="text-align: center;">{{this.$t('system.systemid')}}：{{form.Numbers}}</h4>
    <div style="text-align: center;">
      <span style="text-align: center;font-size: 28px;color: red;">{{State(form.State)}}</span>
    </div>
    <div style="padding: 10px; text-align: center; line-height: 2vw;">
      <table border="1"  align="center" cellpadding="10" style="width: 100%;"  cellspacing="0">
          <tr>
              <td >{{this.$t('IQC.供应商')}}</td>
              <td >{{this.$t('IQC.交货数')}}</td>
              <td >{{this.$t('IQC.承认书')}}</td>
              <td colspan="3" >{{this.$t('IQC.品名')}}</td>
              <td colspan="2">{{$t('All.进料日期')}}</td>
              <td colspan="2" >{{this.$t('IQC.订单号')}}</td>
          </tr>
          <tr>
            <td class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Supplier"></el-input></td>
            <td class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Delivery"></el-input></td>
            <td class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Acknowledgment"></el-input></td>
            <td  colspan="3" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Product_name"></el-input></td>
            <td  colspan="2" class="tdd"><el-date-picker
                                          :disabled = 'isdisabled'
                                          v-model="form.Inspection_No"
                                          type="date"
                                          :placeholder="$t('All.选择日期')" value-format="yyyy/MM/dd">
                                        </el-date-picker></td>
            <td  colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Order_Number"></el-input></td>
          </tr>
          <tr>
            <td >{{this.$t('IQC.入库数')}}</td>
            <td >物料单位</td>
            <td >{{this.$t('IQC.抽样数')}}</td>
            <td >{{this.$t('IQC.工程图面')}}</td>
            <td  colspan="2" >{{this.$t('IQC.料号')}}</td>
            <td  colspan="2" >{{this.$t('IQC.抽样类型')}}</td>
            <td  colspan="2" >{{this.$t('IQC.检验基准表')}}</td>
          </tr>
          <tr>
            <td class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Warehousing"></el-input></td>
            <td class="tdd"><el-input  :disabled = 'isdisabled'  v-model="form.Company"></el-input></td>
            <td class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Sampling"></el-input></td>
            <td class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Drawing_Surface"></el-input></td>
            <td  colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Item_No"></el-input></td>
            <td  colspan="2" class="tdd" v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Sampling_type"></el-input></td>
            <td  colspan="2" class="tdd" v-show="!isdisabled">
                <el-select v-model="form.Sampling_type" :placeholder="this.$t('IQC.请选择类型')">
                  <el-option :label="this.$t('IQC.加严')" :value="this.$t('IQC.加严')"></el-option>
                  <el-option :label="this.$t('IQC.正常')" :value="this.$t('IQC.正常')"></el-option>
                  <el-option :label="this.$t('IQC.减量')" :value="this.$t('IQC.减量')"></el-option>
                </el-select>
            </td>
            <td  colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Inspection_Table"></el-input></td>
          </tr>
          <tr>
            <td colspan="10">{{this.$t('IQC.抽样数按AQL')}}</td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.检验项目')}}</td>
            <td colspan="2">{{this.$t('IQC.检验标准')}}</td>
            <td colspan="4">{{this.$t('IQC.检验内容')}}</td>
            <td>{{this.$t('IQC.判定')}}</td>
            <td colspan="2">{{this.$t('IQC.备注')}}</td>
          </tr>
          <tr>
            <td class="tdd" rowspan="4">{{this.$t('IQC.外观')}}</td>
            <td rowspan="4" colspan="2">{{this.$t('IQC.依样品丶承认书')}}</td>
            <td class="tdd" colspan="4"><el-input :disabled = 'isdisabled' v-model="form.Colour"><template slot="prepend">{{this.$t('IQC.颜色')}}:</template></el-input></td>
            <td v-show="isdisabled" ><el-input :disabled = 'isdisabled' v-model="form.Determine2"></el-input></td>
            <td v-show="!isdisabled">
              <el-select v-model="form.Determine2" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input type="textarea" :rows="1" :disabled = 'isdisabled' v-model="form.Remarks2"></el-input></td>
          </tr>
          <tr>
            <td class="tdd" colspan="4"><el-input  class="cable_Plug" :disabled = 'isdisabled' v-model="form.Plug"><template slot="prepend">{{this.$t('IQC.插头')}}:</template></el-input></td>
            <td v-show="isdisabled" ><el-input :disabled = 'isdisabled' v-model="form.Determine11"></el-input></td>
            <td v-show="!isdisabled">
              <el-select v-model="form.Determine11" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input type="textarea" :rows="1" :disabled = 'isdisabled' v-model="form.Remarks11"></el-input></td>
          </tr>
          <tr>
            <td class="tdd" colspan="4"><el-input class="cable_mold" v-model="form.Cable_MOLD" :disabled = 'isdisabled'><template slot="prepend">CABLE_MOLD:</template></el-input></td>
            <td v-show="isdisabled" ><el-input :disabled = 'isdisabled' v-model="form.Determine12"></el-input></td>
            <td v-show="!isdisabled">
              <el-select v-model="form.Determine12" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input type="textarea" :rows="1" :disabled = 'isdisabled' v-model="form.Remarks12"></el-input></td>
          </tr>
          <tr>
            <td class="tdd" colspan="4"><el-input class="cable_mold" v-model="form.Shape" :disabled = 'isdisabled'><template slot="prepend">{{$t('All.形状')}}:</template></el-input></td>
            <td v-show="isdisabled" ><el-input :disabled = 'isdisabled' v-model="form.Determine3"></el-input></td>
            <td v-show="!isdisabled">
              <el-select v-model="form.Determine3" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input type="textarea" :rows="1" :disabled = 'isdisabled' v-model="form.Remarks3"></el-input></td>
          </tr>
          <tr v-if="form.FQCNumbers === '' || form.FQCNumbers === null || form.FQCNumbers === undefined">
            <td class="tdd" rowspan="2">{{$t('All.可靠度实验项')}}</td>
            <td rowspan="2" colspan="2">{{$t('All.进料检验标准')}}</td>
            <td class="tdd" colspan="4"><el-input type="textarea" :rows="5" :disabled = 'isdisabled' v-model="form.Shiyx1"></el-input></td>
            <td v-show="isdisabled" ><el-input :disabled = 'isdisabled' v-model="form.Determine13"></el-input></td>
            <td v-show="!isdisabled">
              <el-select v-model="form.Determine13" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input type="textarea" :rows="5" :disabled = 'isdisabled' v-model="form.Remarks2"></el-input></td>
          </tr>
          <tr v-if="form.FQCNumbers === '' || form.FQCNumbers === null || form.FQCNumbers === undefined">
            <td class="tdd" colspan="4"><el-input type="textarea" :rows="5" :disabled = 'isdisabled' v-model="form.Shiyx2"></el-input></td>
            <td v-show="isdisabled" ><el-input :disabled = 'isdisabled' v-model="form.Determine14"></el-input></td>
            <td v-show="!isdisabled">
              <el-select v-model="form.Determine14" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input type="textarea" :rows="5" :disabled = 'isdisabled' v-model="form.Remarks14"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.电器特性')}}</td>
            <td colspan="2">{{this.$t('IQC.承认书丶工程图面')}}</td>
            <td colspan="4"><el-input :disabled = 'isdisabled' v-model="form.Text1"></el-input></td>
            <td v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Determine4"></el-input></td>
            <td v-show="!isdisabled">
              <el-select v-model="form.Determine4" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input type="textarea" :rows="1" :disabled = 'isdisabled' v-model="form.Remarks4"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.试用')}}</td>
            <td colspan="2">{{this.$t('IQC.与配套使用')}}</td>
            <td colspan="4"><el-input :disabled = 'isdisabled' v-model="form.Text2"></el-input></td>
            <td v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Determine5"></el-input></td>
            <td v-show="!isdisabled">
              <el-select v-model="form.Determine5" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input type="textarea" :rows="1" :disabled = 'isdisabled' v-model="form.Remarks5"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.包装')}}</td>
            <td colspan="2">{{this.$t('IQC.依承认书丶标示卡')}}</td>
            <td colspan="4"><el-input :disabled = 'isdisabled' v-model="form.Text3"></el-input></td>
            <td v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Determine6"></el-input></td>
            <td v-show="!isdisabled">
              <el-select v-model="form.Determine6" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input type="textarea" :rows="1"  :disabled = 'isdisabled' v-model="form.Remarks6"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.第三方检测报告有效性')}}</td>
            <td colspan="2"></td>
            <td colspan="4"><el-input type="textarea" :rows="5" :disabled = 'isdisabled' v-model="form.Text4"></el-input></td>
            <td v-show="isdisabled"><el-input :rows="5" :disabled = 'isdisabled' v-model="form.Determine7"></el-input></td>
            <td v-show="!isdisabled">
              <el-select v-model="form.Determine7" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input type="textarea" :rows="5" :disabled = 'isdisabled' v-model="form.Remarks7"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.厂商出货报告批号')}}</td>
            <td colspan="2"></td>
            <td colspan="4"><el-input :disabled = 'isdisabled' v-model="form.Text5"></el-input></td>
            <td v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Determine8"></el-input></td>
            <td v-show="!isdisabled">
              <el-select v-model="form.Determine8" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input  type="textarea" :disabled = 'isdisabled' v-model="form.Remarks8"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.来料实物批号')}}</td>
            <td colspan="2"></td>
            <td colspan="4"><el-input :disabled = 'isdisabled' v-model="form.Text6"></el-input></td>
            <td v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Determine9"></el-input></td>
            <td v-show="!isdisabled">
              <el-select v-model="form.Determine9" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input type="textarea" :rows="1" :disabled = 'isdisabled' v-model="form.Remarks9"></el-input></td>
          </tr>
          <tr v-if="false">
            <td>{{$t('All.出货报告其它讯息确认')}}</td>
            <td colspan="2"></td>
            <td colspan="4"><el-input :disabled = 'isdisabled' v-model="form.Text7"></el-input></td>
            <td v-show="isdisabled"><el-input :disabled = 'isdisabled' v-model="form.Determine10"></el-input></td>
            <td v-show="!isdisabled">
              <el-select v-model="form.Determine10" :placeholder="$t('All.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input type="textarea" :rows="1" :disabled = 'isdisabled' v-model="form.Remarks10"></el-input></td>
          </tr>
          <tr>
              <td class="tdd" rowspan="2">{{this.$t('IQC.规格要求')}}</td>
              <td class="tdd" colspan="2">{{this.$t('IQC.量测仪器编号')}}</td>
              <td colspan="7" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Yqi1"></el-input></td>
          </tr>
          <tr>
              <td colspan="6" class="tdd" >{{this.$t('IQC.尺寸检验记录')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最大值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最小值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.判定')}}</td>
          </tr>
          <tr v-for="(text, index) in form.Parcss" :key="index">
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text" ></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text1"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text2"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text3"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text4"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text5"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text6"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text7"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input :disabled = 'isdisabled' v-model="text.Text8"></el-input></td>
              <td class="tdd" style="min-width: 150px;" v-show ="$PublicJs.text9Ref(text.Text) && !isdisabled && form.FQCNumbers == undefined">
                <el-button type="primary" v-show="!text.Text9"  @click="addDomain(text,index)" size="mini">Add</el-button>
                <el-button type="primary" v-show="!text.Text9"  @click="removeDomain(text)" size="mini">Del</el-button>
                <el-button type="primary" v-show="!text.Text9"  style="margin-left:-8px;" @click="text9pass(index,'Pass')" size="mini">Pass</el-button>
                <el-button type="primary" v-show="!text.Text9"   @click="text9pass(index,'Fail')" size="mini">Fail</el-button>
                <el-select v-model="form.Parcss[index].Text9" v-show="text.Text9" :placeholder="$t('All.请选择')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                </el-select>
              </td>
              <td class="tdd" v-show ="!$PublicJs.text9Ref(text.Text) && !isdisabled && form.FQCNumbers == undefined">
                <el-button type="primary" v-show="!text.Text9" @click="addDomain(text,index)" size="mini">Add</el-button>
                <el-button type="primary" v-show="!text.Text9" @click="removeDomain(text)" size="mini">Del</el-button>
                <el-button type="primary" v-show="!text.Text9" @click="indeexDomain(text, index)" size="mini">Result</el-button>
                <el-button :type="text.Text9 == 'Pass' ? 'success':'danger'" v-show="text.Text9"  @click="indeexDomain(text, index)" size="mini">{{text.Text9}}</el-button>
              </td>
              <td class="tdd" style="min-width: 60px;" v-show="isdisabled"><el-input disabled v-model="text.Text9"></el-input></td>
              <td class="tdd" style="min-width: 60px;" v-show="!isdisabled && form.FQCNumbers != undefined"><el-input disabled v-model="text.Text9"></el-input></td>
          </tr>
          <tr>
            <td style="text-align: left;" colspan="10" v-html="this.$t('IQC.beizhu')"></td>
          </tr>
      </table>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.核准')}}: {{form.Hz}}</span></el-col>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.检验员')}}: {{form.Jyy}}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><h6></h6></el-col>
        <el-col :span="12" style="border: 0px solid transparent;padding-right: 20px;text-align: right;"><span style="font-size: 1.5vw;">NYS2401-02 B</span></el-col>
      </el-row>
       <el-dialog
      :title="$t('All.上传附件')"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose" append-to-body>
      <iqcUpload :isdata = "isdisabled" :fqcnumbers = "form.FQCNumbers" :name= "form.Product_name"></iqcUpload>
    </el-dialog>
    <el-dialog
      :title="$t('All.打印选择')"
      :visible.sync="dialogVisibleprin"
      width="500px"
      :before-close="handleClose" append-to-body>
      <el-form :rules="rules" ref="formrules" status-icon :model="prinform" label-position="right" label-width="80px">
         <!-------------------------------------->
        <!-------------------------------------->
        <el-form-item
          v-for="(domain, index) in prinform.Boxlist"
          :label="$t('All.每箱袋')"
          :key="index"
        >
          <el-input v-model="domain.Box" style="width:100px;" autocomplete="off"></el-input>
          <span style="padding: 10px;">{{$t('All.数量')}}:</span><el-input v-model="domain.Boxquantity" style="width:100px;" autocomplete="off"></el-input><el-button v-show="index + 1 === prinform.Boxlist.length" style="margin-left: 11px;" size="mini" type="primary" icon="el-icon-plus"  @click="addDomainbut()"></el-button><el-button v-show="prinform.Boxlist.length > 1" size="mini" type="danger" icon="el-icon-delete"  @click="removeDomainbut(domain)"></el-button>
        </el-form-item>
        <!-------------------------------------->
        <!-------------------------------------->
        <el-form-item :label="$t('All.月份')">
          <el-select v-model="prinform.Month" :placeholder="$t('All.请选择月份区域')">
            <el-option
              v-for="item in $PublicJs.Printer"
              :key="item.value"
              :label="item.label"
              :value="item.value">
              <span style="float: left">{{ item.label }}</span>
              <span :style="`float: right; color: ${item.color}; font-size: 13px;`">{{ item.value }}</span>
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('All.进料日期')">
          <el-date-picker
            v-model="prinform.Date"
            type="date"
            :placeholder="$t('All.选择日期')" value-format="yyyy/MM/dd">
          </el-date-picker>
        </el-form-item>
        <el-form-item :label="$t('All.打印机')">
          <el-select v-model="prinform.Printer" :placeholder="$t('All.请选择打印机')">
            <el-option
              v-for="item in this.$store.state.PrinterData"
              :key="item.Name"
              :label="item.Wz"
              :value="item.Name">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisibleprin = false">{{ $t('All.取消') }}</el-button>
        <el-button type="primary" @click="UserDataUp(form,0)">{{ $t('All.确定') }}</el-button>
      </div>
    </el-dialog>
    </div>
  </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import iqcUpload from './Upload/IQCUpload'
export default {
  data () {
    const thav = this
    const checkAge = (rule, value, callback) => {
      if (!value) {
        return callback(new Error(this.$t('All.箱数不能为空')))
      }
      const age = /^[0-9]*$/
      if (!age.test(value)) {
        return callback(new Error(this.$t('All.该项只能为数字')))
      } else {
        if (parseInt(thav.form.Warehousing) % parseInt(value) !== 0) {
          thav.prinform.Boxquantity = (parseInt(thav.form.Warehousing) - parseInt(thav.prinform.Lintou)) / parseInt(value)
          return callback()
        }
        thav.prinform.Boxquantity = parseInt(thav.form.Warehousing) / parseInt(value)
        callback()
      }
    }
    return {
      form: {
        Date: '', // 创建时间
        Enteredby: '', // 录入人
        Hz: '', // 核准人
        Jyy: '', // 检验员
        Ycms: '', // 异常描述
        Beizhu: '', // 1.量测的尺寸规格按抽样数10%的比例记录；当记录的尺寸规格个数大于10组数据时，则记录前10pcs样本的量测结果值。
        Delivery: '', // 交货数
        Warehousing: '', // 入库数
        Sampling: '', // 抽样数
        Product_name: '', // 品名规格
        Standard1: '', // AQL标准1
        Standard2: '', //  参考文件
        Supplier: '', //  供应商
        Acknowledgment: '', // 承认书
        Inspection_No: '', //  检验单号
        Order_Number: '', //  订单号
        Drawing_Surface: '', //  工程图面
        Item_No: '', //  料号
        Sampling_type: '', //  抽样类型
        Inspection_Table: '', //  检验基准表
        Colour: '', // 颜色
        Shape: '', // 形状
        Text1: '', // 检验内容
        Text2: '', // 检验内容
        Text3: '', // 检验内容
        Text4: '', // 检验内容
        Text5: '', // 检验内容
        Text6: '', // 检验内容
        Text7: '', // 检验内容
        Determine1: '', // 判定
        Determine2: '', // 判定
        Determine3: '', // 判定
        Determine4: '', // 判定
        Determine5: '', // 判定
        Determine6: '', // 判定
        Determine7: '', // 判定
        Determine8: '', // 判定
        Determine9: '', // 判定
        Determine10: '', // 判定
        Remarks1: '', // 备注
        Remarks2: '', // 备注
        Remarks3: '', // 备注
        Remarks4: '', // 备注
        Remarks5: '', // 备注
        Remarks6: '', // 备注
        Remarks7: '', // 备注
        Remarks8: '', // 备注
        Remarks9: '', // 备注
        Remarks10: '', // 备注
        Yqi1: '', // 测试仪器编号1
        Lot_NO: '', // LOT_NO
        SHz: '', // iqc签核人
        SJyy: '', // iqc检验员
        Parcss: [], // 检验数据
        Parcss1: [] // 检验数据
      },
      isdata: false,
      isdisabled: true,
      dialogVisible: false,
      isqpisboot: false,
      dialogVisibleprin: false,
      prinform: {
        Boxlist: [
          {
            Box: 0,
            Boxquantity: 0
          }
        ],
        Month: this.$PublicJs.Dateselection(),
        Printer: this.$store.state.PrinterData.length > 0 ? this.$store.state.PrinterData[this.$store.state.PrinterData.length - 1].Name : '',
        Date: new Date()
      },
      rules: {
        Box: [
          { validator: checkAge, trigger: 'blur' }
        ]
      }
    }
  },
  components: {
    iqcUpload
  },
  created () {
    if (this.$store.state.UserData === null) {
      this.TabComponentFun('IQCSeleItem')
      this.$message.error(this.$t('All.模版不存在'))
    } else {
      this.isdata = false
      this.form = this.$store.state.UserData
      this.form.Inspection_No = this.form.Inspection_No === null ? this.form.Date : this.form.Inspection_No
      this.form.SJyy = this.$store.state.Login.Username
      this.form.Timsdate = this.getCurrentTime()
      this.FQCNumerb(this.form.Numbers)
    }
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['UserData']),
    ...mapMutations(['FQCNumerb', 'TabComponentFun']),
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    getCurrentTime () {
      // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const gettime = yy + this.$t('All.年') + mm + this.$t('All.月') + dd + this.$t('All.日')
      return gettime
    },
    async UserDataUp (row, index) {
      row.State = index
      row.Department = 'IQC'
      const iqcfrom = {
        Printer: this.prinform.Printer,
        Boxlist: this.prinform.Boxlist,
        Month: this.prinform.Month,
        Date: this.prinform.Date,
        IQC: row

      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Admin/IQCUserDataUp', iqcfrom)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.error(this.$t('All.数据异常'))
      }
      loadingInstance.close()
      this.$message.success(res.msg)
      this.TabComponentFun('Examine')
    },
    async UserDataZanCun (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Admin/IQCUserDataUpZancun', row)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(this.$t('All.数据异常'))
      }
      loadingInstance.close()
      this.$message.success(this.$t('All.暂存成功'))
      this.TabComponentFun('Examine')
    },
    async UserDataBohui (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Admin/IQCUserDataBohui', row)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(this.$t('All.数据异常'))
      }
      loadingInstance.close()
      this.$message.success(this.$t('All.驳回成功'))
      this.TabComponentFun('Examine')
    },
    async UserDataJiean (row) {
      row.Hz = this.$store.state.Login.Username
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Admin/IQCUserDataJiean', row)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(this.$t('All.数据异常'))
      }
      loadingInstance.close()
      this.$message.success(this.$t('All.允收成功'))
      this.TabComponentFun('Examine')
    },
    async IQCUsertuihuo (row) {
      row.Hz = this.$store.state.Login.Username
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Admin/IQCUsertuihuo', row)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(this.$t('All.数据异常'))
      }
      loadingInstance.close()
      this.$message.success(this.$t('All.退货成功'))
      this.TabComponentFun('Examine')
    },
    async IQCUsertecai (row) {
      row.Hz = this.$store.state.Login.Username
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Admin/IQCUsertecai', row)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(this.$t('All.数据异常'))
      }
      loadingInstance.close()
      this.$message.success(this.$t('All.特采成功'))
      this.TabComponentFun('Examine')
    },
    async UserDataDel (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Admin/IQCUserDataDel', row)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.success(this.$t('All.数据异常'))
      }
      loadingInstance.close()
      this.$message.success(this.$t('All.Del成功'))
      this.TabComponentFun('Examine')
    },
    removeDomainbut (item) {
      if (this.prinform.Boxlist.length === 1) {
        return this.$message.error(this.$t('All.最少保留一列'))
      }
      var index = this.prinform.Boxlist.indexOf(item)
      if (index !== -1) {
        this.prinform.Boxlist.splice(index, 1)
      }
    },
    addDomainbut () {
      this.prinform.Boxlist.push({
        Box: '',
        Boxquantity: ''
      })
    },
    addDomain (item, index) {
      const contr = {
        Id: 0,
        Date: new Date(),
        Index: index + 1,
        Numbers: item.Numbers,
        Product_name: item.Product_name, // 品名规格
        Text: item.Text,
        Text1: '',
        Text2: '',
        Text3: '',
        Text4: '',
        Text5: '',
        Text6: '',
        Text7: '',
        Text8: '',
        Text9: ''
      }
      this.form.Parcss.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Parcss.length; i++) {
        this.form.Parcss[i].Index = i
      }
    },
    removeDomain (item) {
      var index = this.form.Parcss.indexOf(item)
      if (index !== -1) {
        this.form.Parcss.splice(index, 1)
      }
      for (let i = 0; i < this.form.Parcss.length; i++) {
        this.form.Parcss[i].Index = i
      }
    },
    indeexDomain (text, index) {
      const valany = []
      if (text.Text1 !== null && text.Text1 !== '' && text.Text1 !== undefined) {
        valany.push(parseFloat(text.Text1))
      }
      if (text.Text2 !== null && text.Text2 !== '' && text.Text2 !== undefined) {
        valany.push(parseFloat(text.Text2))
      }
      if (text.Text3 !== null && text.Text3 !== '' && text.Text3 !== undefined) {
        valany.push(parseFloat(text.Text3))
      }
      if (text.Text4 !== null && text.Text4 !== '' && text.Text4 !== undefined) {
        valany.push(parseFloat(text.Text4))
      }
      if (text.Text5 !== null && text.Text5 !== '' && text.Text5 !== undefined) {
        valany.push(parseFloat(text.Text5))
      }
      if (text.Text6 !== null && text.Text6 !== '' && text.Text6 !== undefined) {
        valany.push(parseFloat(text.Text6))
      }
      // this.form.Parcss[index].Text7 = Math.max.apply(null, valany)
      // this.form.Parcss[index].Text8 = Math.min.apply(null, valany)
      // 最大值
      // 最小值
      this.form.Parcss[index].Text7 = Math.max.apply(Math, valany)
      this.form.Parcss[index].Text8 = Math.min.apply(Math, valany)
      var dataine = this.$PublicJs.Calculation(text.Text, this.form.Parcss[index].Text7, this.form.Parcss[index].Text8)
      this.form.Parcss[index].Text9 = dataine
    },
    text9pass (index, state) {
      this.form.Parcss[index].Text9 = state
    },
    State (row) {
      var state
      switch (row) {
        case 0:
          state = this.$t('login.pending')
          break
        case 1:
          state = this.$t('system.bohui')
          break
        case 2:
          state = this.$t('system.wanjie')
          break
        case 3:
          state = this.$t('system.zancun')
          break
        case 4:
          state = this.$t('system.tuihuo')
          break
        case 5:
          state = this.$t('system.tecai')
          break
        default:
          state = this.$t('All.异常')
          break
      }
      return state
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
  min-width: 903px;
}
  .el-col {
    border: 1px solid;
    margin-right: -1px;
     margin-bottom: -1px;
    line-height: 4.5vw;
    font-size: 2vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .grid-content {
    min-height: 36px;
  }
  .bg-purple-light{

  }
  .row-bg {
    background-color: #f9fafc;
  }
</style>
