import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    Login: [],
    UserData: [],
    Productname: '',
    FQCNumerb: '',
    Uploadis: false,
    TabComponent: 'Home',
    PrinterData: [],
    Qcrode: [],
    Qcrode2: '',
    Testdata: [],
    Url: '',
    ConductIs: '',
    Userediet: '',
    NewIQCAdminNumber: '',
    Gongdan: '',
    Productnumbers: '',
    Islogin: false,
    OQCdata: {},
    OQCExamine: '',
    IPQCadminnumber: '',
    FQCAdminNumber: ''
  },
  mutations: {
    loginlist (state, data) {
      state.Login = data
    },
    UserData (state, data) {
      state.UserData = data
    },
    Testdata (state, data) {
      state.Testdata = data
    },
    Productname (state, data) {
      state.Productname = data
    },
    FQCNumerb (state, data) {
      state.FQCNumerb = data
    },
    Uploadis (state, data) {
      state.Uploadis = data
    },
    TabComponentFun (state, data) {
      state.TabComponent = data
    },
    PrinterData (state, data) {
      state.PrinterData = data
    },
    Qcrode (state, data) {
      state.Qcrode = data
    },
    Qcrode2 (state, data) {
      state.Qcrode2 = data
    },
    Userediet (state, data) {
      state.Userediet = data
    },
    ConductIs (state, data) {
      state.ConductIs = data
    },
    NewIQCAdminNumberFun (state, data) {
      state.NewIQCAdminNumber = data
    },
    Gongdanfun (state, data) {
      state.Gongdan = data
    },
    Productnumbersfun (state, data) {
      state.Productnumbers = data
    },
    ProductnIsloginfun (state, data) {
      state.Islogin = data
    },
    OQCdatafun (state, data) {
      state.OQCdata = data
    },
    OQCExaminefun (state, data) {
      state.OQCExamine = data
    },
    IPQCadminnumberfun (state, data) {
      state.IPQCadminnumber = data
    },
    FQCAdminNumberfun (state, data) {
      state.FQCAdminNumber = data
    }
  },
  actions: {
  },
  modules: {
  }
})
