<template>
 <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd'">
    <div style="position: fixed;top: 55px;right: 10px; z-index: 1000;">
      <el-button  size="small" style="text-align: right;"  type="primary" @click="isqpisboot = !isqpisboot">{{isqpisboot ? $t('All.取消全屏'): $t('All.全屏')}}</el-button>
      <el-button size="small" type="primary"  v-if="isdata"  @click="IQC_Admin()">{{this.$t('system.tijiao')}}</el-button>
      <el-button size="small" type="primary"  v-if="!isdata" @click="UserDataUp()">{{this.$t('system.gxup')}}</el-button>
      <el-button size="small" type="primary"   @click="dialogVisible = true">{{this.$t('system.fjian')}}</el-button>
      <el-button size="small" type="danger" v-if="!isdata" @click="Delete()">{{this.$t('system.del')}}</el-button>
    </div>
    <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
    <h3 style="text-align: center;">{{this.$t('system.jinliaojy')}}</h3>
    <h4 style="text-align: center;">{{this.$t('system.lururen')}}：{{form.Enteredby}}</h4>
    <div style=" padding: 10px; text-align: center; line-height: 2vw;">
      <table border="1"  align="center" cellpadding="10" style="width: 100%;"  cellspacing="0">
          <tr>
              <td >{{this.$t('IQC.供应商')}}</td>
              <td >{{this.$t('IQC.交货数')}}</td>
              <td >{{this.$t('IQC.承认书')}}</td>
              <td colspan="3">{{this.$t('IQC.品名')}}</td>
              <td colspan="2">{{$t('All.进料日期')}}</td>
              <td colspan="2">{{this.$t('IQC.订单号')}}</td>
          </tr>
          <tr>
            <td class="tdd"><el-input v-model="form.Supplier"></el-input></td>
            <td class="tdd"><el-input v-model="form.Delivery"></el-input></td>
            <td class="tdd"><el-input v-model="form.Acknowledgment"></el-input></td>
            <td  colspan="3" class="tdd"><el-input v-model="form.Product_name"></el-input></td>
            <td  colspan="2" class="tdd"><el-date-picker
                                          v-model="form.Inspection_No"
                                          type="date"
                                          :placeholder="$t('All.选择日期')" value-format="yyyy/MM/dd">
                                        </el-date-picker></td>
            <td  colspan="2" class="tdd"><el-input v-model="form.Order_Number"></el-input></td>
          </tr>
          <tr>
            <td >{{this.$t('IQC.入库数')}}</td>
            <td >物料单位</td>
            <td >{{this.$t('IQC.抽样数')}}</td>
            <td >{{this.$t('IQC.工程图面')}}</td>
            <td  colspan="2">{{this.$t('IQC.料号')}}</td>
            <td  colspan="2">{{this.$t('IQC.抽样类型')}}</td>
            <td  colspan="2">{{this.$t('IQC.检验基准表')}}</td>
          </tr>
          <tr>
            <td class="tdd"><el-input v-model="form.Warehousing"></el-input></td>
            <td class="tdd"><el-input v-model="form.Company"></el-input></td>
            <td class="tdd"><el-input v-model="form.Sampling"></el-input></td>
            <td class="tdd"><el-input v-model="form.Drawing_Surface"></el-input></td>
            <td  colspan="2" class="tdd"><el-input v-model="form.Item_No"></el-input></td>
            <td  colspan="2" class="tdd"><el-input v-model="form.Sampling_type"></el-input></td>
            <td  colspan="2" class="tdd"><el-input v-model="form.Inspection_Table"></el-input></td>
          </tr>
          <tr>
            <td colspan="10"><el-input v-model="form.Standard1"><template slot="prepend">{{this.$t('IQC.执行标准')}}</template></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.检验项目')}}</td>
            <td colspan="2">{{this.$t('IQC.检验标准')}}</td>
            <td colspan="4">{{this.$t('IQC.检验内容')}}</td>
            <td>{{this.$t('IQC.判定')}}</td>
            <td colspan="2">{{this.$t('IQC.备注')}}</td>
          </tr>
          <tr>
            <td class="tdd" rowspan="4">{{this.$t('IQC.外观')}}</td>
            <td rowspan="4" colspan="2"><el-input v-model="form.Tbz1"></el-input></td>
            <td class="tdd" colspan="4"><el-input v-model="form.Colour"><template slot="prepend">{{this.$t('IQC.颜色')}}</template></el-input></td>
            <td><el-input disabled v-model="form.Determine2"></el-input></td>
            <td colspan="2"><el-input v-model="form.Remarks2"></el-input></td>
          </tr>
          <tr>
            <td class="tdd" colspan="4"><el-input v-model="form.Plug"><template slot="prepend">{{this.$t('IQC.插头')}}</template></el-input></td>
            <td><el-input disabled v-model="form.Determine11"></el-input></td>
            <td colspan="2"><el-input v-model="form.Remarks11"></el-input></td>
          </tr>
          <tr>
            <td class="tdd" colspan="4"><el-input v-model="form.Cable_MOLD"><template slot="prepend">CABLE_MOLD</template></el-input></td>
            <td><el-input disabled v-model="form.Determine12"></el-input></td>
            <td colspan="2"><el-input v-model="form.Remarks12"></el-input></td>
          </tr>
          <tr>
            <td class="tdd" colspan="4"><el-input v-model="form.Shape"><template slot="prepend">{{$t('All.形状')}}</template></el-input></td>
            <td><el-input disabled v-model="form.Determine3"></el-input></td>
            <td colspan="2"><el-input v-model="form.Remarks3"></el-input></td>
          </tr>
          <tr >
            <td class="tdd" rowspan="2">{{$t('All.可靠度实验项')}}</td>
            <td rowspan="2" colspan="2">{{$t('All.进料检验标准')}}</td>
            <td colspan="4"><el-input type="textarea" :rows="5" v-model="form.Shiyx1"></el-input></td>
            <td><el-input disabled v-model="form.Determine13"></el-input></td>
            <td colspan="2"><el-input type="textarea" :rows="5" v-model="form.Remarks13"></el-input></td>
          </tr>
          <tr>
            <td colspan="4"><el-input type="textarea" :rows="5" v-model="form.Shiyx2"></el-input></td>
            <td><el-input disabled v-model="form.Determine14"></el-input></td>
            <td colspan="2"><el-input type="textarea" :rows="5" v-model="form.Remarks14"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.电器特性')}}</td>
            <td colspan="2"><el-input v-model="form.Tbz2"></el-input></td>
            <td colspan="4"><el-input v-model="form.Text1"></el-input></td>
            <td><el-input disabled v-model="form.Determine4"></el-input></td>
            <td colspan="2"><el-input v-model="form.Remarks4"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.试用')}}</td>
            <td colspan="2"><el-input v-model="form.Tbz3"></el-input></td>
            <td colspan="4"><el-input v-model="form.Text2"></el-input></td>
            <td><el-input disabled v-model="form.Determine5"></el-input></td>
            <td colspan="2"><el-input v-model="form.Remarks5"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.包装')}}</td>
            <td colspan="2"><el-input v-model="form.Tbz4"></el-input></td>
            <td colspan="4"><el-input v-model="form.Text3"></el-input></td>
            <td><el-input disabled v-model="form.Determine6"></el-input></td>
            <td colspan="2"><el-input v-model="form.Remarks6"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.第三方检测报告有效性')}}</td>
            <td colspan="2"><el-input v-model="form.Tbz5"></el-input></td>
            <td colspan="4"><el-input v-model="form.Text4"></el-input></td>
            <td><el-input disabled v-model="form.Determine7"></el-input></td>
            <td colspan="2"><el-input v-model="form.Remarks7"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.厂商出货报告批号')}}</td>
            <td colspan="2"><el-input v-model="form.Tbz6"></el-input></td>
            <td colspan="4"><el-input v-model="form.Text5"></el-input></td>
            <td><el-input disabled v-model="form.Determine8"></el-input></td>
            <td colspan="2"><el-input v-model="form.Remarks8"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.来料实物批号')}}</td>
            <td colspan="2"><el-input v-model="form.Tbz7"></el-input></td>
            <td colspan="4"><el-input v-model="form.Text6"></el-input></td>
            <td><el-input disabled v-model="form.Determine9"></el-input></td>
            <td colspan="2"><el-input v-model="form.Remarks9"></el-input></td>
          </tr>
          <tr v-if="false">
            <td>{{$t('All.出货报告其它讯息确认')}}</td>
            <td colspan="2"><el-input v-model="form.Tbz8"></el-input></td>
            <td colspan="4"><el-input v-model="form.Text7"></el-input></td>
            <td><el-input disabled v-model="form.Determine10"></el-input></td>
            <td colspan="2"><el-input v-model="form.Remarks10"></el-input></td>
          </tr>
          <tr>
              <td class="tdd" rowspan="2">{{this.$t('IQC.规格要求')}}</td>
              <td class="tdd" colspan="2">{{this.$t('IQC.量测仪器编号')}}</td>
              <td colspan="7" class="tdd"><el-input v-model="form.Yqi1"></el-input></td>
          </tr>
          <tr>
              <td colspan="6" class="tdd" >{{this.$t('IQC.尺寸检验记录')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最大值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最小值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.判定')}}</td>
          </tr>
          <tr v-for="(text, index) in form.IQCParcss" :key="index">
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text" ></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text1"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text2"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text3"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text4"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text5"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text6"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text7"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text8"></el-input></td>
              <td class="tdd" style="min-width: 150px;"><el-button type="primary"  @click="addDomain(text,index)" size="mini">{{$t('All.新增')}}</el-button><el-button type="primary"  @click="removeDomain(text)" size="mini">{{$t('All.删除')}}</el-button></td>
          </tr>
      </table>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.核准')}}: {{form.Hz}}</span></el-col>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.检验员')}}: {{form.Jyy}}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><h6></h6></el-col>
        <el-col :span="12" style="border: 0px solid transparent;padding-right: 20px;text-align: right;"><span style="font-size: 1.5vw;">NYS2401-02 B</span></el-col>
      </el-row>
    </div>
    <el-dialog
      :title="$t('All.上传附件')"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose" append-to-body>
      <iqcUpload :isdata = "isdata" :fqcadmin = "form.Product_name"></iqcUpload>
  </el-dialog>
  </div>
</template>
<script>
import iqcUpload from './Upload/IQCAdminUpload'
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      form: {
        Timsdate: '', // 检验员填写日期
        Date: new Date(), // 写入时间
        Enteredby: '', // 录入人
        Hz: '', // 核准人
        Jyy: '', // 检验员
        Ycms: '', // 异常描述
        Beizhu: '', // 1.量测的尺寸规格按抽样数10%的比例记录；当记录的尺寸规格个数大于10组数据时，则记录前10pcs样本的量测结果值。
        Delivery: '', // 交货数
        Warehousing: '', // 入库数
        Sampling: '', // 抽样数
        Product_name: '', // 品名规格
        Standard1: '', // AQL标准1
        Standard2: '', //  参考文件
        Supplier: '', //  供应商
        Acknowledgment: '', // 承认书
        Inspection_No: '', //  检验单号
        Order_Number: '', //  订单号
        Drawing_Surface: '', //  工程图面
        Item_No: '', //  料号
        Sampling_type: '', //  抽样类型
        Inspection_Table: '', //  检验基准表
        Colour: '', // 颜色
        Shape: this.$t('All.形状与样品一致'), // 形状
        Plug: '', // 插头
        Cable_MOLD: '', // Cable_MOLD
        Text1: '', // 检验内容
        Text2: '', // 检验内容
        Text3: '', // 检验内容
        Text4: '', // 检验内容
        Text5: '', // 检验内容
        Text6: '', // 检验内容
        Text7: '', // 检验内容
        Determine1: '', // 判定
        Determine2: '', // 判定
        Determine3: '', // 判定
        Determine4: '', // 判定
        Determine5: '', // 判定
        Determine6: '', // 判定
        Determine7: '', // 判定
        Determine8: '', // 判定
        Determine9: '', // 判定
        Determine10: '', // 判定
        Determine11: '', // 判定
        Determine12: '', // 判定
        Determine13: '', // 判定
        Determine14: '', // 判定
        Remarks1: '', // 备注
        Remarks2: '', // 备注
        Remarks3: '', // 备注
        Remarks4: '', // 备注
        Remarks5: '', // 备注
        Remarks6: '', // 备注
        Remarks7: '', // 备注
        Remarks8: '', // 备注
        Remarks9: '', // 备注
        Remarks10: '', // 备注
        Remarks11: '', // 备注
        Remarks12: '', // 备注
        Remarks13: '', // 备注
        Remarks14: '', // 备注
        Tbz1: this.$t('All.依样品丶承认书'),
        Tbz2: this.$t('All.承认书丶工程图面'),
        Tbz3: this.$t('All.与配套使用'),
        Tbz4: this.$t('All.依承认书丶标示卡'),
        Tbz5: '',
        Tbz6: '',
        Tbz7: '',
        Tbz8: '',
        Factory: '', // 厂区
        Yqi1: '', // 测试仪器编号1
        Lot_NO: '', // LOT_NO
        IQCParcss: [],
        Shiyx1: '',
        Shiyx2: '',
        Shiyx3: '',
        Shiyx4: '',
        Company: '' // 物料单位
      },
      isdata: false,
      dialogVisible: false,
      isqpisboot: false
    }
  },
  components: {
    iqcUpload
  },
  created () {
    if (this.$store.state.UserData === null) {
      this.isdata = true
      this.form.Enteredby = this.$store.state.Login.Username
      this.form.Product_name = this.$store.state.Productname
      this.form.Factory = this.$store.state.Login.Factory
      const contr = {
        Index: 0,
        Product_name: this.form.Product_name, // 品名规格
        Text: '',
        Text1: '',
        Text2: '',
        Text3: '',
        Text4: '',
        Text5: '',
        Text6: '',
        Text7: '',
        Text8: '',
        Text9: ''
      }
      this.form.IQCParcss.splice(0, 0, contr)
    } else {
      this.isdata = false
      this.form = this.$store.state.UserData
    }
  },
  mounted () {
  },
  methods: {
    addDomain (item, index) {
      const contr = {
        Index: index + 1,
        Product_name: item.Product_name, // 品名规格
        Text: item.Text,
        Text1: '',
        Text2: '',
        Text3: '',
        Text4: '',
        Text5: '',
        Text6: '',
        Text7: '',
        Text8: '',
        Text9: ''
      }
      this.form.IQCParcss.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Parcss.length; i++) {
        this.form.Parcss[i].Index = i
      }
    },
    removeDomain (item) {
      var index = this.form.IQCParcss.indexOf(item)
      if (index !== -1) {
        this.form.IQCParcss.splice(index, 1)
      }
      for (let i = 0; i < this.form.Parcss.length; i++) {
        this.form.Parcss[i].Index = i
      }
    },
    ...mapMutations(['UserData', 'TabComponentFun']),
    getCurrentTime () {
      // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const gettime = yy + this.$t('All.年') + mm + this.$t('All.月') + dd + this.$t('All.日')
      return gettime
    },
    async IQC_Admin () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/IQC/IQCUserDataAdd', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.TabComponentFun('IQCAdminData')
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async UserDataUp () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/IQC/IQCUserDataUp', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.提交成功'))
        this.TabComponentFun('IQCAdminData')
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async Delete () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/IQC/IQCAdminDataDel', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.删除成功'))
        this.TabComponentFun('IQCAdminData')
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
  min-width: 903px;
}
  .el-col {
    border: 1px solid;
    margin-right: -1px;
     margin-bottom: -1px;
    line-height: 4.5vw;
    font-size: 2vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .grid-content {
    min-height: 36px;
  }
  .bg-purple-light{

  }
  .row-bg {
    background-color: #f9fafc;
  }
</style>
