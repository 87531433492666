<template>
  <div class="wbobyy">
    <div id="wrapper">
        <div class="input-data">
            <input type="text" v-model="Noitem" @keyup.enter="UserDataSelect(Noitem)" required>
            <div class="underline">
              <i class="el-icon-circle-close" v-show="Noitem.length > 0" style="position: absolute;top: -30px;right: 60px;color:#9fa1a7;" @click="Noitem = ''"></i>
              <el-button type="primary" style="position: absolute;top: -35px;right: 1px;" size="mini" icon="el-icon-search" @click="UserDataSelect(Noitem)"></el-button>
            </div>
            <label>{{$t('All.输入OQC模版品名')}}</label>
        </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Noitem: ''
    }
  },
  created () {
    this.User = this.$store.state.Login
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData']),
    ...mapMutations(['Productname']),
    async UserDataSelect (row) {
      const postdata = {
        Name: row,
        Factory: this.$store.state.Login.Factory
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Zh/AdminDataSelect', postdata)
        if (res.status !== 200) {
          loadingInstance.close()
          this.UserData(null)
          this.$message.success(this.$t('All.数据不存在') + '，' + this.$t('All.即将进入新增页面'))
          this.Productname(this.Noitem)
          this.$store.state.TabComponent = 'OQCAdmin'
          return
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.数据已存在') + '，' + this.$t('All.即将进入编辑页面'))
        this.Productname(this.Noitem)
        this.UserData(res.response)
        this.$store.state.TabComponent = 'OQCAdmin'
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    exit () {
      this.$router.push('/Login')
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobyy{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 0px;
  width: 300px;
}
</style>
