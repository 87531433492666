<template>
  <div>
    <div style="padding: 20px;">
      <el-button type="primary" icon="el-icon-arrow-left" size="small" @click="$router.push('/Produchuizong/' + formData.Item)">返回</el-button>
      <el-button type="primary" v-if="Islogin" icon="el-icon-edit" size="small" @click="Isbool = !Isbool">编辑</el-button>
      <el-button size="mini" v-if="Islogin"  type="danger" @click="Delete(formData)">Delete</el-button>
    </div>
    <el-form ref="elForm" :model="formData" style="padding: 20px;" :rules="rules" size="medium" label-width="100px"
      label-position="top">
      <el-form-item label="料号">
        <el-input v-if="Isbool" v-model="formData.Item" onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" @blur="formData.Item=formData.Item.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" oninput="if (value.trim() === '') { value = null; }" placeholder="请输入料号" clearable :style="{ width: '100%' }">
        </el-input>
        <span style="color: #409eff;" v-if="!Isbool">{{ formData.Item }}</span>
      </el-form-item>
      <el-form-item v-if="Isbool" label="品名" prop="Productname">
        <el-input v-model="formData.Productname" placeholder="请输入品名" clearable :style="{ width: '100%' }">
        </el-input>
      </el-form-item>
      <el-form-item v-if="!Isbool" label="品名">
        <span style="color: #409eff;">{{ formData.Productname }}</span>
      </el-form-item>
      <el-form-item label="客户">
        <el-input v-if="Isbool" v-model="formData.Custom" placeholder="请输入客户" clearable :style="{ width: '100%' }">
        </el-input>
        <span style="color: #409eff;" v-if="!Isbool">{{ formData.Custom }}</span>
      </el-form-item>
      <el-form-item v-if="Isbool" label="简述" prop="Sketch">
        <el-input v-model="formData.Sketch" maxlength="20" show-word-limit placeholder="请输入简述" clearable :style="{ width: '100%' }">
        </el-input>
      </el-form-item>
      <el-form-item v-if="!Isbool" label="简述">
        <span style="color: #409eff;">{{ formData.Sketch }}</span>
      </el-form-item>
      <el-form-item label="详细描述" prop="Details">
        <el-input v-model="formData.Details" maxlength="200" show-word-limit :disabled="!Isbool" type="textarea" placeholder="请输入详细描述"
          :autosize="{ minRows: 6, maxRows: 6 }" :style="{ width: '100%' }"></el-input>
      </el-form-item>
      <el-form-item v-if="Isbool" size="large" style="text-align: center;">
        <el-button type="primary" style="width: 40%;" @click="submit">提交</el-button>
        <el-button style="width: 40%;" @click="Isbool = false">取消</el-button>
      </el-form-item>
      <el-form-item label="创建人">
        <span style="color: #409eff;">{{ formData.Username }}</span>
      </el-form-item>
      <el-form-item label="最后一次更新人" v-if="upisbool">
        <span style="color: #409eff;">{{ Productrecord[Productrecord.length - 1]. Username}}</span>
      </el-form-item>
    </el-form>
    <div v-if="upisbool">
      <el-table :data="Productfile" style="width: 100%">
        <el-table-column type="index" width="50">
        </el-table-column>
        <el-table-column prop="Date" label="上传日期" width="180">
        </el-table-column>
        <el-table-column label="文件名称">
           <template slot-scope="scope">
            <a :href="`${$Upload}/${scope.row.Route}`" target="_blank">{{scope.row.Fname}}</a>
          </template>
        </el-table-column>
        <el-table-column align="center" width="180" v-if="Islogin">
          <template slot="header">
            <el-upload
            ref="upload"
            class="upload-demo"
            :limit="1"
            :action="`${$Upload}/api/Product/UploadAdd`"
            :file-list="fileList"
            :on-success="onsuccess"
            :data="formData"
            multiple>
            <i class="el-icon-upload" style="font-size: 30px; vertical-align: middle;color: #409eff;"></i>
          </el-upload>
          </template>
          <template slot-scope="scope">
            <el-button size="mini" type="danger" @click="handleDelete(scope.row)">Delete</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block" v-show="Productrecord.length > 0">
            <div class="radio" style="margin-bottom: 20px;margin-top: 20px;">
                排序：
                <el-radio-group v-model="reverse">
                    <el-radio :label="true">倒序</el-radio>
                    <el-radio :label="false">正序</el-radio>
                </el-radio-group>
            </div>
            <el-timeline :reverse="reverse">
                <el-timeline-item  v-for="(activity, index) in Productrecord" :key="index" :timestamp="activity.Date" placement="top">
                    <el-card>
                        <h4>{{ $PublicJs.RecordType(activity.Type) }}</h4>
                        <p>{{ activity.Notes }}</p>
                        <p>{{ activity.Username }} 提交于 {{ activity.Date }}</p>
                    </el-card>
                </el-timeline-item>
            </el-timeline>
        </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  components: {},
  data () {
    return {
      reverse: true,
      formData: {},
      Productfile: [],
      Productrecord: [],
      Isbool: false,
      rules: {
        Item: [{
          required: true,
          message: '请输入料号',
          trigger: 'blur'
        }],
        Productname: [{
          required: true,
          message: '请输入品名',
          trigger: 'blur'
        }],
        Custom: [{
          required: true,
          message: '请输入客户',
          trigger: 'blur'
        }],
        Sketch: [{
          required: true,
          message: '请输入简述',
          trigger: 'blur'
        }],
        Details: [{
          required: true,
          message: '请输入详细描述',
          trigger: 'blur'
        }]
      },
      fileList: [],
      upisbool: false
    }
  },
  computed: {
    Islogin () {
      return this.$store.state.Islogin
    }
  },
  watch: {},
  created () {
    if (this.$route.params.numbers === undefined) {
      this.$router.push('/Product')
    } else {
      this.Getform()
    }
  },
  mounted () { },
  methods: {
    ...mapMutations(['TabComponentFun']),
    submit () {
      this.$refs.elForm.validate(async valid => {
        if (!valid) return this.$message.error('请完善表单后在进行提交！')
        const postdata = {
          UserData: this.$store.state.Login,
          Product: this.formData
        }
        const { data: res } = await this.$http.post(
          '/api/Product/Upform',
          postdata
        )
        if (res.status !== 200) return this.$message.error(res.msg)
        this.$message.success('提交成功！')
        this.Isbool = false
        this.Getform()
      })
    },
    async Getform () {
      const { data: res } = await this.$http.get(
        '/api/Product/Getform?Numbers=' + this.$route.params.numbers
      )
      if (res.status !== 200) return this.$message.error(res.msg)
      this.formData = res.response.Product
      this.Productfile = res.response.Productfile
      this.Productrecord = res.response.Productrecord
      this.upisbool = true
    },
    onsuccess (response) {
      if (response.status !== 200) {
        // 先删除
        this.$refs.upload.clearFiles()
        return this.$message.error(response.msg)
      }
      this.$message.success(response.msg)
      this.$refs.upload.clearFiles()
      this.Getform()
    },
    async handleDelete (row) {
      const postdata = {
        UserData: this.$store.state.Login,
        Productfile: row
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post(
        '/api/Product/UploadDel',
        postdata
      )
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.error(res.msg)
      }
      this.$message.success('删除成功！')
      this.Getform()
      loadingInstance.close()
    },
    async Delete (row) {
      const postdata = {
        UserData: this.$store.state.Login,
        Product: row
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post(
        '/api/Product/DelFileform',
        postdata
      )
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.error(res.msg)
      }
      loadingInstance.close()
      this.$message.success('删除成功！')
      this.$router.push('/Product')
    }
  }

}

</script>
<style lang="less" scoped>
.el-upload__tip {
  line-height: 1.2;
}

spantext {
  color: #409eff;
}
</style>
