<template>
  <div id="app">
      <!--路由暂位符-->
      <div style="height: 100%">
          <router-view v-if="isRouterAlicve"></router-view>
      </div>
    </div>
  </template>

<script>
export default {
  name: 'app',
  provide () {
    return {
      reload: this.reload
    }
  },
  data () {
    return {
      isRouterAlicve: true
    }
  },
  created () {
    // 在页面加载时读取sessionStorage里的状态信息
    if (sessionStorage.getItem('store')) {
      this.$store.replaceState(Object.assign({}, this.$store.state, JSON.parse(sessionStorage.getItem('store'))))
    }

    // 在页面刷新时将vuex里的信息保存到sessionStorage里
    window.addEventListener('beforeunload', () => {
      sessionStorage.setItem('store', JSON.stringify(this.$store.state))
    })
  },
  methods: {
    reload () {
      this.isRouterAlicve = false
      this.$nextTick(function () {
        this.isRouterAlicve = true
      })
    }
  }
}
</script>
  <style>
  </style>
