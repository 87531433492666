<template>
    <div>
        <div>
            <el-row  style="padding-top: 10px;">
                <el-col :span="12" style="text-align: center;">
                    <div id="quanbadc" style="width: 600px; margin: auto;height: 25vw;"></div>
                    <el-button style="margin-left: 10px;font-size: inherit;" size="small" type="text" @click="Tadcdw">{{$t('All.下载')}}(Exlce)</el-button>
                </el-col>
                <el-col :span="12" style="text-align: center;">
                    <div id="quanbpowr" style="width: 600px; margin: auto;height: 25vw;"></div>
                    <el-button style="margin-left: 10px;font-size: inherit;" size="small" type="text" @click="Tpowdw">{{$t('All.下载')}}(Exlce)</el-button>
                </el-col>
            </el-row>
        </div>
        <div  class="jatbody">
            <el-date-picker
                v-model="Datedata2"
                size="small"
                type="daterange"
                range-separator="~"
                :start-placeholder="this.$t('All.开始日期')"
                :end-placeholder="this.$t('All.结束日期')"
                value-format="yyyy-MM-dd">
            </el-date-picker>
            <el-button type="primary" size="small" style="margin-left: 10px;" icon="el-icon-search" @click="Getdata(form2)">图表搜索</el-button>
        </div>
        <div  class="jatbody">
            <el-date-picker
            v-if="false"
                v-model="Datedata"
                size="small"
                type="daterange"
                range-separator="~"
                :start-placeholder="this.$t('All.开始日期')"
                :end-placeholder="this.$t('All.结束日期')">
            </el-date-picker>
            <el-input v-model="form.Test" placeholder="请输入Mac或二维码内容" size="small" style="width: 220px; padding-left: 10px;"></el-input>
            <el-select v-model="form.Type" placeholder="请选择类型" style="width: 100px; padding-left: 10px;" size="small">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"></el-option>
            </el-select>
            <el-button type="primary" size="small" style="margin-left: 10px;" icon="el-icon-search" @click="Jatselset(form)">搜索</el-button>
        </div>
        <div>
            <h3 style="text-align: center;">ADC测试数据
                <el-button style="margin-left: 10px;font-size: inherit;" size="small" v-if="tableDataadc.length > 0" type="text" @click="Xiazadc">{{$t('All.下载')}}(Exlce)</el-button>
            </h3>
            <el-table :data="tableDataadc" style="width: 100%;padding: 10px;text-align: center;">
              <el-table-column type="index"></el-table-column>
              <el-table-column label="类型">
                  <template slot-scope="scope">
                       <span :style="scope.row.Type === 1? 'color: #E6A23C;' : 'color: #67C23A;'">{{ scope.row.Type === 1? '复测' : '原测' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Isbool" label="最终结果"  >
                    <template slot-scope="scope">
                       <span :style="scope.row.Isbool === 'PASS'? 'color: #67C23A;' : 'color: red;'">{{ scope.row.Isbool }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Date" label="日期" width="180"></el-table-column>
                <el-table-column prop="Mac" label="MAC"  ></el-table-column>
                <el-table-column prop="Csz" label="初始值"  ></el-table-column>
                <el-table-column prop="Zgz" label="最高值"  ></el-table-column>
                <el-table-column prop="Gwz" label="归位值1"  ></el-table-column>
                <el-table-column prop="Zdz" label="最低值"  ></el-table-column>
                <el-table-column prop="Gwz2" label="归位值2"  ></el-table-column>
                <el-table-column prop="Isbool1" label="初始值结果"  ></el-table-column>
                <el-table-column prop="Isbool2" label="最高值结果"  ></el-table-column>
                <el-table-column prop="Isbool3" label="归位值1结果"  ></el-table-column>
                <el-table-column prop="Isbool4" label="最低值结果"  ></el-table-column>
                <el-table-column prop="Isbool5" label="归位值2结果"  ></el-table-column>
                <el-table-column prop="Qrcode" label="二维码"  ></el-table-column>
            </el-table>
            <h3 style="text-align: center;">电流电压测试数据
                <el-button style="margin-left: 10px;font-size: inherit;" size="small" v-if="tableDatapow.length > 0" type="text" @click="Xiazpow">{{$t('All.下载')}}(Exlce)</el-button>
            </h3>
            <el-table :data="tableDatapow" style="width: 100%;padding: 10px;text-align: center;">
              <el-table-column type="index"></el-table-column>
                <el-table-column label="类型">
                  <template slot-scope="scope">
                       <span :style="scope.row.Type === 1? 'color: #E6A23C;' : 'color: #67C23A;'">{{ scope.row.Type === 1? '复测' : '原测' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Isbool" label="最终结果"  >
                    <template slot-scope="scope">
                       <span :style="scope.row.Isbool === 'PASS'? 'color: #67C23A;' : 'color: red;'">{{ scope.row.Isbool }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Date" label="日期"></el-table-column>
                <el-table-column prop="Mac" label="MAC"  ></el-table-column>
                <el-table-column prop="Name1" label="阶段名称1"  ></el-table-column>
                <el-table-column prop="Dw1" label="阶段1单位"  ></el-table-column>
                <el-table-column prop="Zcdl1" label="正常工作电流1"  ></el-table-column>
                <el-table-column prop="Zcdl2" label="正常工作电流2"  ></el-table-column>
                <el-table-column prop="Zcdl3" label="正常工作电流3"  ></el-table-column>
                <el-table-column prop="Zcdl" label="正常工作电流平均值"  ></el-table-column>
                <el-table-column prop="Name2" label="阶段名称2"  ></el-table-column>
                <el-table-column prop="Dw2" label="阶段2单位"  ></el-table-column>
                <el-table-column prop="Djdl1" label="待机电流1"  ></el-table-column>
                <el-table-column prop="Djdl2" label="待机电流2"  ></el-table-column>
                <el-table-column prop="Djdl3" label="待机电流3"  ></el-table-column>
                <el-table-column prop="Djdl" label="待机电流平均值"  ></el-table-column>
                <el-table-column prop="Name2" label="阶段名称3"  ></el-table-column>
                <el-table-column prop="Dw3" label="阶段3单位"  ></el-table-column>
                <el-table-column prop="Smdl1" label="休眠电流1"  ></el-table-column>
                <el-table-column prop="Smdl2" label="休眠电流2"  ></el-table-column>
                <el-table-column prop="Smdl3" label="休眠电流3"  ></el-table-column>
                <el-table-column prop="Smdl" label="休眠电流平均值"  ></el-table-column>
                <el-table-column prop="X" label="X"  ></el-table-column>
                <el-table-column prop="Y" label="Y"  ></el-table-column>
                <el-table-column prop="Z" label="Z"  ></el-table-column>
                <el-table-column prop="XYZIsbool" label="XYZ结果"  ></el-table-column>
            </el-table>
            <h3 style="text-align: center;">24小时电压测试数据
                <el-button style="margin-left: 10px;font-size: inherit;" size="small" v-if="tableDataadc.length > 0" type="text" @click="Xiazadc">{{$t('All.下载')}}(Exlce)</el-button>
            </h3>
            <el-table :data="Pedaldgwtest" style="width: 100%;padding: 10px;text-align: center;">
              <el-table-column type="index"></el-table-column>
              <el-table-column label="类型">
                  <template slot-scope="scope">
                       <span :style="scope.row.Type === 1? 'color: #E6A23C;' : 'color: #67C23A;'">{{ scope.row.Type === 1? '复测' : '原测' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Isbool" label="最终结果"  >
                    <template slot-scope="scope">
                       <span :style="scope.row.Isbool ? 'color: #67C23A;' : 'color: red;'">{{ scope.row.Isbool ? 'PASS':'FAIL' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Date" label="日期" width="180"></el-table-column>
                <el-table-column prop="Dw" label="单位"  ></el-table-column>
                <el-table-column prop="Coutn" label="测试工站">
                  <template slot-scope="scope">
                       <span>{{ scope.row.Coutn === 1 ? '初测':'复测' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Qrcode" label="二维码"  ></el-table-column>
            </el-table>
            <h3 style="text-align: center;">快速睡眠数据
                <el-button style="margin-left: 10px;font-size: inherit;" size="small" v-if="tableDataadc.length > 0" type="text" @click="Xiazadc">{{$t('All.下载')}}(Exlce)</el-button>
            </h3>
            <el-table :data="Sleepclass" style="width: 100%;padding: 10px;text-align: center;">
              <el-table-column type="index"></el-table-column>
              <el-table-column label="类型">
                  <template slot-scope="scope">
                       <span :style="scope.row.Type === 1? 'color: #E6A23C;' : 'color: #67C23A;'">{{ scope.row.Type === 1? '复测' : '原测' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Isbool" label="测试结果">
                    <template slot-scope="scope">
                       <span :style="scope.row.Isbool ? 'color: #67C23A;' : 'color: red;'">{{ scope.row.Isbool ? 'PASS':'FAIL' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Date" label="日期" width="180"></el-table-column>
                <el-table-column prop="Mac" label="MAC"  ></el-table-column>
                <el-table-column prop="Qrcode" label="二维码"  ></el-table-column>
                <el-table-column prop="Enddate" label="下次可测试时间"  ></el-table-column>
            </el-table>
            <h3 style="text-align: center;">最终视觉测试
                <el-button style="margin-left: 10px;font-size: inherit;" size="small" v-if="tableDataadc.length > 0" type="text" @click="Xiazadc">{{$t('All.下载')}}(Exlce)</el-button>
            </h3>
            <el-table :data="Zuizhongclass" style="width: 100%;padding: 10px;text-align: center;">
              <el-table-column type="index"></el-table-column>
              <el-table-column label="类型">
                  <template slot-scope="scope">
                       <span :style="scope.row.Type === 1? 'color: #E6A23C;' : 'color: #67C23A;'">{{ scope.row.Type === 1? '复测' : '原测' }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Isbool" label="最终结果"  >
                    <template slot-scope="scope">
                       <span :style="scope.row.Isbool ? 'color: #67C23A;' : 'color: red;'">{{ scope.row.Isbool }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Date" label="日期" width="180"></el-table-column>
                <el-table-column prop="Mac" label="Mac"  ></el-table-column>
                <el-table-column prop="Qrcode" label="二维码"  ></el-table-column>
                <el-table-column prop="Dl" label="电流"  ></el-table-column>
                <el-table-column prop="Adc" label="Adc"  ></el-table-column>
                <el-table-column prop="Dy" label="电压"  ></el-table-column>
                <el-table-column prop="Dliang" label="电量"  ></el-table-column>
                <el-table-column prop="Dliangbool" label="电量结果"  ></el-table-column>
                <el-table-column prop="Lyqd" label="蓝牙强度"  ></el-table-column>
                <el-table-column prop="Lyqdbool" label="蓝牙结果"  ></el-table-column>
                <el-table-column prop="Cgq" label="传感器"  ></el-table-column>
                <el-table-column prop="Cgqbool" label="传感器结果"  ></el-table-column>
                <el-table-column prop="Bb" label="版本"  ></el-table-column>
                <el-table-column prop="Bbbool" label="版本结果"  ></el-table-column>
                <el-table-column prop="Sjcd" label="视觉测灯"  ></el-table-column>
            </el-table>
            <h3 style="text-align: center;">复测XYZ数据
                <el-button style="margin-left: 10px;font-size: inherit;" size="small" v-if="tableDataxyz.length > 0" type="text" @click="Xiazxyz">{{$t('All.下载')}}(Exlce)</el-button>
            </h3>
            <el-table :data="tableDataxyz" style="width: 100%;padding: 10px;text-align: center;">
              <el-table-column type="index"></el-table-column>
                <el-table-column prop="XYZIsbool" label="最终结果"  >
                    <template slot-scope="scope">
                       <span :style="scope.row.XYZIsbool === 'PASS'? '' : 'color: red;'">{{ scope.row.XYZIsbool }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="Date" label="日期" width="180"></el-table-column>
                <el-table-column prop="Mac" label="MAC"  ></el-table-column>
                <el-table-column prop="X" label="X"  ></el-table-column>
                <el-table-column prop="Y" label="Y"  ></el-table-column>
                <el-table-column prop="Z" label="Z"  ></el-table-column>
            </el-table>
        </div>
        <download-excel id="download_exceladc" class = "export-excel-wrapper" :data = "tableDataadc" :fields = "json_fieldsadc"  :name = "`ADC测试数据${Date.now()}.xls`">
            <el-button v-show="false" type="primary" icon="download" > Download </el-button>
        </download-excel>
        <download-excel id="download_excelpow" class = "export-excel-wrapper" :data = "tableDatapow" :fields = "json_fields" :name = "`电流电压测试数据${Date.now()}.xls`">
            <el-button v-show="false" type="primary" icon="download" > Download </el-button>
        </download-excel>
        <download-excel id="download_excelxyz" class = "export-excel-wrapper" :data = "tableDataxyz" :fields = "json_fieldsXYZ" :name = "`复测试XYZ数据${Date.now()}.xls`">
            <el-button v-show="false" type="primary" icon="download" > Download </el-button>
        </download-excel>
        <download-excel id="download_exceltadc" class = "export-excel-wrapper" :data = "Tadc" :fields = "json_fieldsadc"  :name = "`ADC测试数据${Date.now()}.xls`">
            <el-button v-show="false" type="primary" icon="download" > Download </el-button>
        </download-excel>
        <download-excel id="download_exceltpow" class = "export-excel-wrapper" :data = "Tpowr" :fields = "json_fields" :name = "`电流电压测试数据${Date.now()}.xls`">
            <el-button v-show="false" type="primary" icon="download" > Download </el-button>
        </download-excel>
    </div>
</template>
<script>
export default {
  data () {
    return {
      form: {
        Stardate: '',
        Enddate: '',
        Test: '',
        Type: 1
      },
      form2: {
        Stardate: '',
        Enddate: '',
        Test: '',
        Type: 1
      },
      Datedata: '',
      Datedata2: '',
      tableDataadc: [],
      tableDatapow: [],
      tableDataxyz: [],
      Pedaldgwtest: [],
      Sleepclass: [],
      Zuizhongclass: [],
      options: [
        {
          value: 1,
          label: 'Mac'
        }, {
          value: 2,
          label: '二维码'
        }],
      json_fieldsadc: {
        日期: 'Date', // 常规字段
        MAC: 'Mac', // 支持嵌套属性
        初始值: 'Csz',
        最高值: 'Zgz',
        归位值: 'Gwz',
        Qrcode: 'Qrcode',
        最终结果: 'Isbool'
      },
      json_meta: [
        [
          {
            ' key ': ' charset ',
            ' value ': ' utf- 8 '
          }
        ]
      ],
      excelpage: [], // 存放用于导出excel的数据
      json_fields: {
        日期: 'Date', // 常规字段
        MAC: 'Mac', // 支持嵌套属性
        阶段名称1: 'Name1',
        阶段1单位: 'Dw1',
        正常工作电流1: 'Zcdl1',
        正常工作电流2: 'Zcdl2',
        正常工作电流3: 'Zcdl3',
        正常工作电流平均值: 'Zcdl',
        阶段名称2: 'Name2',
        阶段2单位: 'Dw2',
        待机电流1: 'Djdl1',
        待机电流2: 'Djdl2',
        待机电流3: 'Djdl3',
        待机电流平均值: 'Djdl',
        阶段名称3: 'Name2',
        阶段3单位: 'Dw3',
        休眠电流1: 'Smdl1',
        休眠电流2: 'Smdl2',
        休眠电流3: 'Smdl3',
        休眠电流平均值: 'Smdl',
        最终结果: 'Isbool',
        X: 'X',
        Y: 'Y',
        Z: 'Z',
        XYZ结果: 'XYZIsbool'
      },
      json_fieldsXYZ: {
        日期: 'Date', // 常规字段
        MAC: 'Mac', // 支持嵌套属性
        X: 'X',
        Y: 'Y',
        Z: 'Z',
        最终结果: 'XYZIsbool'
      },
      quanbadc: {
        title: {
          text: '捷安特ADC测试数据',
          subtext: this.$t('All.系统现有数据'),
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: this.$t('All.现有数据'),
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: 'MSDS' },
              { value: 735, name: '检验设备' },
              { value: 580, name: '标准样品' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      quanbpowr: {
        title: {
          text: '捷安特电流测试数据',
          subtext: this.$t('All.系统现有数据'),
          left: 'center'
        },
        tooltip: {
          trigger: 'item'
        },
        legend: {
          orient: 'vertical',
          left: 'left'
        },
        series: [
          {
            name: this.$t('All.现有数据'),
            type: 'pie',
            radius: '50%',
            data: [
              { value: 1048, name: 'MSDS' },
              { value: 735, name: '检验设备' },
              { value: 580, name: '标准样品' }
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      },
      Tadc: [],
      Tpowr: []
    }
  },
  created () {
    this.Datedata2 = [
      new Date().toISOString(),
      new Date().toISOString()
    ]
    this.Getdata(this.form2)
  },
  mounted () {
  },
  methods: {
    async Jatselset (row) {
      try {
        if (this.Datedata) {
          this.form.Stardate = this.Datedata[0].toString()
          this.form.Enddate = this.Datedata[1].toString()
        }
        if (!this.form.Test) return this.$notify.error('请输入查询参数')
        const { data: res } = await this.$http.post('/api/Jat/Jatselset', row)
        if (res.status !== 200) return this.$notify.error(res.msg)
        this.tableDataadc = res.ADC
        this.tableDatapow = res.Current
        this.tableDatapow = res.Current
        this.tableDataxyz = res.XYZ
        this.Pedaldgwtest = res.Pedaldgwtest
        this.Sleepclass = res.Sleepclass
        this.Zuizhongclass = res.Zuizhongclass
        this.$notify.success('查询成功！')
      } catch (error) {
        alert('网络异常')
      }
    },
    Xiazadc () {
      document.getElementById('download_exceladc').click()
    },
    Xiazpow () {
      document.getElementById('download_excelpow').click()
    },
    Tadcdw () {
      document.getElementById('download_exceltadc').click()
    },
    Tpowdw () {
      document.getElementById('download_exceltpow').click()
    },
    Xiazxyz () {
      document.getElementById('download_excelxyz').click()
    },
    async Getdata (row) {
      try {
        if (this.Datedata2) {
          this.form2.Stardate = this.Datedata2[0].toString()
          this.form2.Enddate = this.Datedata2[1].toString()
        }
        const { data: res } = await this.$http.post('/api/Jat/Getdata', row)
        if (res.status !== 200) return this.$message.error(res.msg)
        const myChart = this.$echarts.init(document.getElementById('quanbadc'))
        window.onresize = function () {
          myChart.resize()
        }
        const myChart2 = this.$echarts.init(document.getElementById('quanbpowr'))
        window.onresize = function () {
          myChart2.resize()
        }
        this.quanbadc.series[0].data = res.Adcdata
        this.quanbadc.title.subtext = `系统现有数据:${res.Adc},良品:${res.Adcliang},不良品:${res.Adcbuliang}`
        this.quanbpowr.series[0].data = res.Powedata
        this.quanbpowr.title.subtext = `系统现有数据:${res.Powe},良品:${res.Powliang},不良品:${res.Powbuliang}`
        this.Tadc = res.Adcalldata
        this.Tpowr = res.Powealldata
        // 使用刚指定的配置项和数据显示图表。
        myChart.setOption(this.quanbadc)
        myChart2.setOption(this.quanbpowr)
      } catch (error) {
        console.log(error)
        return this.$message.error(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.jatbody{
    margin: 0 auto;
    text-align: center;
    padding: 20px;
}
</style>
