<template>
  <div>
    <div>
      <el-select v-if="$store.state.Login.Jurisdiction == 0" size="medium" v-model="Factory" style="width: 150px; padding: 0px 0px 0px 10px;" clearable :placeholder="this.$t('system.选择厂区')">
        <el-option
          v-for="item in $PublicJs.factory"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-select size="medium" v-if="$store.state.Login.Jurisdiction == 0" v-model="Department" style="width: 150px; padding: 0px 0px 0px 10px;" clearable :placeholder="$t('system.选择部门')">
        <el-option
          v-for="item in $PublicJs.department"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select>
      <el-input size="medium" clearable v-model="Text" style="width: 200px; padding: 0px 10px 0px 10px;" :placeholder="$t('All.账号姓名模糊查询')"></el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="UserJyySele()">{{$t('All.搜索')}}</el-button>
      <el-button size="small" type="primary" v-if="$store.state.Login.Jurisdiction <= 4" @click="dialogFormVisible = true">{{$t('All.新增用户')}}</el-button>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%;">
          <el-table-column align="center" :label="$t('All.序号')" type="index" width="90">
          </el-table-column>
          <el-table-column align="center" prop="Date" :label="$t('All.创建日期')" width="180">
          </el-table-column>
          <el-table-column align="center" prop="Username" :label="$t('All.姓名')">
          </el-table-column>
          <el-table-column align="center" prop="User" :label="$t('All.用户名')" width="180">
          </el-table-column>
          <el-table-column align="center" :label="$t('All.权限')">
            <template slot-scope="scope">
              {{ $PublicJs.Jurisdiction(scope.row.Jurisdiction) }}
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('All.部门')">
            <template slot-scope="scope">
              {{ $PublicJs.JurisdictionDepartment(scope.row.Department) }}
            </template>
          </el-table-column>
          <el-table-column align="center" width="155"  fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="UserUp(scope.row)">Edit</el-button>
              <el-button size="mini" v-show="scope.row.User !== $store.state.Login.User" :disabled="$store.state.Login.Jurisdiction > 3" type="danger" @click="UserDel(scope.row)">Delete</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          style="text-align: center;display: flow;"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pageindex"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="page_size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          hide-on-single-page>
        </el-pagination>
      </template>
    </div>
    <el-dialog :title="$t('All.新增用户')" :visible.sync="dialogFormVisible" width="500px" append-to-body>
      <el-form :model="User" :rules="$PublicJs.rules" ref="User" label-width="125px">
        <el-form-item :label="$t('All.姓名')" prop="Username">
          <el-input v-model="User.Username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('All.用户名')" prop="User">
          <el-input v-model="User.User"  @input="addhandleInput" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('All.密码')" :error="adderror">
          <el-input v-model="User.Password" @input="addvalidateInput" show-password autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('All.部门')" prop="Department">
          <el-select v-model="User.Department" :disabled= "Openis" :placeholder="$t('All.请选择')" style="width: 100%;">
            <el-option
              v-for="item in $PublicJs.department"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.value == $store.state.Login.Department ||  parseInt($store.state.Login.Jurisdiction) == 0 ||  parseInt($store.state.Login.Jurisdiction) == 1? false:true">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('All.权限')" prop="Jurisdiction">
          <el-select v-model="User.Jurisdiction" :placeholder="$t('All.请选择')" style="width: 100%;">
            <el-option
              v-for="item in $PublicJs.jurisdiction"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="parseInt(item.value) > parseInt($store.state.Login.Jurisdiction)?false:true">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('All.厂区')" prop="Factory">
          <el-select v-model="User.Factory" :disabled= "Openis" :placeholder="$t('All.请选择')" style="width: 100%;">
            <el-option
              v-for="item in $PublicJs.factory"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.value == $store.state.Login.Factory ||  parseInt($store.state.Login.Jurisdiction) == 0 ? false:true">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{$t('All.取消')}}</el-button>
        <el-button type="primary" @click="UserDataAdd(User)">{{$t('All.确定')}}</el-button>
      </div>
    </el-dialog>
    <el-dialog :title="$t('All.编辑用户')" :visible.sync="dialogFormVisible1" width="500px" append-to-body>
      <el-form :model="UpUser" :rules="$PublicJs.rules" ref="UpUser" label-width="125px">
        <el-form-item :label="$t('All.姓名')" prop="Username">
          <el-input v-model="UpUser.Username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('All.用户名')" prop="User">
          <el-input v-model="UpUser.User" @input="updhandleInput" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('All.密码')" :error="upderror">
          <el-input v-model="UpUser.Password" @input="updvalidateInput" show-password autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item :label="$t('All.部门')" prop="Department">
          <el-select v-model="UpUser.Department" :disabled= "Openis" :placeholder="$t('All.请选择')" style="width: 100%;">
            <el-option
              v-for="item in $PublicJs.department"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.value == $store.state.Login.Department || $store.state.Login.Jurisdiction == 0? false:true">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('All.权限')" prop="Jurisdiction">
          <el-select v-model="UpUser.Jurisdiction" :placeholder="$t('All.请选择')" style="width: 100%;">
            <el-option
              v-for="item in $PublicJs.jurisdiction"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="parseInt(item.value) > parseInt($store.state.Login.Jurisdiction)?false:true">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('All.厂区')" prop="Factory">
          <el-select v-model="UpUser.Factory" :disabled= "Openis" :placeholder="$t('All.请选择')" style="width: 100%;">
            <el-option
              v-for="item in $PublicJs.factory"
              :key="item.value"
              :label="item.label"
              :value="item.value"
              :disabled="item.value == $store.state.Login.Factory || $store.state.Login.Jurisdiction == 0 ? false:true">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible1 = false">{{$t('All.取消')}}</el-button>
        <el-button type="primary" @click="UserDataUp(UpUser)">{{$t('All.确定')}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import PublicJs from '@/assets/JS/PublicJs'

export default {
  data () {
    return {
      tableData: [],
      User: {
        Id: 0,
        Date: new Date(),
        User: '',
        Password: '',
        Username: '',
        Factory: '',
        Wx: '',
        Wxgroup: '',
        Jurisdiction: '',
        Department: ''
      },
      UpUser: [],
      dialogFormVisible: false,
      dialogFormVisible1: false,
      Openis: false,
      grouplist: [],
      wxlist: [],
      Text: '',
      total: 0,
      page_size: 10,
      pageindex: 1,
      Factory: '',
      Department: '',
      adderror: '',
      upderror: ''
    }
  },
  created () {
    this.UserJyySele()
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData', 'TabComponentFun']),
    async UserJyySele () {
      const pgeage = {
        Text: this.Text,
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login,
        Factory: this.Factory,
        Department: this.Department
      }
      const { data: res } = await this.$http.post('/api/User/SeleUserData', pgeage)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.tableData = res.response.UserData
      this.total = res.response.TotalCount
    },
    handleSizeChange (val) {
      this.page_size = val
      this.UserJyySele()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.UserJyySele()
    },
    async UserDataUp (row) {
      this.$refs.UpUser.validate(async valid => {
        if (!valid) return
        if (row.Password === '') return this.$message.error(this.$t('Upuserpassd.请输入新密码'))
        if (this.upderror !== '') return this.$message.error(this.$t('Upuserpassd.新密码强度不符合需求请重新填写'))
        const { data: res } = await this.$http.post('/api/Admin/UserUp', row)
        if (res.status !== 200) return this.$message.error(res.msg)
        this.UserJyySele()
        this.$message.success(res.msg)
        this.dialogFormVisible1 = false
      })
    },
    async UserDataDel (row) {
      const { data: res } = await this.$http.post('/api/Admin/UserDel', row)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.UserJyySele()
      this.$message.success(res.msg)
    },
    async UserDataAdd (row) {
      this.$refs.User.validate(async valid => {
        if (!valid) return
        if (row.Password === '') return this.$message.error(this.$t('Upuserpassd.请输入新密码'))
        if (this.adderror !== '') return this.$message.error(this.$t('Upuserpassd.新密码强度不符合需求请重新填写'))
        const { data: res } = await this.$http.post('/api/Admin/UserAdd', row)
        if (res.status !== 200) return this.$message.error(res.msg)
        this.UserJyySele()
        this.dialogFormVisible = false
        this.$message.success(res.msg)
      })
    },
    UserAdd () {
      this.TabComponentFun('UserAdd')
    },
    AdminAdd () {
      this.TabComponentFun('AdminAdd')
    },
    Delete () {
      PublicJs.Eixt(this.$router)
    },
    UserUp (row) {
      this.UpUser = row
      this.dialogFormVisible1 = true
    },
    async groupchange (value) {
      const group = {
        robot_wxid: 'wxid_xfqq4dv1y4px12',
        group_wxid: value,
        is_refresh: 1
      }
      const { data: res } = await this.$http.post('https://wx.nienyi.cn/api/Obtain/Get_group_member', group)
      if (res.code !== 0) return this.$message.error(res.msg)
      this.wxlist = res.data
    },
    UserDel (row) {
      this.$confirm(this.$t('All.确认删除用户吗'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          this.UserDataDel(row)
        })
        .catch(_ => { })
    },
    addvalidateInput () {
      // 正则表达式
      const lengthPattern = /^(?!.*(.).*\1{5})[a-zA-Z0-9!@#$%^&*]{8,16}$/
      const charTypePattern = /^(?=.*[a-zA-Z])(?=.*[0-9])|(?=.*[a-zA-Z])(?=.*[!@#$%^&*])|(?=.*[0-9])(?=.*[!@#$%^&*])/
      const sequencePattern = /^(?!.*(\d)\1{5})(?!.*([a-zA-Z])\2{5})(?!.*(0123456|1234567|abcdefg|ABCDEFG))/
      const forbiddenPattern = /(nienyi)/i // 禁止出现 "nienyi"，忽略大小写

      // 校验密码
      if (!lengthPattern.test(this.User.Password)) {
        this.adderror = this.$t('Upuserpassd.密码规则')
      } else if (!charTypePattern.test(this.User.Password)) {
        this.adderror = this.$t('Upuserpassd.密码必须包含字母数字符号中的至少两种')
      } else if (!sequencePattern.test(this.User.Password)) {
        this.adderror = this.$t('Upuserpassd.密码不能包含连续字符或重复6位以上字符')
      } else if (forbiddenPattern.test(this.User.Password)) {
        this.adderror = this.$t('Upuserpassd.密码不能包含nienyi字样')
      } else {
        this.adderror = '' // 校验通过，清空错误信息
      }
    },
    addhandleInput (value) {
      // 使用正则表达式，移除非字母和数字的字符
      this.User.User = value.replace(/[^a-zA-Z0-9]/g, '')
    },
    updvalidateInput () {
      // 正则表达式
      const lengthPattern = /^(?!.*(.).*\1{5})[a-zA-Z0-9!@#$%^&*]{8,16}$/
      const charTypePattern = /^(?=.*[a-zA-Z])(?=.*[0-9])|(?=.*[a-zA-Z])(?=.*[!@#$%^&*])|(?=.*[0-9])(?=.*[!@#$%^&*])/
      const sequencePattern = /^(?!.*(\d)\1{5})(?!.*([a-zA-Z])\2{5})(?!.*(0123456|1234567|abcdefg|ABCDEFG))/
      const forbiddenPattern = /(nienyi)/i // 禁止出现 "nienyi"，忽略大小写

      // 校验密码
      if (!lengthPattern.test(this.UpUser.Password)) {
        this.upderror = this.$t('Upuserpassd.密码规则')
      } else if (!charTypePattern.test(this.UpUser.Password)) {
        this.upderror = this.$t('Upuserpassd.密码必须包含字母数字符号中的至少两种')
      } else if (!sequencePattern.test(this.UpUser.Password)) {
        this.upderror = this.$t('Upuserpassd.密码不能包含连续字符或重复6位以上字符')
      } else if (forbiddenPattern.test(this.UpUser.Password)) {
        this.upderror = this.$t('Upuserpassd.密码不能包含nienyi字样')
      } else {
        this.upderror = '' // 校验通过，清空错误信息
      }
    },
    updhandleInput (value) {
      // 使用正则表达式，移除非字母和数字的字符
      this.UpUser.User = value.replace(/[^a-zA-Z0-9]/g, '')
    }
  }
}
</script>
<style lang="less" scoped>
/** talbe表头置顶**/
/deep/ .el-table {
    overflow: visible;
 }

 /deep/ .el-table__header-wrapper {
    position: sticky;
    top: 0px;
    z-index: 10;
 }
.is-error {
  margin-bottom: 35px;
}
</style>
