<template>
  <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd'">
    <div style="text-align: right; z-index: 1000;">
      <el-button size="small" style="text-align: right;"  type="primary" @click="isqpisboot = !isqpisboot">{{isqpisboot ? $t('All.取消全屏'): $t('All.全屏')}}</el-button>
      <el-button size="small" type="warning"  v-if="!isdata" @click="klfun()">{{this.$t('All.克隆')}}</el-button>
      <el-button size="small" type="primary"  v-if="isdata"  @click="FQC_Admin()">{{this.$t('system.tijiao')}}</el-button>
      <el-button size="small" type="primary"  v-if="!isdata" @click="UserDataUp()">{{this.$t('system.gxup')}}</el-button>
      <el-button size="small" type="primary"   @click="dialogVisible = true">{{this.$t('system.fjian')}}</el-button>
      <el-button size="small" type="danger" v-if="!isdata"  @click="Delete()">{{this.$t('system.del')}}</el-button>
    </div>
    <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
    <h3 style="text-align: center;">{{this.$t('system.成品检验记录')}}</h3>
    <h4 style="text-align: center;">{{this.$t('system.lururen')}}：{{form.Enteredby}}</h4>
    <div style="text-align: right;padding-right: 10px; ">
      {{$t('system.料号')}}:<el-input onkeyup="value=value.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" @blur="form.Item_no=form.Item_no.replace(/[^a-zA-Z0-9]/g,'').toUpperCase()" oninput="if (value.trim() === '') { value = null; }" style="width: 300px;border: 1px solid #DCDFE6;border-radius: 5px;" v-model="form.Item_no" ></el-input>
    </div>
    <div style=" padding: 10px; text-align: center; line-height: 2vw;">
      <table border="1"  align="center" cellpadding="10" style="width: 100%;"  cellspacing="0">
         <tr>
            <td >{{this.$t('FQC.制造班别')}}</td>
            <td >{{this.$t('FQC.工单号')}}</td>
            <td >{{this.$t('FQC.客户')}}</td>
            <td colspan="3">{{this.$t('FQC.品名')}}</td>
            <td colspan="2">{{this.$t('FQC.图面编号')}}</td>
            <td colspan="3">LOT NO.</td>
          </tr>
          <tr>
              <td class="tdd"><el-input disabled v-model="form.Banbi"></el-input></td>
              <td class="tdd"><el-input disabled v-model="form.Workorder"></el-input></td>
              <td class="tdd"><el-input v-model="form.Customer"></el-input></td>
              <td colspan="3" class="tdd"><el-input v-model="form.Product_name"></el-input></td>
              <td class="tdd" colspan="2"><el-input v-model="form.Drawing_number"></el-input></td>
              <td colspan="3" class="tdd"><el-input v-model="form.Lot_NO"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('FQC.第三方检测报告有效性')}}</td>
            <td style="text-align: left;" colspan="6"><el-input type="textarea" :rows="5" v-model="form.Effectiveness"></el-input></td>
            <td colspan="3">{{this.$t('FQC.外观')}}</td>
          </tr>
          <tr>
            <td class="tdd">{{this.$t('FQC.抽样数')}}</td>
            <td class="tdd">{{this.$t('FQC.尺寸')}}</td>
            <td class="tdd">{{this.$t('FQC.包装')}}</td>
            <td class="tdd">{{this.$t('FQC.适配')}}</td>
            <td class="tdd">{{this.$t('FQC.交货数')}}</td>
            <td class="tdd">{{this.$t('FQC.入库数')}}</td>
            <td class="tdd">{{this.$t('FQC.箱数')}}</td>
            <td class="tdd">{{this.$t('FQC.颜色')}}</td>
            <td class="tdd">{{this.$t('FQC.插头')}}</td>
            <td class="tdd">CABLE/MOLD</td>
          </tr>
          <tr>
              <td class="tdd"><el-input v-model="form.Sampling"></el-input></td>
              <td class="tdd"><el-input v-model="form.Size"></el-input></td>
              <td class="tdd"><el-input v-model="form.Packing"></el-input></td>
              <td class="tdd"><el-input v-model="form.Other"></el-input></td>
              <td class="tdd"><el-input v-model="form.Delivery"></el-input></td>
              <td class="tdd"><el-input v-model="form.Warehousing"></el-input></td>
              <td class="tdd"><el-input v-model="form.Case_number"></el-input></td>
              <td class="tdd"><el-input v-model="form.Colour"></el-input></td>
              <td class="tdd"><el-input v-model="form.Plug"></el-input></td>
              <td class="tdd"><el-input v-model="form.Cable_MOLD"></el-input></td>
          </tr>
          <tr>
            <td class="tdd">{{this.$t('FQC.AQL标准')}}</td>
              <td colspan="5">{{this.$t('FQC.抽样标准')}}</td>
              <td class="tdd">{{this.$t('FQC.参考文件')}}</td>
              <td colspan="1" class="tdd"><el-input v-model="form.Standard2"></el-input></td>
              <td class="tdd">{{this.$t('IQC.检验基准表')}}</td>
              <td colspan="2" class="tdd">
                <el-input v-model="form.Inspection_benchmarkurl"></el-input>
              </td>
          </tr>
          <tr>
              <td class="tdd" rowspan="2" colspan="2">{{this.$t('FQC.可靠的实验编号')}}</td>
              <td class="tdd" colspan="8">{{this.$t('FQC.电性测试')}}</td>
          </tr>
          <tr>
              <td class="tdd" colspan="3">{{this.$t('FQC.参数设定线位')}}</td>
              <td class="tdd" colspan="3">{{this.$t('FQC.测试仪器编号')}}</td>
              <td class="tdd">{{this.$t('FQC.不良数')}}</td>
              <td class="tdd">{{this.$t('FQC.判定')}}</td>
          </tr>
          <tr>
              <td class="tdd" colspan="2"><el-input v-model="form.Xrf"><template slot="prepend">XRF</template></el-input></td>
              <td class="tdd" rowspan="2" colspan="3"><el-input v-model="form.Canshu1" type="textarea" :rows="5"></el-input></td>
              <td class="tdd" rowspan="2" colspan="3"><el-input v-model="form.Yqi1" type="textarea" :rows="5"></el-input></td>
              <td class="tdd" rowspan="3"><el-input v-model="form.Bniang"></el-input></td>
              <td class="tdd" rowspan="3"><el-input v-model="form.Pding"></el-input></td>
          </tr>
          <tr>
              <td class="tdd" colspan="2"><el-input v-model="form.Icp"><template slot="prepend">ICP</template></el-input></td>
          </tr>
          <tr>
              <td class="tdd"  colspan="2"><el-input v-model="form.Kkaoqta" type="textarea" :rows="5"></el-input></td>
              <td class="tdd"  colspan="3"><el-input v-model="form.Canshu2" type="textarea" :rows="5"></el-input></td>
              <td class="tdd"  colspan="3"><el-input v-model="form.Yqi2" type="textarea" :rows="5"></el-input></td>
          </tr>
          <tr>
              <td class="tdd" rowspan="2">{{this.$t('FQC.规格要求')}}</td>
              <td class="tdd" colspan="2">{{this.$t('FQC.量测仪器编号')}}</td>
              <td colspan="7" class="tdd"><el-input v-model="form.Yqish1"></el-input></td>
          </tr>
          <tr>
              <td colspan="1" class="tdd" >{{$t('All.单位') + '/' + $t('All.标注')}}</td>
              <td colspan="5" class="tdd" >{{$t('FQC.尺寸检验记录')}}</td>
              <td colspan="1" class="tdd">{{this.$t('FQC.最大值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('FQC.最小值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('FQC.判定')}}</td>
          </tr>
          <tr v-for="(text, index) in form.Parcss" :key="index">
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text" ></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text1"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text2"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text3"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text4"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text5"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text6"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text7"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input disabled v-model="text.Text8"></el-input></td>
              <td class="tdd" style="min-width: 150px;"><el-button type="primary"  @click="addDomain(text,index)" size="mini">Add</el-button><el-button v-show="form.Parcss.length > 1" type="danger"  @click="removeDomain(text)" size="mini">Del</el-button></td>
          </tr>
          <tr>
            <td class="tdd"  colspan="5"><el-input :placeholder="this.$t('FQC.请输入异常描述')" v-model="form.Ycms" type="textarea" :rows="5"></el-input></td>
            <td class="tdd"  colspan="5"><el-input :placeholder="this.$t('FQC.请输入备注')" v-model="form.Beizhu" type="textarea" :rows="5"></el-input></td>
        </tr>
      </table>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('FQC.核准')}}: {{form.Hz}}</span></el-col>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('FQC.检验员')}}: {{form.Jyy}}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><h6></h6></el-col>
        <el-col :span="12" style="border: 0px solid transparent;padding-right: 20px;text-align: right;"><span style="font-size: 1.5vw;">NYP1001-02-J</span></el-col>
      </el-row>
    </div>
    <el-dialog
    :title="this.$t('system.fjian')"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose" append-to-body>
      <fqcUpload :isdata = "isdata" :fqcadmin = "form.Number"></fqcUpload>
    </el-dialog>
    <el-dialog
    :title="this.$t('All.克隆')"
      :visible.sync="dialogVisiblekl"
      width="500px"
      :before-close="handleClose" append-to-body>
      <el-form :model="klform">
        <el-form-item :label="$t('All.模版单号')">
            {{ klform.Number }}
          </el-form-item>
        <el-form-item :label="$t('All.克隆后的料号')">
            <el-input v-model="klform.Item_no" autocomplete="off"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogVisiblekl = false">{{$t('All.取消')}}</el-button>
          <el-button type="primary" @click="KlFQC_Admin()">{{$t('All.确定')}}</el-button>
        </div>
    </el-dialog>
  </div>
</template>
<script>
import fqcUpload from './Upload/FQCAdminUpload.vue'
export default {
  data () {
    return {
      form: {
        Timsdate: '', // 检验员填写日期
        Date: new Date(), // 写入时间
        Banbi: '', // 班别
        Workorder: '', // 工单号
        Customer: '', // 客户
        Product_name: '', // 品名规格
        Drawing_number: '', // 图面编号
        Edition: '', // 版本
        Lot_NO: '', // LOT_NO
        Delivery: '', // 交货数
        Warehousing: '', // 入库数
        Case_number: '', // 箱号
        Standard1: this.$t('All.抽样数按判定依据按标准执行'), // AQL标准1
        Standard2: '', //  参考文件
        Sampling: '', // 抽样数
        Colour: '', // 颜色
        Plug: '', // 插头
        Cable_MOLD: '', // CABLE_MOLD
        Size: '', // 尺寸
        Packing: '', // 包装
        Other: '', // 其它
        Xrf: '', // XRF
        Icp: '', // ICQ
        Kkaoqta: '', // 可靠的实验其他
        Canshu1: '', // 参数设定1
        Canshu2: '', // 参数设定2
        Yqi1: '', // 测试仪器编号1
        Yqi2: '', // 测试仪器编号2
        Bniang: '', // 不良数
        Pding: '', // 判定
        Yqish1: '', // 量测仪器/编号1
        Yqish2: '', // 量测仪器/编号2
        Ycms: '', // 异常描述
        Beizhu: '1. 10% of sampling rate per lot on the measurement of dimensions ; when the total recorded sampling quantities larger than 10 samples, only record the initital 10set of data.', // 备注
        Hz: '', // 核准人
        Jyy: '', // 检验员
        Enteredby: this.$store.state.Login.Username, // 录入人
        Parcss: [],
        Effectiveness: '', // 第三方有效性报告
        Factory: this.$store.state.Login.Factory,
        Number: '',
        Item_no: '',
        Drawing_numberurl: '',
        Inspection_benchmark: '',
        Inspection_benchmarkurl: ''
      },
      isdata: false,
      dialogVisible: false,
      isqpisboot: false,
      dialogVisiblekl: false,
      klform: {}
    }
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename'],
  created () {
    if (this.$store.state.UserData === null) {
      this.isdata = true
      this.form.Enteredby = this.$store.state.Login.Username
      this.form.Item_no = this.$store.state.Productname
      this.QcCount()
    } else {
      this.isdata = false
      this.form = this.$store.state.UserData
      if (this.form.Parcss.length < 1) {
        const contr = {
          Index: 0,
          Product_name: this.form.Product_name, // 品名规格
          Number: this.form.Number,
          Text: '',
          Text1: '',
          Text2: '',
          Text3: '',
          Text4: '',
          Text5: '',
          Text6: '',
          Text7: '',
          Text8: '',
          Text9: ''
        }
        this.form.Parcss.splice(0, 0, contr)
      }
    }
  },
  components: {
    fqcUpload
  },
  mounted () {
  },
  methods: {
    async QcCount () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        this.form.Number = this.$store.state.FQCAdminNumber
        const contr = {
          Index: 0,
          Product_name: this.form.Product_name, // 品名规格
          Number: this.$store.state.FQCAdminNumber,
          Text: '',
          Text1: '',
          Text2: '',
          Text3: '',
          Text4: '',
          Text5: '',
          Text6: '',
          Text7: '',
          Text8: '',
          Text9: ''
        }
        this.form.Parcss.splice(0, 0, contr)
        loadingInstance.close()
      } catch (error) {
        loadingInstance.close()
        alert(this.$t('All.网络异常'))
      }
    },
    addDomain (item, index) {
      const contr = {
        Product_name: item.Product_name, // 品名规格
        Index: index + 1,
        Text: item.Text,
        Number: item.Number,
        Text1: item.Text1,
        Text2: '',
        Text3: '',
        Text4: '',
        Text5: '',
        Text6: '',
        Text7: '',
        Text8: '',
        Text9: ''
      }
      this.form.Parcss.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Parcss.length; i++) {
        this.form.Parcss[i].Index = i
      }
    },
    removeDomain (item) {
      if (this.form.Parcss.length === 1) {
        return this.$message.error(this.$t('All.数据表必须留一行数据'))
      }
      var index = this.form.Parcss.indexOf(item)
      if (index !== -1) {
        this.form.Parcss.splice(index, 1)
      }
      for (let i = 0; i < this.form.Parcss.length; i++) {
        this.form.Parcss[i].Index = i
      }
    },
    async FQC_Admin () {
      if (this.form.Item_no.length < 1) return this.$message.error(this.$t('All.请填写料号后在提交'))
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/User/UserDataAdd', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.Renewtargename(this.$t('All.FQC模版'), 'FQCAdminData', 'FQCAdminData')
        this.RemoveTab(this.$t('All.FQC模版') + '-' + this.form.Number)
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async UserDataUp () {
      if (this.form.Item_no.length < 1) return this.$message.error(this.$t('All.请填写料号后在提交'))
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/User/UserDataUp', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.提交成功'))
        this.Renewtargename(this.$t('All.FQC模版'), 'FQCAdminData', 'FQCAdminData')
        this.RemoveTab(this.$t('All.FQC模版') + '-' + this.form.Number)
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async Delete () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/User/FQCAdminDataDel', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.Del成功'))
        this.Renewtargename(this.$t('All.FQC模版'), 'FQCAdminData', 'FQCAdminData')
        this.RemoveTab(this.$t('All.FQC模版') + '-' + this.form.Number)
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    async klfun () {
      this.klform = { ...this.form }
      this.klform.Item_no = ''
      this.klform.Enteredby = this.$store.state.Login.Username
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const QcCountLogin = {
        Login: this.$store.state.Login,
        Type: 'FQC'
      }
      const { data: res } = await this.$http.post('/api/User/QcCount', QcCountLogin)
      if (res.status !== 200) {
        loadingInstance.close()
        return this.$message.error(res.response)
      }
      loadingInstance.close()
      this.klform.Number = res.response
      for (let index = 0; index < this.klform.Parcss.length; index++) {
        this.klform.Parcss[index].Number = this.klform.Number
      }
      this.dialogVisiblekl = true
    },
    async KlFQC_Admin () {
      if (this.klform.Item_no.length < 1) return this.$message.error(this.$t('All.请填写料号后在提交'))
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/User/UserDataAdd', this.klform)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.dialogVisiblekl = false
        this.Renewtargename(this.$t('All.FQC模版'), 'FQCAdminData', 'FQCAdminData')
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgb(255, 255, 255);
  min-width: 903px;
}
  .el-col {
    border: 1px solid;
    margin-right: -1px;
     margin-bottom: -1px;
    line-height: 4.5vw;
    font-size: 2vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .grid-content {
    min-height: 36px;
  }
  .bg-purple-light{

  }
  .row-bg {
    background-color: #f9fafc;
  }
</style>
