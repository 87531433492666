<template>
  <el-container>
  <el-header>
    <el-row>
      <el-col :span="8">
        <div class="grid-content bg-purple">
          <img class="logoimg2"  src="../../assets/Img/adtu9-gnqbt-001.png" alt="">
        </div>
      </el-col>
      <el-col :span="8"><div @click="$store.state.TabComponent = 'Home'" class="grid-content bg-purple-light"><h1 style="line-height: 15px;text-align: center;">太仓年益</h1></div></el-col>
      <el-col :span="8">
        <div class="grid-content bg-purple" style="float: right;">
          <el-dropdown @command="handleCommand">
            <i @click="isCollapse = true"  class="el-icon-s-fold asieico"></i>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="仓库分包">仓库分包</el-dropdown-item>
              <el-dropdown-item command="领料">领料</el-dropdown-item>
              <el-dropdown-item command="退料">退料</el-dropdown-item>
              <el-dropdown-item command="制粒标识卡">制粒标识卡</el-dropdown-item>
              <el-dropdown-item command="铜线标识卡">铜线标识卡</el-dropdown-item>
              <el-dropdown-item command="二部标识卡">二部标识卡</el-dropdown-item>
              <el-dropdown-item command="冲压/注塑标识卡">冲压/注塑标识卡</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>
  </el-header>
  <el-main>
    <transition name="fade-transform" mode="out-in">
      <component :is="this.$store.state.TabComponent" :Userid="Userid"></component>
    </transition>
  </el-main>
</el-container>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      isCollapse: true,
      value: localStorage.getItem('locale') || 'zh',
      authCode: '',
      code: '',
      state: '',
      Userid: ''
    }
  },
  mounted () {
  },
  created () {
    this.Printerdata()
  },
  components: {
    TraceQuery: () => import('@/components/Trace/TraceQuery.vue'),
    Subcontract: () => import('@/components/Trace/Subcontract.vue'),
    Subcontractdata: () => import('@/components/Trace/Subcontractdata.vue'),
    Printersytem: () => import('@/components/Trace/Printersytem.vue'),
    Qecode: () => import('@/components/Trace/Qrcode.vue'),
    User: () => import('@/views/Mobile/User.vue'),
    Home: () => import('@/views/Mobile/Home.vue'),
    ZL: () => import('../../components/ZLCTCY/ZL.vue'),
    CT: () => import('../../components/ZLCTCY/CT.vue'),
    CY: () => import('../../components/ZLCTCY/CY.vue'),
    ER: () => import('../../components/ZLCTCY/ER.vue'),
    Erfb: () => import('../../components/ZLCTCY/Erfb.vue'),
    Zlfb: () => import('../../components/ZLCTCY/Zlfb.vue'),
    Ctfb: () => import('../../components/ZLCTCY/Ctfb.vue'),
    Cyfb: () => import('../../components/ZLCTCY/Cyfb.vue'),
    // 单独工单指定料号
    ICUser: () => import('../../components/Trace/L/ICUser.vue'),
    // 查询工单物料
    LSeleItem: () => import('../../components/Trace/L/LSeleItem.vue')
  },
  methods: {
    ...mapMutations(['loginlist', 'PrinterData']),
    // App.vue
    _isMobile () {
      const flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i)
      return flag
    },
    exit () {
      this.$store.state.TabComponent = 'Home'
      this.$router.push('/Login')
    },
    async Printerdata () {
      const { data: res } = await this.$http.post('/api/Trace/Printerdata', this.$store.state.Login)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.PrinterData(res.response)
    },
    async getStaffInfo () {
      const { data: res } = await this.$http.get(`/api/Authenticate/Getwx?code=${this.code}&state=${this.state}`)
      if (res.status !== 200) {
        this.Userid = res.Userid
        this.$store.state.TabComponent = 'User'
        return
      }
      this.$message.success(this.$t('login.loginmessageyes'))
      this.loginlist(res.Userdata)
      this.Printerdata()
      window.sessionStorage.setItem('token', res.token)
      this.$store.state.TabComponent = 'Home'
    },
    handleCommand (command) {
      try {
        if (command === '仓库分包') {
          if (this.$store.state.Login.Department === 'CK' || this.$store.state.Login.Department === 'All') {
            this.$store.state.TabComponent = 'Qecode'
          } else {
            this.$message.error('您没有权限，只有仓库人员才能进行分包！')
          }
        } else if (command === '制粒标识卡') {
          if (this.$store.state.Login.Department === 'ZL' || this.$store.state.Login.Department === 'All') {
            this.$store.state.TabComponent = 'ZL'
          } else {
            this.$message.error('您没有权限，只有制粒人员才能进行打印！')
          }
        } else if (command === '铜线标识卡') {
          if (this.$store.state.Login.Department === 'CT' || this.$store.state.Login.Department === 'All') {
            this.$store.state.TabComponent = 'CT'
          } else {
            this.$message.error('您没有权限，只有铜线人员才能进行打印！')
          }
        } else if (command === '二部标识卡') {
          if (this.$store.state.Login.Department === 'SCEB' || this.$store.state.Login.Department === 'SJ' || this.$store.state.Login.Department === 'FQC' || this.$store.state.Login.Department === 'All') {
            this.$store.state.TabComponent = 'ER'
          } else {
            this.$message.error('您没有权限，只有二部人员才能进行打印！')
          }
        } else if (command === '冲压/注塑标识卡') {
          if (this.$store.state.Login.Department === 'CY' || this.$store.state.Login.Department === 'All') {
            this.$store.state.TabComponent = 'CY'
          } else {
            this.$message.error('您没有权限，只有冲压/注塑人员才能进行打印！')
          }
        } else if (command === '领料') {
          this.$store.state.TabComponent = 'LSeleItem'
        } else {
          this.$message.error('暂不开放')
        }
      } catch (error) {
        this.$message.error('请登录后在进行作业！')
      }
    }
  }
}
</script>
<style lang="less" scoped>
.el-container{
    height: 100%;
    background-color: rgb(255, 255, 255);
}
.el-aside{
    background-color: rgb(255, 255, 255);
    margin: 0 auto;
}
.el-main{
    padding: 0 !important;
    background-color: rgb(255, 255, 255);
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.topleft{
    float: left;
    font-size: 20px;
}
.topright{
    float: right;
    padding-right: 10px;
    line-height: 45px;
}
.el-breadcrumb{
    line-height: 50px;
    font-size: 16px;
}
.logoimg{
    height: 60px;
    width: 170px;
    margin-top: 5px;
}
.iconunfoled{
    font-size: xx-large;
}
.el-header{
  border-bottom: 1px solid rgba(0, 21, 41, 0.08);
  height: 60px !important;
  padding: 0px !important;
}
.tagsclass{
    cursor: default;
    float: left;
    width: 100%;
    height: 40px !important;
    padding: 0;
    line-height: 40px;
    box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.asieico{
    font-size: 25px;
    float: left;
    line-height: 50px;
    margin-left: 10px;
    margin-right: 10px;
}
.logoimg2{
    padding-top: 10px;
}
.el-tag{
   margin-left: 10px;
}
.el-menu{
  border-right: solid 1px #e6e6e669;
}
// global transition css

/* fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.28s;
}

.fade-enter,
.fade-leave-active {
  opacity: 0;
}

/* fade-transform */
.fade-transform-leave-active,
.fade-transform-enter-active {
  transition: all .4s;
}

.fade-transform-enter {
  opacity: 0;
  transform: translateX(-30px);
}

.fade-transform-leave-to {
  opacity: 0;
  transform: translateX(30px);
}

/* breadcrumb transition */
.breadcrumb-enter-active,
.breadcrumb-leave-active {
  transition: all .4s;
}

.breadcrumb-enter,
.breadcrumb-leave-active {
  opacity: 0;
  transform: translateX(20px);
}

.breadcrumb-move {
  transition: all .4s;
}

.breadcrumb-leave-active {
  position: absolute;
}
</style>
