<template>
  <div class="wboby">
    <el-input style="width: 300px;" @keyup.enter.native.native="UserDataSelect(Noitem)"  v-model="Noitem" :placeholder="this.$t('fqcseleitem.name')"></el-input>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Noitem: ''
    }
  },
  created () {
    this.User = this.$store.state.Login
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData']),
    ...mapMutations(['Productname']),
    async UserDataSelect (row) {
      const postdata = {
        Name: row,
        Factory: this.$store.state.Login.Factory
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/IQC/IQCDataSelectadmin', postdata)
      if (res.status !== 200) {
        loadingInstance.close()
        this.UserData(null)
        this.Productname(row)
        this.$message.success(this.$t('All.数据不存在') + '，' + this.$t('All.即将进入新增页面'))
        return this.TabComponentFun('IQCAdminFQC')
      }
      loadingInstance.close()
      this.$message.success(res.msg)
      res.response.Region = this.$store.state.Login.Factory
      this.UserData(res.response)
      this.TabComponentFun('IQCAdminFQC')
    }
  }
}
</script>
<style lang="less" scoped>
  .wboby{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 0px;
  width: 300px;
}
</style>
