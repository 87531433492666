<template>
    <div>
        <video id="video" ref="video" class="video vjs-fluid" autoplay />
      <button @click="toggleCamera">Toggle Camera</button>
      <button @click="toggleFlashlight">Toggle Flashlight</button>
    </div>
  </template>

<script>
import { BrowserMultiFormatReader } from '@zxing/library'

export default {
  data () {
    return {
      codeReader: null,
      selectedDeviceId: null,
      scanning: false,
      flashlightOn: false
    }
  },
  mounted () {
    this.initScanner()
  },
  methods: {
    async initScanner () {
      this.codeReader = new BrowserMultiFormatReader()
      try {
        const devices = await this.codeReader.listVideoInputDevices()
        if (devices.length > 0) {
          this.selectedDeviceId = devices[0].deviceId
          this.startScanning()
        }
      } catch (error) {
        console.error('Error listing video input devices:', error)
      }
    },
    async startScanning () {
      if (this.scanning) return // Prevent multiple scans simultaneously
      this.scanning = true

      const videoElement = this.$refs.video
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: { deviceId: this.selectedDeviceId } })
        videoElement.srcObject = stream

        const constraints = { video: { facingMode: 'environment' } }
        if (this.flashlightOn) {
          constraints.video = { facingMode: 'environment', torch: true }
        }
        const updatedStream = await navigator.mediaDevices.getUserMedia(constraints)
        videoElement.srcObject = updatedStream

        this.codeReader.decodeFromVideoElement(videoElement, (result, error) => {
          if (result) {
            console.log('Scan result:', result.text)
            this.stopScanning()
          } else {
            console.error('Error decoding QR code:', error)
            this.stopScanning()
          }
        })
      } catch (error) {
        console.error('Error accessing camera:', error)
        this.stopScanning()
      }
    },
    stopScanning () {
      if (this.codeReader) {
        this.codeReader.reset()
        this.codeReader.stopContinuousDecode()
        this.scanning = false
      }
    },
    async toggleCamera () {
      if (this.codeReader) {
        const devices = await this.codeReader.listVideoInputDevices()
        if (devices.length > 1) {
          const currentDeviceIndex = devices.findIndex(device => device.deviceId === this.selectedDeviceId)
          const nextDeviceIndex = (currentDeviceIndex + 1) % devices.length
          this.selectedDeviceId = devices[nextDeviceIndex].deviceId
          this.stopScanning()
          this.startScanning()
        }
      }
    },
    async toggleFlashlight () {
      this.flashlightOn = !this.flashlightOn
      this.stopScanning()
      this.startScanning()
    }
  },
  beforeDestroy () {
    this.stopScanning()
  }
}
</script>

  <style>
  /* Style your component as needed */
  </style>
