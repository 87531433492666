<template>
  <div class="minclass">
    <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd '" id="ipqcprintTest">
    <div style="text-align: right;z-index: 2;" >
      <el-button size="small" style="text-align: right;"  type="primary" @click="isqpisboot = !isqpisboot">{{isqpisboot ? $t('All.取消全屏'): $t('All.全屏')}}</el-button>
      <el-button size="small" style="text-align: right;"  type="primary" v-print="'#ipqcprintTest'">{{$t('All.打印')}}</el-button>
    </div>
    <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
    <h3 style="text-align: center;">{{ $t('All.生产巡检报表') }}</h3>
    <h4 style="text-align: center;">{{this.$t('system.systemid')}}：{{form.Numbers}}</h4>
    <h4 style="text-align: center;">{{ $t('All.录入人') }}：{{form.Entered}}</h4>
    <div style="text-align: center;">
      <span style="text-align: center;font-size: 28px;color: red;">{{State(form.State)}}</span>
    </div>
    <h3 style="text-align: right;padding-right: 10px; ">{{this.$t('system.InspectionDate')}}：{{form.Timsdate}}</h3>
    <h5 style="text-align: right;padding-right: 10px; ">{{ $t('All.料号') }}：{{form.Item_no}}</h5>
    <div style=" padding: 10px; text-align: center; line-height: 2vw;">
      <table border="1" style="width:100%; border-bottom: none;" align="center" cellpadding="10" cellspacing="0">
          <tr>
              <td colspan="2" class="tdd">{{ $t('All.班别') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.制令单号') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.客户') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.品名') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.测试机') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.XRF送测编号') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.ICP送测编号') }}</td>
              <td colspan="2" class="tdd" style="width:30px;">{{ $t('All.首件判定') }}</td>
          </tr>
          <tr>
            <td v-show="isdisabled"  colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Class" ></el-input></td>
            <td v-show="!isdisabled" colspan="2" class="tdd">
              <el-select v-model="form.Class" allow-create filterable :placeholder="this.$t('FQC.请选择')">
                  <el-option
                    v-for="i in $PublicJs.Banbi"
                    :key="i.value"
                    :label="i.label"
                    :value="i.value">
                  </el-option>
                </el-select>
            </td>
            <td colspan="2" class="tdd"><el-input disabled v-model="form.Order_no" ></el-input></td>
            <td colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Customer" ></el-input></td>
            <td colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Product_name" ></el-input></td>
            <td colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Test_machine" ></el-input></td>
            <td colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Xrfnumber" ></el-input></td>
            <td colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Icpnumber" ></el-input></td>
            <td colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.First_article" ></el-input></td>
          </tr>
          <tr>
              <td colspan="2" class="tdd">LOT NO.</td>
              <td colspan="2" class="tdd">{{ $t('All.SOP编号') }}</td>
              <td colspan="2" class="tdd">{{ $t('FQC.外观') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.颜色') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.色样号') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.图面编号') }}</td>
              <td colspan="2" class="tdd">{{ $t('FQC.版本') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.装箱数量') }}</td>
          </tr>
          <tr>
            <td colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Lot_no" ></el-input></td>
            <td colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Drawing_no" ></el-input></td>
            <td v-show="isdisabled"  colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Appearance" ></el-input></td>
            <td v-show="!isdisabled" colspan="2" class="tdd">
              <el-select v-model="form.Appearance" :placeholder="$t('All.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
              </el-select>
            </td>
            <td v-show="isdisabled"  colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Colour" ></el-input></td>
            <td v-show="!isdisabled" colspan="2" class="tdd">
              <el-select v-model="form.Colour" allow-create filterable :placeholder="$t('All.请选择')">
                  <el-option :label="$t('All.黑')" :value="$t('All.黑')"></el-option>
                  <el-option :label="$t('All.白')" :value="$t('All.白')"></el-option>
                  <el-option :label="$t('All.橙')" :value="$t('All.橙')"></el-option>
                  <el-option :label="$t('All.黄')" :value="$t('All.黄')"></el-option>
                  <el-option :label="$t('All.绿')" :value="$t('All.绿')"></el-option>
                  <el-option :label="$t('All.青')" :value="$t('All.青')"></el-option>
                  <el-option :label="$t('All.蓝')" :value="$t('All.蓝')"></el-option>
                  <el-option :label="$t('All.紫')" :value="$t('All.紫')"></el-option>
              </el-select>
            </td>
            <td colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Color_sample_no" ></el-input></td>
            <td colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Sopnumber" ></el-input></td>
            <td colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Edition" ></el-input></td>
            <td colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="form.Packing_quantity" ></el-input></td>
          </tr>
          <tr>
            <td class="tdd">{{ $t('All.量测仪器') }}/{{ $t('All.编号') }}</td>
            <td colspan="8"><el-input :disabled = 'isdisabled' v-model="form.Measuring_instrument_no"></el-input></td>
            <td class="tdd">{{ $t('All.线位') }}</td>
            <td colspan="6"><el-input :disabled = 'isdisabled' v-model="form.Line_position"></el-input></td>
          </tr>
          <tr>
              <td colspan="15"><span style="letter-spacing: 56px;margin-left: 56px;text-align: center;">{{ $t('All.不良品记录') }}</span></td>
              <td colspan="1">
                <el-button style="margin-left: 11px;" size="mini" v-show="Nljlisool" type="primary" @click="Nljlisool = false">{{$t('All.收起')}}</el-button>
                <el-button style="margin-left: 11px;" size="mini" v-show="!Nljlisool" type="primary" @click="Nljlisool = true">{{$t('All.展开')}}</el-button>
              </td>
          </tr>
      </table>
       <table v-if="Nljlisool" border="1" style="width:100%;border-bottom: none;" align="center" cellpadding="10"  cellspacing="0">
        <tr>
              <td colspan="2" class="tdd">{{$t('All.排序')}}</td>
              <td colspan="2" class="tdd">{{$t('All.日期')}}</td>
              <td colspan="2" class="tdd">{{$t('All.异常类型')}}</td>
              <td colspan="2" class="tdd">{{$t('All.班别')}}</td>
              <td colspan="2" class="tdd">{{$t('All.工站名称')}}</td>
              <td colspan="2" class="tdd">{{$t('All.处理方式')}}</td>
              <td colspan="2" class="tdd">{{$t('All.状态')}}</td>
              <td colspan="1" class="tdd">{{ $t('All.查看') }}</td>
        </tr>
        <tr  v-for="(item, index) in buliandata" :key="index">
          <td colspan="2" class="tdd">{{index + 1}}</td>
          <td colspan="2" class="tdd">{{item.Date}}</td>
          <td colspan="2" class="tdd">{{item.Exceptiontype}}</td>
          <td colspan="2" class="tdd">{{item.Class}}</td>
          <td colspan="2" class="tdd">{{item.Processname}}</td>
          <td colspan="2" class="tdd">{{item.Statestr}}</td>
          <td colspan="2" class="tdd"> {{item.State == 0? this.$t('All.不良发现'):item.State == 2 ? this.$t('All.重工中') : this.$t('All.完结')}} </td>
          <td colspan="1"><el-button type="primary" @click="setleipqcexceptiontdatalist(item.Qrcode)">{{$t('All.查看')}}</el-button></td>
        </tr>
      </table>
      <table border="1" style="width:100%;border-bottom: none;" align="center" cellpadding="10"  cellspacing="0">
        <tr>
              <td colspan="19"><span style="letter-spacing: 56px;margin-left: 56px;text-align: center;">{{ $t('All.物料追溯') }}</span></td>
              <td colspan="1">
                <el-button style="margin-left: 11px;" size="mini" v-show="Materialisool" type="primary" @click="Materialisool = false">{{$t('All.收起')}}</el-button>
                <el-button style="margin-left: 11px;" size="mini" v-show="!Materialisool" type="primary" @click="Materialisool = true">{{$t('All.展开')}}</el-button>
              </td>
          </tr>
      </table>
      <table v-if="Materialisool" border="1" style="width:100%;border-bottom: none;" align="center" cellpadding="10"  cellspacing="0">
        <tr>
          <td colspan="2" class="tdd">{{ $t('All.IQC系统单号') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.材料名称') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.供应商') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.进料日期') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.负责人') }}</td>
              <td colspan="2" class="tdd">{{ $t('All.领用数量') }}</td>
              <td colspan="2" class="tdd">LOT NO.</td>
              <td colspan="2" class="tdd">{{ $t('All.QC确认') }}</td>
        </tr>
        <tr  v-for="(item, index) in form.Material" :key="item.Id">
          <td colspan="2" class="tdd"><el-input disabled v-model="item.IQCNumbers" ></el-input></td>
          <td colspan="2" class="tdd"><el-input disabled v-model="item.Material_name" ></el-input></td>
          <td colspan="2" class="tdd"><el-input disabled v-model="item.Supplier" ></el-input></td>
          <td colspan="2" class="tdd"><el-input disabled v-model="item.Feeding_date" ></el-input></td>
          <td colspan="2" class="tdd"><el-input disabled v-model="item.Person" ></el-input></td>
          <td colspan="2" class="tdd"><el-input disabled v-model="item.Quantity" ></el-input></td>
          <td colspan="2" class="tdd"><el-input disabled v-model="item.Lot_no" ></el-input></td>
          <td v-show="isdisabled"  colspan="2" class="tdd"><el-input :disabled = 'isdisabled' v-model="item.Name" ></el-input></td>
          <td v-show="!isdisabled" colspan="2" class="tdd">
            <el-select v-model="item.Name" :disabled="item.State == 1" :placeholder="$t('All.请选择')" @change="Materiala(item,index,$event)">
                  <el-option :label="$t('All.删除')" :value="$t('All.删除')"></el-option>
              </el-select>
          </td>
        </tr>
      </table>
      <table border="1" style="width:100%;border-bottom: none;" align="center" cellpadding="10"  cellspacing="0">
        <td colspan="17"><span style="letter-spacing: 56px;margin-left: 56px;text-align: center;">{{ $t('All.巡检记录') }}</span></td>
      </table>
      <!---->
      <formtr :data="form.Patrol_records"
              :isdisabled="isdisabled"
              :addDomains="addDomains"
              :removeDomain="removeDomain"
              :addlist="addlist"
              :form="form"
      ></formtr>
      <!---->
      <!---->
      <table border="1" style="width:100%;border-bottom: none;" align="center" cellpadding="10"  cellspacing="0">
        <tr>
            <td class="tdd" style="width: 50px;">{{$t('All.备注')}} </td>
            <td colspan="3" class="tdd" v-show="!isdisabled"><el-input class="tdd" style="word-wrap:break-word;text-align: left;" :rows="3" type="textarea" v-model="form.Beizhu"></el-input></td>
            <td colspan="3" class="tdd" v-show="isdisabled" style="text-align: left;line-height: 17px;"><span v-html="form.Beizhu"></span></td>
          </tr>
          <tr v-for="(item, index) in form.Beilist" :key="index">
            <td class="tdd" style="max-width: 50px;">{{$t('All.备注')}} {{ index }}</td>
            <td class="tdd" v-show="!isdisabled"><textarea class="el-input__inner" style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1" v-model.lazy="item.Title" ></textarea></td>
            <td class="tdd" v-show="isdisabled" style="text-align: left;"><span>{{ item.Title.length === 0 ?item.Title + item.Txte:item.Title + '：' + item.Txte}}</span></td>
            <td class="tdd" v-show="!isdisabled" style="word-wrap:break-word;text-align: left;"><textarea :disabled = 'isdisabled' class="el-input__inner" style="display: block;resize: none;border-radius: unset;font-size: 14px;" rows="1" v-model.lazy="item.Txte" ></textarea></td>
            <td class="tdd" style="width: 120px;" v-if="!isdisabled">
              <el-button size="mini" type="primary"  @click="addbeizhulist(item, index)" >Add</el-button>
              <el-button size="mini" type="danger" v-show="form.Beilist.length > 1"  @click="removebeizhulist(item, index)" >Del</el-button>
            </td>
          </tr>
      </table>
      <table border="1" style="width:100%;" align="center" cellpadding="10"  cellspacing="0">
          <tr>
            <td class="tdd" style="width: 50px;">{{$t('All.备注')}} </td>
            <td class="tdd" style="word-wrap:break-word;text-align: left;">1.{{ this.$t('All.固定备注')}}(5PCS)</td>
          </tr>
      </table>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.核准')}}: {{form.Auditor}}</span></el-col>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.检验员')}}: {{form.Examination}}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><h6></h6></el-col>
        <el-col :span="12" style="border: 0px solid transparent;padding-right: 20px;text-align: right;"><span style="font-size: 1.5vw;">NYS2401-02 B</span></el-col>
      </el-row>
    </div>
  </div>
  <el-dialog
    :title="this.$t('system.fjian')"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose" append-to-body>
      <fqcUpload :fqcadmin = "form.Number" :moban="form.Numbers" :isdata="isdisabled"></fqcUpload>
    </el-dialog>
    <transition name="fade-transform" mode="out-in">
        <component :is="TabComponent" :Iscoder="Iscoder" :Getxwuliao="Getxwuliao" :Materialadddata="Materialadddata" :Numbers="form.Numbers" :Factory="form.Factory" :gongdan="form.Order_no" :Getipqcexceptiontdatalist="Getipqcexceptiontdatalist" style="z-index: 888;"></component>
    </transition>
    <el-drawer
         :title="$t('All.异常处理过程')"
         :visible.sync="dialog"
         direction="rtl"
         size="40%" append-to-body>
            <div style="padding: 10px;">
                <el-timeline>
                    <el-timeline-item v-for="(item,index) in gridData" :key="index" :timestamp="item.Date" placement="top">
                        <el-card>
                            <h4>{{ item.Statestr }}</h4>
                            <h4 v-if="item.Size">{{$t('All.尺寸')}}：{{ item.Size }}</h4>
                            <span>{{ item.Describe }}</span>
                            <p>{{item.Processname}}</p>
                            <p>{{item.Username}}{{$t('All.提交于')}}{{ item.Date }}</p>
                        </el-card>
                    </el-timeline-item>
                </el-timeline>
                <el-form :model="buliangform" v-if="buliangformbool">
                <el-form-item :label="$t('All.确认方式')">
                  <el-select v-model="buliangform.Statestr" :placeholder="$t('All.请选择')">
                    <el-option :label="$t('All.认可')" :value="$t('All.认可')"></el-option>
                    <el-option :label="$t('All.驳回')" :value="$t('All.驳回')"></el-option>
                  </el-select>
                </el-form-item>
                <el-form-item :label="$t('All.描述分析')" >
                  <el-input v-model="buliangform.Describe" type="textarea" autocomplete="off"></el-input>
                </el-form-item>
              </el-form>
              <div class="demo-drawer__footer" v-if="buliangformbool">
                <el-button type="primary" @click="tijiaobuliang()" >{{$t('All.提交')}}</el-button>
                <el-button @click="dialog = false">{{$t('All.取消')}}</el-button>
              </div>
            </div>
    </el-drawer>
    <el-form style="max-width: 800px;margin: auto;" size="medium" label-width="90px"
      label-position="top">
    <el-form-item :label="$t('All.签核记录')">
        <el-radio-group  style="padding-bottom: 20px;padding-left: 5%;" v-model="reverse">
          <el-radio :label="true">{{ $t('All.倒序') }}</el-radio>
          <el-radio :label="false">{{ $t('All.正序') }}</el-radio>
        </el-radio-group>
        <el-timeline :reverse="reverse">
          <el-timeline-item
            v-for="(activity, index) in Signlog"
            :key="index"
            :timestamp="activity.Date">
            {{ $PublicJs.State(activity.State)}}
            <p>{{activity.Username}}</p>
            <p>{{activity.Notes}}</p>
          </el-timeline-item>
        </el-timeline>
      </el-form-item>
    </el-form>
    <IPQCqianhe v-if="qianhe" :Qianhecolse="Qianhecolse" :UserIpqcAdminhek="UserIpqcAdminhek" :UserIpqcAdminboh="UserIpqcAdminboh"/>
  </div>
</template>
<script>
import fqcUpload from '../../components/Ipqc/Upload/IPQCUpload.vue' // /Upload/IPQCUpload.vue
import IPQCqianhe from '../../components/Ipqc/Upload/IPQCqianhe.vue'
import { mapMutations } from 'vuex'
import formtr from '../../components/Ipqc/tdvue.vue'
export default {
  data () {
    return {
      form: {
        Numbers: '',
        Timsdate: '',
        Class: '',
        Order_no: '',
        Customer: '',
        Sopnumber: '',
        Edition: '',
        Xrfnumber: '',
        Icpnumber: '',
        First_article: '',
        Lot_no: '',
        Drawing_no: '',
        Appearance: '',
        Colour: '',
        Color_sample_no: '',
        Test_machine: '',
        Line_position: '',
        Packing_quantity: '',
        Measuring_instrument_no: '',
        Product_name: '',
        Beizhu: '',
        Patrol_records: [
          {
            Numbers: '',
            Index: 0,
            Date1: '',
            Date2: '',
            Patrol: [
              {
                Cid: 0,
                Numbers: '',
                Index: 0,
                Patrol_name: '',
                Specification: '',
                Company: '',
                Value1: '',
                Value2: '',
                Value3: '',
                Value4: '',
                Value5: '',
                Result: '',
                Whether: ''
              }
            ]
          }
        ],
        Beilist: [
          {
            Numbers: '',
            Number: '',
            Index: 0,
            Title: '',
            Txte: ''
          }
        ],
        Examination: '',
        Auditor: '',
        Entered: '',
        Factory: '',
        State: '',
        Material: [{
          Numbers: '',
          Index: 0,
          IQCNumbers: '',
          Quantity: '',
          Material_name: '',
          Supplier: '',
          Feeding_date: '',
          Person: '',
          Lot_no: '',
          Name: '',
          Serialcode: '',
          Item_no: '',
          Type: 0
        }]
      },
      isdisabled: true,
      isqpisboot: false,
      dialogVisible: false,
      TabComponent: '',
      Page: {
        Pageindex: 1,
        PageSize: 1
      },
      TotalCount: 0,
      isjies: false,
      Materialisool: false,
      Nljlisool: false,
      buliandata: [],
      dialog: false,
      gridData: [],
      dialogedit: false,
      buliangform: {
        Statestr: this.$t('All.认可'),
        Describe: ''
      },
      buliangqrcode: '',
      buliangformbool: false,
      qianhe: false,
      Signlog: [],
      reverse: true
    }
  },
  props: ['ipqcdata'],
  created () {
    console.log(this.ipqcdata)
    if (this.ipqcdata === null) {
      this.$message.error(this.$t('All.模版不存在'))
    } else {
      this.form = this.ipqcdata
      this.GetMaterial(this.form.Order_no)
      this.Getipqcexceptiontdata(this.form.Order_no, this.form.Factory)
    }
  },
  components: {
    formtr,
    fqcUpload,
    IPQCqianhe
  },
  mounted () {
  },
  methods: {
    Qianhecolse () {
      this.qianhe = false
    },
    ...mapMutations(['UserData']),
    Iscoder (row) {
      this.TabComponent = row
    },
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    getCurrentTime () {
      // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const gettime = yy + this.$t('All.年') + mm + this.$t('All.月') + dd + this.$t('All.日')
      return gettime
    },
    State (row) {
      var state
      switch (row) {
        case 0:
          state = this.$t('login.pending')
          break
        case 1:
          state = this.$t('system.bohui')
          break
        case 2:
          state = this.$t('system.wanjie')
          break
        case 3:
          state = this.$t('system.zancun')
          break
        case 4:
          state = this.$t('system.tuihuo')
          break
        case 5:
          state = this.$t('system.tecai')
          break
        default:
          state = this.$t('All.异常')
          break
      }
      return state
    },
    addbeizhulist (item, index) {
      const contr = {
        Number: item.Number,
        Numbers: '',
        Index: item.Index + 1,
        Title: '',
        Txte: ''
      }
      this.form.Beilist.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Beilist.length; i++) {
        this.form.Beilist[i].Index = i
      }
    },
    removebeizhulist (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          var index = this.form.Beilist.indexOf(item)
          if (index !== -1) {
            this.form.Beilist.splice(index, 1)
          }
          for (let i = 0; i < this.form.Beilist.length; i++) {
            this.form.Beilist[i].Index = i
          }
        })
        .catch(() => {
        })
    },
    addDomains (item, index) {
      const contr = {
        Number: item.Number,
        Numbers: item.Numbers,
        Index: item.Index + 1,
        Date1: '',
        Date2: '',
        Ispiece: 0,
        Isbool: 1,
        Patrol: JSON.parse(JSON.stringify(item.Patrol))
      }
      contr.Patrol.forEach(element => {
        element.Cid = contr.Index
        element.Value1 = ''
        element.Value2 = ''
        element.Value3 = ''
        element.Value4 = ''
        element.Value5 = ''
        element.Result = ''
        element.Whether = ''
      })
      this.form.Patrol_records.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Patrol_records.length; i++) {
        this.form.Patrol_records[i].Index = i
        this.form.Patrol_records[i].Patrol.forEach(element => {
          element.Cid = this.form.Patrol_records[i].Index
        })
      }
    },
    removeDomain (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          var index = this.form.Patrol_records.indexOf(item)
          if (index !== -1) {
            this.form.Patrol_records.splice(index, 1)
          }
          for (let i = 0; i < this.form.Patrol_records.length; i++) {
            this.form.Patrol_records[i].Index = i
            this.form.Patrol_records[i].Patrol.forEach(element => {
              element.Cid = i
            })
          }
        })
        .catch(() => {
        })
    },
    addlist (indexx, item, index, value) {
      if (value === this.$t('All.增加')) {
        this.addDomainlist(indexx, item, index)
        this.form.Patrol_records[indexx].Patrol[index].Whether = ''
        return
      }
      if (value === this.$t('All.删除')) {
        this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
          confirmButtonText: this.$t('All.确认'),
          cancelButtonText: this.$t('All.取消'),
          type: 'warning'
        })
          .then(() => {
            this.removeDomainlist(item, indexx)
          })
          .catch(() => {
            this.form.Patrol_records[indexx].Patrol[index].Whether = ''
          })
      }
    },
    addDomainlist (indexx, item, index) {
      const contr = {
        Number: item.Number,
        Numbers: item.Numbers,
        Cid: item.Cid,
        Index: 1,
        Patrol_name: item.Patrol_name,
        Specification: item.Specification,
        Company: item.Company,
        Value1: '',
        Value2: '',
        Value3: '',
        Value4: '',
        Value5: '',
        Result: '',
        Whether: ''
      }
      this.form.Patrol_records[indexx].Patrol.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.Patrol_records[indexx].Patrol.length; i++) {
        this.form.Patrol_records[indexx].Patrol[i].Index = i
      }
    },
    removeDomainlist (item, indexx) {
      var index = this.form.Patrol_records[indexx].Patrol.indexOf(item)
      if (index !== -1) {
        this.form.Patrol_records[indexx].Patrol.splice(index, 1)
      }
      for (let i = 0; i < this.form.Patrol_records[indexx].Patrol.length; i++) {
        this.form.Patrol_records[indexx].Patrol[i].Index = i
      }
    },
    async UserIpqcAdminboh (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const ipqcformpost = {
          UserData: this.$store.state.Login,
          IpqcUser: this.form,
          Qianheclass: row
        }
        const { data: res } = await this.$http.post('/api/Ipqc/UserIpqcAdminboh', ipqcformpost)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.qianhe = false
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async UserIpqcAdminhek (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const ipqcformpost = {
          UserData: this.$store.state.Login,
          IpqcUser: this.form,
          Qianheclass: row
        }
        const { data: res } = await this.$http.post('/api/Ipqc/UserIpqcAdminhek', ipqcformpost)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.qianhe = false
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    Materiala (item, index, value) {
      if (value === this.$t('All.增加')) {
        this.Materialaddlist(item, index)
        this.Material[index].Name = ''
        return
      }
      if (value === this.$t('All.删除')) {
        this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
          confirmButtonText: this.$t('All.确认'),
          cancelButtonText: this.$t('All.取消'),
          type: 'warning'
        })
          .then(() => {
            this.removeMaterial(item)
          })
          .catch(() => {
            this.Material[index].Name = ''
          })
      }
    },
    Materialaddlist (item, index) {
      const contr = {
        Numbers: item.Numbers,
        Index: item.Index + 1,
        Quantity: '',
        IQCNumbers: '',
        Material_name: '',
        Supplier: '',
        Feeding_date: '',
        Person: '',
        Lot_no: '',
        Name: '',
        Serialcode: ''
      }
      this.Material.splice(index + 1, 0, contr)
      for (let i = 0; i < this.Material.length; i++) {
        this.Material[i].Index = i
      }
    },
    async removeMaterial (item) {
      const { data: res } = await this.$http.post('/api/Semifinished/DelMaterial', item)
      if (res.status !== 200) return this.$message.error(res.msg)
      var index = this.Material.indexOf(item)
      if (index !== -1) {
        this.Material.splice(index, 1)
      }
      for (let i = 0; i < this.Material.length; i++) {
        this.Material[i].Index = i
      }
    },
    Getxwuliao (row) {
      for (let j = 0; j < this.Material.length; j++) {
        if (this.Material[j].Serialcode === row) {
          this.$message.error(this.$t('All.此PASS数据已被采集'), this.$t('All.请勿重复扫描'))
          return true
        }
      }
      return false
    },
    Materialadddata (row) {
      /* for (let j = 0; j < this.Material.length; j++) {
        if (this.Material[j].Serialcode === row.Serialcode) {
          this.$message.error('此PASS数据已被采集，请勿重复扫描！')
          return 'NO'
        }
      }
      var res = this.$http.post('/api/Semifinished/AddMaterial', row)
      console.log(res)
      if (res.status !== 200) return this.$message.error(res.msg)
      row.Index = this.Material.length
      this.Material.push(row)
      this.$message.success('采集成功！')
      return 'OK' */
      for (let j = 0; j < this.Material.length; j++) {
        if (this.Material[j].Serialcode === row.Serialcode) {
          this.$message.error(this.$t('All.此PASS数据已被采集'), this.$t('All.请勿重复扫描'))
          return
        }
      }

      // 发起异步请求
      this.$http.post('/api/Semifinished/AddMaterial', row)
        .then((response) => {
          // 检查HTTP响应状态码
          if (response.status === 200) {
            const res = response.data

            // 处理服务器端返回的数据
            if (res.status === 200) {
              row.Index = this.Material.length
              this.Material.push(row)
              this.$message.success(this.$t('All.采集成功'))
            } else {
              this.$message.error(res.msg)
            }
          } else {
            this.$message.error(this.$t('All.HTTP请求失败'))
          }
        })
        .catch((error) => {
          console.error(error)
          this.$message.error(this.$t('All.操作失败'))
        })
    },
    async GetMaterial (row) {
      const { data: res } = await this.$http.get(`/api/Semifinished/GetMaterial?gongdan=${row}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.Material = res.response
    },
    async Getipqcexceptiontdata (row, row1) {
      const { data: res } = await this.$http.get(`/api/IPQC/Getipqcexceptiontdata?gongdan=${row}&chagnqu=${this.form.Factory}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.buliandata = res.response
    },
    async Getipqcexceptiontdatalist (row, row1) {
      this.buliangqrcode = row
      const { data: res } = await this.$http.get(`/api/IPQC/Getipqcexceptiontdatalist?qrcode=${row}&chagnqu=${this.form.Factory}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.gridData = res.response
      this.dialog = true
      this.buliangformbool = true
      this.TabComponent = ''
    },
    async tijiaobuliang () {
      const data = {
        qrcode: this.buliangqrcode,
        Statestr: this.buliangform.Statestr,
        Describe: this.buliangform.Describe,
        Text: '',
        Type: this.buliangform.Statestr === this.$t('All.认可') ? 2 : 1,
        Mac: this.$t('All.IPQC设备'),
        Processname: this.$t('All.品管部门IPQC设备'),
        User: this.$store.state.Login.User,
        Username: this.$store.state.Login.Username,
        Factory: this.form.Factory
      }
      const { data: res } = await this.$http.post('/api/IPQC/Addipqcexceptiontdatalist', data)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.dialog = false
      this.buliangformbool = false
      this.Getipqcexceptiontdata(this.form.Order_no, this.form.Factory)
    },
    async setleipqcexceptiontdatalist (row) {
      this.buliangqrcode = row
      const { data: res } = await this.$http.get(`/api/IPQC/Getipqcexceptiontdatalist?qrcode=${row}&chagnqu=${this.form.Factory}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.gridData = res.response
      this.dialog = true
      this.buliangformbool = false
    },
    async FQCSignloglist (row) {
      try {
        const { data: res } = await this.$http.post('/api/FQC/FQCSignloglist?Numbers=' + row)
        if (res.status !== 200) return
        this.Signlog = res.response
      } catch (error) {
        alert(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
.wbobye {
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  position: fixed;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
  min-width: 903px;
}

.el-col {
  border: 1px solid;
  margin-right: -1px;
  margin-bottom: -1px;
  line-height: 4.5vw;
  font-size: 2vw;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.grid-content {
  min-height: 36px;
}

.row-bg {
  background-color: #f9fafc;
}

.minclass {
  min-width: 900px !important;
}
</style>
