<template>
  <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd'">
    <div style="position: fixed;top: 55px;right: 10px; z-index: 1000;">
      <el-button size="small" style="text-align: right;"  type="primary" @click="isqpisboot = !isqpisboot">{{isqpisboot ? $t('All.取消全屏'): $t('All.全屏')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="!isdata"  type="primary" @click="ZhAdminAdd(form)">{{ $t('All.提交') }}</el-button>
      <el-button size="small" style="text-align: right;" v-if="isdata"  type="primary" @click="ZhAdminDataUp(form)">{{$t('All.修改')}}</el-button>
      <el-button size="small" style="text-align: right;" v-if="isdata"  type="danger" @click="Delete()">Delete</el-button>
    </div>
    <h2 style="text-align: center;">{{$t('All.年益实业股份有限公司') }}</h2>
    <h3 style="text-align: center;">{{$t('All.成品抽检') }}（{{$t('All.包装') }}）</h3>
    <h4 style="text-align: center;">{{$t('All.录入人') }}：{{this.$store.state.Login.Username}}</h4>
    <div style=" padding: 10px; text-align: center; line-height: 2vw;">
      <table border="1"  style="width:100%;" align="center" cellpadding="10"  cellspacing="0">
          <tr>
              <td style="min-width:50px">{{$t('All.制造班别') }}</td>
              <td>{{$t('All.客户') }}</td>
              <td>{{$t('All.品名') }}</td>
              <td>{{$t('All.三位吗') }}</td>
              <td>{{$t('All.图面编号') }}</td>
              <td>{{$t('All.入库箱数') }}</td>
              <td colspan="2">{{$t('All.检验时间') }}</td>
          </tr>
          <tr>
              <td class="tdd">{{form.Manufacturing}}</td>
              <td class="tdd"><el-input style="width:100%" v-model="form.Customer"></el-input></td>
              <td class="tdd"><el-input style="width:100%" v-model="form.Productname"></el-input></td>
              <td class="tdd"><el-input v-model="form.Three"></el-input></td>
              <td class="tdd"><el-input v-model="form.Drawingnumber"></el-input></td>
              <td class="tdd"><el-input v-model="form.Number"></el-input></td>
              <td class="tdd" colspan="2"></td>
          </tr>
          <tr>
              <td >{{$t('All.抽箱基准') }}</td>
              <td  class="tdd" colspan="3" ><el-input v-model="form.Benchmark"></el-input></td>
              <td>{{$t('All.参考文件') }}</td>
              <td  class="tdd"><el-input v-model="form.Reference"></el-input></td>
              <td>{{$t('All.判定') }}</td>
              <td style="min-width:50px" class="tdd"></td>
          </tr>
      </table>
      <div class="block admin">
         <h3 style="text-align: center;">{{$t('All.参考图面') }}</h3>
        <el-upload
            :action="`${$Upload}/api/Zh/Imgadmin/`"
            list-type="picture-card"
            :on-preview="handlePictureCardPreview"
            :on-remove="handleRemove"
            :data="filedata"
            :file-list="fileList"
            :on-success="handsuccess"
            >
            <i class="el-icon-plus"></i>
          </el-upload>
          <el-dialog :visible.sync="dialogVisible" append-to-body>
            <img width="100%" :src="dialogImageUrl" alt="">
          </el-dialog>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      form: {
        Manufacturing: '', // 制造班别
        Customer: '', // 客户
        Productname: '', // 品名
        Three: '', // 三位吗
        Drawingnumber: '', // 图面编号
        Number: '', // 入库箱数
        Benchmark: '', // 抽箱基准
        Reference: '', // 参考文件
        Determine: '', // 判定
        Factory: this.$store.state.Login.Factory,
        MBNumbers: '',
        Enteredby: this.$store.state.Login.Username // 录入姓名
      },
      dialogVisible: false,
      dialogImageUrl: '',
      filedata: {
        Workorder: ''
      },
      fileList: [],
      isdata: false,
      isqpisboot: false
    }
  },
  created () {
    this.form.Productname = this.$store.state.Productname
    if (this.$store.state.UserData === null) {
      this.QcCount()
      this.isdata = false
      this.form.Enteredby = this.$store.state.Login.Username
    } else {
      this.isdata = true
      this.form = this.$store.state.UserData
      this.filedata.Workorder = this.form.MBNumbers
      this.GetAllFiles(this.form.MBNumbers)
    }
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData']),
    ...mapMutations(['Productname']),
    async handleRemove (file, fileList) {
      try {
        const { data: res } = await this.$http.post('/api/Zh/ImgDel', file)
        if (res.status !== 200) {
          return this.$message.error(this.$t('All.数据异常'))
        }
        for (let index = 0; index < this.fileList.length; index++) {
          if (this.fileList[index].name === file.name) {
            this.fileList.splice(index, 1)
            return this.$message.success(this.$t('All.删除成功'))
          }
        }
        this.$message.success(this.$t('All.删除成功'))
      } catch (error) {
        this.$message.error(error)
      }
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    handsuccess (response) {
      const data = {
        name: '',
        url: ''
      }
      if (response.status !== 200) return this.$message.error(this.$t('All.图片上传失败'))
      data.name = response.name
      data.url = response.url
      this.fileList.push(data)
    },
    async ZhAdminAdd (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Zh/ZhAdminAdd', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.提交成功'))
        this.UserData(null)
        this.Productname(null)
        this.$store.state.TabComponent = 'OQCSeleItemAdmin'
      } catch (error) {
        loadingInstance.close()
        alert(error)
      }
    },
    async ZhAdminDataUp (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Zh/ZhAdminDataUp', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.修改成功'))
        this.UserData(null)
        this.Productname(null)
        this.$store.state.TabComponent = 'OQCSeleItemAdmin'
      } catch (error) {
        loadingInstance.close()
        alert(error)
      }
    },
    async GetAllFiles (row) {
      try {
        const { data: res } = await this.$http.post('/api/Zh/GetAllFiles?Workorder=' + row)
        if (res.status === 201) return
        if (res.status !== 200) return this.$message.error(this.$t('All.数据异常'))
        for (let index = 0; index < res.response.length; index++) {
          this.fileList.push(res.response[index])
        }
      } catch (error) {
        alert(error)
      }
    },
    async Delete () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Zh/OQCAdminDataDel', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.删除成功'))
        this.$store.state.TabComponent = 'OQCAdminData'
      } catch (error) {
        this.$message.error(this.$t('All.服务器断开'))
        loadingInstance.close()
      }
    },
    async QcCount () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const QcCountLogin = {
          Login: this.$store.state.Login,
          Type: 'OQC'
        }
        const { data: res } = await this.$http.post('/api/User/QcCount', QcCountLogin)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        loadingInstance.close()
        this.form.MBNumbers = res.response
        this.filedata.Workorder = res.response
        this.GetAllFiles(this.form.MBNumbers)
      } catch (error) {
        loadingInstance.close()
        alert(this.$t('All.网络异常'))
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
}
  .el-col {
    border: 1px solid;
    margin-right: -1px;
     margin-bottom: -1px;
    line-height: 4.5vw;
    font-size: 2vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .grid-content {
    min-height: 36px;
  }
  .bg-purple-light{

  }
  .row-bg {
    background-color: #f9fafc;
  }
  .admin {
  border: 1px solid grey;
  border-top: 0px;
  padding-top: 15px;
  padding-left: 15px;
  text-align: left;
}
</style>
