<template>
  <div class="wboby">
      <div style="position: fixed;top: 5px;right: 10px;">
        <el-button type="danger" @click="$PublicJs.Eixt()">{{this.$t('system.exit')}}</el-button>
        <el-select style="margin-left: 10px; float: right; width: 100px;" v-model="value" @change="$PublicJs.languageXZ(value)" :placeholder="this.$t('login.placeholder')">
              <el-option v-for="item in $PublicJs.language" :key="item.value" :label="item.label" :value="item.value">
              </el-option>
      </el-select>
      </div>
    <div class="neiboby">
      <el-row :gutter="20" style="margin-top: 10%;">
        <el-col :span="6"><div @click="FQC" class="grid-content">FQC</div></el-col>
        <el-col :span="6"><div @click="OQC" class="grid-content">OQC</div></el-col>
        <el-col :span="6"><div class="grid-content">IPQC</div></el-col>
        <el-col :span="6"><div @click="IQC" class="grid-content">IQC</div></el-col>
      </el-row>
      <el-row :gutter="20" style="margin-top: 10%;">
        <el-col :span="6"><div @click="Query" class="grid-content">{{this.$t('selefun.charge')}}</div></el-col>
        <el-col :span="6"><div @click="Examine" class="grid-content">{{this.$t('selefun.pending')}}</div></el-col>
        <el-col :span="6"><div @click="SeleFunInput" class="grid-content">{{this.$t('selefun.datainput')}}</div></el-col>
        <el-col :span="6"><div @click="UserAdmin" class="grid-content">{{this.$t('selefun.personnel')}}</div></el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      User: [],
      value: localStorage.getItem('locale') || ''
    }
  },
  created () {
    this.User = this.$store.state.Login
  },
  mounted () {

  },
  methods: {
    FQC () {
      this.$router.push('/FQCSeleItem')
    },
    OQC () {
      this.$router.push('/OQCSeleItem')
    },
    IQC () {
      this.$router.push('/IQCSeleItem')
    },
    Examine () {
      this.$router.push('/Examine')
    },
    UserAdmin () {
      this.$router.push('/UserAdmin')
    },
    SeleFunInput () {
      this.$router.push('/SeleFunInput')
    },
    Query () {
      this.$router.push('/Query')
    }
  }
}
</script>
<style lang="less" scoped>
  .wboby{
    position:absolute/fixed;
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin:auto;
    position: absolute;
    width: 80%;
  }
  .grid-content {
    margin: 0 auto;
    left:0;
    right:0;
    top:0;
    bottom:0;
    font-size: 2vw;
    color: #0a3596;
    text-align: center;
    line-height: 600%;
    width: 12vw;
    height: 12vw;
    border-radius: 100%;
    padding: 10px;
    background-color: rgba(239, 243, 247, 0.40);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    cursor:default;
  }
  .grid-content:hover{
    margin: 0 auto;
    left:0;
    right:0;
    top:0;
    bottom:0;
    font-size: 2.1vw;
    color: #0a3596;
    text-align: center;
    line-height: 600%;
    width: 12vw;
    height: 12vw;
    border-radius: 100%;
    padding: 10px;
    background-color: rgba(6, 130, 255, 0.616);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    cursor:default;
  }
  .exit {
    color:#FFF;
    border: 1px solid rgba(249, 0, 0, 0.35);
    background-color: rgba(249, 0, 0, 0.35);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .exit:hover{
    color:#FFF;
    border: 1px solid rgba(249, 0, 0, 0.548);
    background-color: rgba(249, 0, 0, 0.548);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
</style>
