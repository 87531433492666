<template>
  <div class="Examine">
    <div style="position: fixed;top: 55px;right: 10px;">
      <el-button size="small" @click="dataadd()" type="primary">{{this.$t('system.新增')}}</el-button>
      <el-button size="small" @click="$router.push('/IQCAdminDataFqc')" type="primary">{{$t('All.腾耀列表')}}{{this.$t('system.新增')}}</el-button>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" :label="this.$t('system.序号')" type="index" width="60">
          </el-table-column>
          <el-table-column align="center" prop="Date" :label="this.$t('system.创建日期')" width="180">
          </el-table-column>
          <el-table-column align="center" prop="Product_name" :label="this.$t('system.品名规格')">
          </el-table-column>
          <el-table-column align="center" prop="Enteredby" :label="this.$t('system.lururen')" >
          </el-table-column>
          <el-table-column align="center" :label="this.$t('system.操作')" width="100" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="AdminDataSelect(scope.row.Product_name)" type="primary">Review</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      value: localStorage.getItem('locale') || ''
    }
  },
  created () {
    this.IQCAdminData()
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData']),
    ...mapMutations(['Productname']),
    async IQCAdminData () {
      const fqcdata = {
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login
      }
      const { data: res } = await this.$http.post('/api/IQC/IQCAdminData', fqcdata)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.tableData = res.response.ZhFqc
    },
    handleSizeChange (val) {
      this.page_size = val
      this.IQCAdminData()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.IQCAdminData()
    },
    async AdminDataSelect (row) {
      const postdata = {
        Name: row,
        Factory: this.$store.state.Login.Factory
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/IQC/IQCDataSelectadmin', postdata)
        if (res.status !== 200) {
          loadingInstance.close()
          this.UserData(null)
          this.$message.success(this.$t('All.数据不存在'))
          return
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.即将进入编辑页面'))
        this.Productname(this.Noitem)
        this.UserData(res.response)
        this.$store.state.TabComponent = 'IQCAdmin'
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    },
    dataadd () {
      this.$store.state.TabComponent = 'IQCSeleItemadmin'
    }
  }
}
</script>
<style lang="less" scoped>

</style>
