<template>
  <div>
    <div v-show="!Isbool" style="padding-top: 10px;">
      <el-input size="medium" clearable v-model="Text" style="width: 200px; padding: 0px 10px 0px 10px;" placeholder="料号/品名/简述模糊搜索"></el-input>
      <el-button type="primary" icon="el-icon-search" size="small" @click="GetProduct()">搜索</el-button>
      <el-button type="primary" v-if="Islogin" icon="el-icon-plus" size="small" @click="Isbool = true">新增</el-button>
    </div>
    <el-table  v-show="!Isbool" :data="tableData" style="width: 100%" @row-click="rowclick">
      <el-table-column  type="index" width="50">
      </el-table-column>
      <el-table-column prop="Cdate" label="创建日期" width="150">
      </el-table-column>
      <el-table-column prop="Item" label="料号">
      </el-table-column>
      <el-table-column prop="Productname" label="品名">
      </el-table-column>
      <el-table-column prop="Sketch" label="简述">
      </el-table-column>
    </el-table>
    <el-pagination  v-show="!Isbool"
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      hide-on-single-page>
    </el-pagination>
    <Addproductissues v-if="Isbool" :Close="Close"></Addproductissues>
    <el-dialog
      title="绑定账号"
      :visible.sync="dialogVisible"
      width="100%"
      :before-close="handleClose" append-to-body>
      <transition name="fade-transform" mode="out-in">
        <User :Userid="Userid" :Boollogin="boollogin" :Userbool="Userbool"></User>
      </transition>
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  components: {
    Addproductissues: () => import('../../components/Productissues/Addproductissues.vue'),
    User: () => import('./User.vue')
  },
  props: [],
  data () {
    return {
      tableData: [],
      Text: '',
      total: 0,
      page_size: 10,
      pageindex: 1,
      Isbool: false,
      isCollapse: true,
      value: localStorage.getItem('locale') || 'zh',
      authCode: '',
      code: '',
      state: '',
      Userid: '',
      dialogVisible: false
    }
  },
  computed: {
    Islogin () {
      return this.$store.state.Islogin
    }
  },
  watch: {},
  created () {
    if (this.$store.state.Islogin) {
      this.GetProduct()
    } else {
      try {
        this.GetProduct()
        const url = window.location.href
        console.log(url)
        this.code = url.match(/code=([^&]*)/)[1]
        this.state = url.match(/state=([^&]*)/)[1].split('#')[0]
        this.getStaffInfo()
      } catch (error) {
      }
    }
  },
  mounted () { },
  methods: {
    ...mapMutations(['loginlist', 'Userdata', 'ProductnIsloginfun']),
    handleClose (done) {
      this.$confirm('确认取消绑定？')
        .then(_ => {
          this.dialogVisible = false
        })
        .catch(_ => {})
    },
    Userbool () {
      this.dialogVisible = false
    },
    boollogin () {
      this.ProductnIsloginfun(true)
    },
    ...mapMutations(['TabComponentFun', 'Productnumbersfun']),
    Close () {
      this.Isbool = false
      this.GetProduct()
    },
    async GetProduct () {
      const pgeage = {
        Text: this.Text,
        Pageindex: this.pageindex,
        PageSize: this.page_size
      }
      const { data: res } = await this.$http.post('/api/Product/GetProduct', pgeage)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.tableData = res.response.Product
      this.total = res.response.TotalCount
    },
    async getStaffInfo () {
      const { data: res } = await this.$http.get(`/api/Authenticate/Getwx?code=${this.code}&state=${this.state}`)
      if (res.status !== 200) {
        this.Userid = res.Userid
        this.dialogVisible = true
        return
      }
      this.$message.success(this.$t('login.loginmessageyes'))
      this.loginlist(res.Userdata)
      this.Userdata(res.Userdata)
      window.sessionStorage.setItem('token', res.token)
      this.ProductnIsloginfun(true)
    },
    handleSizeChange (val) {
      this.page_size = val
      this.GetProduct()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.GetProduct()
    },
    rowclick (row) {
      if (row.Item.length > 0) {
        this.$router.push('/Produchuizong/' + row.Item)
      } else {
        this.$router.push('/Details/' + row.Numbers)
      }
    }
  }
}

</script>
<style>
.el-upload__tip {
  line-height: 1.2;
}
</style>
