<template>
  <div :class="isqpisboot ? 'wbobye zhadminadd': 'zhadminadd'">
    <div style="position: fixed;top: 55px;right: 10px; z-index: 1000;">
      <el-button style="text-align: right;"  type="primary" @click="isqpisboot = !isqpisboot">{{isqpisboot ? $t('All.取消全屏'): $t('All.全屏')}}</el-button>
      <el-button type="primary"   @click="IQC_User(form,0)">{{this.$t('system.tijiao')}}</el-button>
      <el-button type="success"   @click="IQC_User(form,3)">{{this.$t('system.zancun')}}</el-button>
      <el-button type="primary"   @click="dialogVisible = true">{{this.$t('system.fjian')}}</el-button>
    </div>
    <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
    <h3 style="text-align: center;">{{this.$t('system.jinliaojy')}}</h3>
    <h4 style="text-align: center;">{{this.$t('system.systemid')}}：{{form.Numbers}}</h4>
    <div style=" padding: 10px; text-align: center; line-height: 2vw;">
      <table border="1"  align="center" cellpadding="10" style="width: 100%;"  cellspacing="0">
          <tr>
              <td style="width: 80px;">{{this.$t('IQC.供应商')}}</td>
              <td style="width: 80px;">{{this.$t('IQC.交货数')}}</td>
              <td style="width: 80px;">{{this.$t('IQC.承认书')}}</td>
              <td colspan="3" style="width: 180px;">{{this.$t('IQC.品名')}}</td>
              <td colspan="2" style="width: 80px;">IQC No.</td>
              <td colspan="2" style="width: 80px;">{{this.$t('IQC.订单号')}}</td>
          </tr>
          <tr>
            <td class="tdd"><el-input v-model="form.Supplier"></el-input></td>
            <td class="tdd"><el-input v-model="form.Delivery"></el-input></td>
            <td class="tdd"><el-input v-model="form.Acknowledgment"></el-input></td>
            <td  colspan="3" class="tdd"><el-input v-model="form.Product_name"></el-input></td>
            <td  colspan="2" class="tdd"><el-input v-model="form.Numbers"></el-input></td>
            <td  colspan="2" class="tdd"><el-input v-model="form.Order_Number"></el-input></td>
          </tr>
          <tr>
            <td >{{this.$t('IQC.入库数')}}</td>
            <td >{{this.$t('IQC.抽样数')}}</td>
            <td >{{this.$t('IQC.工程图面')}}</td>
            <td  colspan="3" >{{this.$t('IQC.料号')}}</td>
            <td  colspan="2" >{{this.$t('IQC.抽样类型')}}</td>
            <td  colspan="2" >{{this.$t('IQC.检验基准表')}}</td>
          </tr>
          <tr>
            <td class="tdd"><el-input v-model="form.Warehousing"></el-input></td>
            <td class="tdd"><el-input v-model="form.Sampling"></el-input></td>
            <td class="tdd"><el-input v-model="form.Drawing_Surface"></el-input></td>
            <td  colspan="3" class="tdd"><el-input v-model="form.Item_No"></el-input></td>
            <td  colspan="2" class="tdd">
                <el-select v-model="form.Sampling_type" :placeholder="this.$t('IQC.请选择类型')">
                  <el-option :label="this.$t('IQC.加严')" :value="this.$t('IQC.加严')"></el-option>
                  <el-option :label="this.$t('IQC.正常')" :value="this.$t('IQC.正常')"></el-option>
                  <el-option :label="this.$t('IQC.减量')" :value="this.$t('IQC.减量')"></el-option>
                </el-select>
            </td>
            <td  colspan="2" class="tdd"><el-input v-model="form.Inspection_Table"></el-input></td>
          </tr>
          <tr>
            <td colspan="10">{{this.$t('IQC.抽样数按AQL')}}</td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.检验项目')}}</td>
            <td colspan="2">{{this.$t('IQC.检验标准')}}</td>
            <td colspan="4">{{this.$t('IQC.检验内容')}}</td>
            <td>{{this.$t('IQC.判定')}}</td>
            <td colspan="2">{{this.$t('IQC.备注')}}</td>
          </tr>
          <tr>
            <td class="tdd" rowspan="4">{{this.$t('IQC.外观')}}</td>
            <td rowspan="4" colspan="2">{{this.$t('IQC.依样品丶承认书')}}</td>
            <td class="tdd" colspan="4"><el-input v-model="form.Colour"><template slot="prepend">{{this.$t('IQC.颜色')}}</template></el-input></td>
            <td>
              <el-select v-model="form.Determine2" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input v-model="form.Remarks2"></el-input></td>
          </tr>
          <tr>
            <td class="tdd" colspan="4"><el-input class="cable_Plug" v-model="form.Plug"><template slot="prepend">{{this.$t('IQC.插头')}}</template></el-input></td>
            <td>
              <el-select v-model="form.Determine11" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input v-model="form.Remarks11"></el-input></td>
          </tr>
          <tr>
            <td class="tdd" colspan="4"><el-input class="cable_mold" v-model="form.Cable_MOLD"><template slot="prepend">CABLE_MOLD</template></el-input></td>
            <td>
              <el-select v-model="form.Determine12" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input v-model="form.Remarks12"></el-input></td>
          </tr>
          <tr>
            <td class="tdd" colspan="4"><el-input class="cable_mold" v-model="form.Shape" :disabled = 'isdisabled'><template slot="prepend">形状</template></el-input></td>
            <td>
              <el-select v-model="form.Determine3" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input v-model="form.Remarks3"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.电器特性')}}</td>
            <td colspan="2">{{this.$t('IQC.承认书丶工程图面')}}</td>
            <td colspan="4"><el-input v-model="form.Text1"></el-input></td>
            <td>
              <el-select v-model="form.Determine4" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input v-model="form.Remarks4"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.试用')}}</td>
            <td colspan="2">{{this.$t('IQC.与配套使用')}}</td>
            <td colspan="4"><el-input v-model="form.Text2"></el-input></td>
            <td>
              <el-select v-model="form.Determine5" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input v-model="form.Remarks5"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.包装')}}</td>
            <td colspan="2">{{this.$t('IQC.依承认书丶标示卡')}}</td>
            <td colspan="4"><el-input v-model="form.Text3"></el-input></td>
            <td>
              <el-select v-model="form.Determine6" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input v-model="form.Remarks6"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.第三方检测报告有效性')}}</td>
            <td colspan="2"></td>
            <td colspan="4"><el-input type="textarea" :rows="5" v-model="form.Text4"></el-input></td>
            <td>
              <el-select v-model="form.Determine7" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input v-model="form.Remarks7"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.厂商出货报告批号')}}</td>
            <td colspan="2"></td>
            <td colspan="4"><el-input v-model="form.Text5"></el-input></td>
            <td>
              <el-select v-model="form.Determine8" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input v-model="form.Remarks8"></el-input></td>
          </tr>
          <tr>
            <td>{{this.$t('IQC.来料实物批号')}}</td>
            <td colspan="2"></td>
            <td colspan="4"><el-input v-model="form.Text6"></el-input></td>
            <td>
              <el-select v-model="form.Determine9" :placeholder="this.$t('IQC.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input v-model="form.Remarks9"></el-input></td>
          </tr>
          <tr  v-if="false">
            <td>{{$t('All.出货报告其它讯息确认')}}</td>
            <td colspan="2"></td>
            <td colspan="4"><el-input v-model="form.Text7"></el-input></td>
            <td>
              <el-select v-model="form.Determine10" :placeholder="$t('All.请判定')">
                  <el-option label="Pass" value="Pass"></el-option>
                  <el-option label="Fail" value="Fail"></el-option>
                  <el-option label="NA" value="NA"></el-option>
              </el-select>
            </td>
            <td colspan="2"><el-input v-model="form.Remarks10"></el-input></td>
          </tr>
          <tr>
              <td class="tdd" rowspan="2">{{this.$t('IQC.检验基准表')}}</td>
              <td class="tdd" colspan="2">{{this.$t('IQC.量测仪器编号')}}</td>
              <td colspan="7" class="tdd"><el-input v-model="form.Yqi1"></el-input></td>
          </tr>
          <tr>
              <td colspan="6" class="tdd" >{{this.$t('IQC.尺寸检验记录')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最大值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最小值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.判定')}}</td>
          </tr>
          <tr v-for="(text, index) in form.Parcss" :key="index">
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text" ></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text1"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text2"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text3"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text4"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text5"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text6"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text7"></el-input></td>
              <td class="tdd" style="min-width: 100px;"><el-input v-model="text.Text8"></el-input></td>
              <td class="tdd" style="min-width: 150px;"><el-input v-model="text.Text9"></el-input></td>
          </tr>
          <tr>
            <td style="text-align: left;" colspan="10" v-html="this.$t('IQC.beizhu')"></td>
          </tr>
      </table>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.核准')}}: {{form.Hz}}</span></el-col>
        <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.检验员')}}: {{form.Jyy}}</span></el-col>
      </el-row>
      <el-row>
        <el-col :span="12" style="border: 0px solid transparent;"><h6></h6></el-col>
        <el-col :span="12" style="border: 0px solid transparent;padding-right: 20px;text-align: right;"><span style="font-size: 1.5vw;">NYS2401-02 B</span></el-col>
      </el-row>
    </div>
    <el-dialog
      :title="$t('All.上传附件')"
      :visible.sync="dialogVisible"
      width="80%"
      :before-close="handleClose" append-to-body>
      <iqcUpload :isdata = "isdata" :fqcnumbers = "form.FQCNumbers" :name= "form.Product_name"></iqcUpload>
    </el-dialog>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
import iqcUpload from './Upload/IQCUpload'
export default {
  data () {
    return {
      form: {
        Date: '', // 创建时间
        Enteredby: '', // 录入人
        Hz: '', // 核准人
        Jyy: '', // 检验员
        Ycms: '', // 异常描述
        Beizhu: '', // 1.量测的尺寸规格按抽样数10%的比例记录；当记录的尺寸规格个数大于10组数据时，则记录前10pcs样本的量测结果值。
        Delivery: '', // 交货数
        Warehousing: '', // 入库数
        Sampling: '', // 抽样数
        Product_name: '', // 品名规格
        Standard1: '', // AQL标准1
        Standard2: '', //  参考文件
        Supplier: '', //  供应商
        Acknowledgment: '', // 承认书
        Inspection_No: '', //  检验单号
        Order_Number: '', //  订单号
        Drawing_Surface: '', //  工程图面
        Item_No: '', //  料号
        Sampling_type: '', //  抽样类型
        Inspection_Table: '', //  检验基准表
        Colour: '', // 颜色
        Shape: '', // 形状
        Text1: '', // 检验内容
        Text2: '', // 检验内容
        Text3: '', // 检验内容
        Text4: '', // 检验内容
        Text5: '', // 检验内容
        Text6: '', // 检验内容
        Text7: '', // 检验内容
        Determine1: '', // 判定
        Determine2: '', // 判定
        Determine3: '', // 判定
        Determine4: '', // 判定
        Determine5: '', // 判定
        Determine6: '', // 判定
        Determine7: '', // 判定
        Determine8: '', // 判定
        Determine9: '', // 判定
        Determine10: '', // 判定
        Remarks1: '', // 备注
        Remarks2: '', // 备注
        Remarks3: '', // 备注
        Remarks4: '', // 备注
        Remarks5: '', // 备注
        Remarks6: '', // 备注
        Remarks7: '', // 备注
        Remarks8: '', // 备注
        Remarks9: '', // 备注
        Remarks10: '', // 备注
        Yqi1: '', // 测试仪器编号1
        Lot_NO: '', // LOT_NO
        SHz: '', // iqc签核人
        SJyy: '', // iqc检验员
        Parcss: [], // 检验数据
        Parcss1: [] // 检验数据
      },
      isdata: true,
      dialogVisible: false,
      value: localStorage.getItem('locale') || '',
      isqpisboot: false
    }
  },
  created () {
    if (this.$store.state.UserData === null) {
      this.TabComponentFun('IQCSeleItem')
      this.$message.error(this.$t('All.模版不存在'))
    } else {
      this.isdata = false
      this.form = this.$store.state.UserData
      this.form.Jyy = this.$store.state.Login.Username
      this.form.Region = this.$store.state.Login.Factory
      this.form.Timsdate = this.getCurrentTime()
      this.FQCNumerb(this.form.Numbers)
      this.form.Shape = this.form.Shape === '' || this.form.Shape === null || this.form.Shape === undefined ? this.$t('IQC.形状与样品一致') : this.form.Shape
      if (this.form.Parcss1 !== null) {
        this.form.Parcss = this.form.Parcss1
        for (let index = 0; index < this.form.Parcss.length; index++) {
          this.form.Parcss[index].Numbers = this.form.Numbers
        }
      }
      this.form.Parcss = this.$PublicJs.Parcss(this.form.Parcss)
      this.form.Parcss = this.$PublicJs.Parcss(this.form.Parcss)
    }
  },
  components: {
    iqcUpload
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['UserData']),
    ...mapMutations(['FQCNumerb', 'TabComponentFun']),
    handleClose (done) {
      this.$confirm(this.$t('All.确认关闭'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    getCurrentTime () {
      // 获取当前时间并打印
      const yy = new Date().getFullYear()
      const mm = new Date().getMonth() + 1
      const dd = new Date().getDate()
      const gettime = yy + this.$t('All.年') + mm + this.$t('All.月') + dd + this.$t('All.日')
      return gettime
    },
    async IQC_User (row, index) {
      // if (this.form.Sampling_type.length <= 0 || this.form.Sampling_type !== null || this.form.Order_Number.length <= 0 || this.form.Determine2.length <= 0 || this.form.Determine3.length <= 0 || this.form.Determine4.length <= 0 || this.form.Determine5.length <= 0 || this.form.Determine6.length <= 0 || this.form.Determine7.length <= 0 || this.form.Determine8.length <= 0 || this.form.Determine9.length <= 0 || this.form.Determine11.length <= 0 || this.form.Determine12.length <= 0) {
      //  return this.$message.error('请判定后在提交')
      // }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        row.State = index
        row.Department = 'IQC'
        const { data: res } = await this.$http.post('/api/User/IQCUserAdd', row)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(this.$t('All.数据异常'))
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.提交成功'))
        this.UserData(null)
        this.TabComponentFun('IQCSeleItem')
      } catch (error) {
        loadingInstance.close()
        alert(this.$t('All.网络异常'))
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
  min-width: 903px;
}
  .el-col {
    border: 1px solid;
    margin-right: -1px;
     margin-bottom: -1px;
    line-height: 4.5vw;
    font-size: 2vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .grid-content {
    min-height: 36px;
  }
  .bg-purple-light{

  }
  .row-bg {
    background-color: #f9fafc;
  }
</style>
