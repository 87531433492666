<template>
  <div class="Examine">
    <div style="position: fixed;top: 5px;right: 10px;">
      <el-button @click="$router.push('/IQCSeleItemadminfqc')" type="primary">{{this.$t('system.新增')}}</el-button>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" :label="this.$t('system.序号')" type="index" width="60">
          </el-table-column>
          <el-table-column align="center" prop="Date" :label="this.$t('system.创建日期')" width="180">
          </el-table-column>
          <el-table-column align="center" prop="Item_No" :label="this.$t('system.料号')">
          </el-table-column>
          <el-table-column align="center" prop="Product_name" :label="this.$t('system.品名规格')">
          </el-table-column>
          <el-table-column align="center" prop="Enteredby" :label="this.$t('system.lururen')" >
          </el-table-column>
          <el-table-column align="center" :label="this.$t('system.操作')" width="100" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" @click="AdminDataSelect(scope.row.Item_No)" type="primary">Review</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      tableData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      value: localStorage.getItem('locale') || ''
    }
  },
  created () {
    this.IQCAdminData(this.pageindex, this.page_size)
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData']),
    ...mapMutations(['Productname']),
    async IQCAdminData (index, size) {
      const { data: res } = await this.$http.get(`/api/IQC/IQCAdminDatafqc?pageindex=${index}&pageSize=${size}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.tableData = res.response.TyIQC
      this.$message.success(res.msg)
    },
    handleSizeChange (val) {
      this.page_size = val
      this.IQCAdminData(this.pageindex, this.page_size)
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.IQCAdminData(this.pageindex, this.page_size)
    },
    async AdminDataSelect (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.get(`/api/IQC/IQCDataSelectadminfqc?Name=${row}`)
        if (res.status !== 200) {
          loadingInstance.close()
          this.UserData(null)
          this.$message.success(this.$t('All.数据不存在'))
          return
        }
        loadingInstance.close()
        this.$message.success(this.$t('All.即将进入编辑页面'))
        this.Productname(this.Noitem)
        this.UserData(res.response)
        this.TabComponentFun('IQCAdminFqc')
      } catch (error) {
        loadingInstance.close()
        this.$message.error(error)
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .Examine {
    position: absolute/fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    position: absolute;
    height: 80%;
    width: 90%;
    overflow: auto;
  }

</style>
