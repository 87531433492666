<template>
  <div class="zhadminadd">
    <div style="position: fixed;top: 10px;right: 1%; z-index: 1000;">
      <el-button style="text-align: right;" v-if="!isdata"  type="primary" @click="ZhAdminAdd(form)">{{ $t('All.提交') }}</el-button>
      <el-button style="text-align: right;" v-if="isdata"  type="primary" @click="ZhAdminDataUp(form)">{{$t('All.修改')}}</el-button>
      <el-button @click="$router.back(-1)" type="warning">Return</el-button>
      <el-button type="danger" @click="$PublicJs.Eixt()">Exit</el-button>
    </div>
    <h2 style="text-align: center;">{{$t('All.年益实业股份有限公司') }}</h2>
    <h3 style="text-align: center;">{{$t('All.生产巡检报表') }}（{{$t('All.腾耀') }}）</h3>
    <h4 style="text-align: center;">{{$t('All.录入人') }}：{{this.$store.state.Login.Username}}</h4>
    <div style=" padding: 10px; text-align: center; line-height: 2vw;">
      <table border="1"  align="center" cellpadding="10" style="width: 100%;"  cellspacing="0">
          <tr>
              <td style="min-width:50px">{{$t('All.制造班别') }}</td>
              <td>{{$t('All.客户') }}</td>
              <td>{{$t('All.检验日期') }}</td>
              <td>{{$t('All.内部订单号码') }}</td>
              <td>P/O NO</td>
              <td>{{$t('All.料号') }}</td>
              <td>{{$t('All.品名') }}</td>
          </tr>
          <tr>
              <td class="tdd"><el-input ></el-input></td>
              <td class="tdd"><el-input ></el-input></td>
              <td class="tdd"><el-input ></el-input></td>
              <td class="tdd"><el-input ></el-input></td>
              <td class="tdd"><el-input ></el-input></td>
              <td class="tdd"><el-input ></el-input></td>
              <td class="tdd"><el-input ></el-input></td>
          </tr>
          <tr>
            <td colspan="3">{{$t('All.参考文件') }}</td>
            <td>{{$t('All.出货数量') }}</td>
            <td>{{$t('All.重量') }}</td>
            <td>{{$t('All.箱数') }}</td>
            <td>{{$t('All.实际出货数量') }}</td>
          </tr>
          <tr>
            <td colspan="3"><el-input ></el-input></td>
            <td><el-input ></el-input></td>
            <td><el-input ></el-input></td>
            <td><el-input ></el-input></td>
            <td><el-input ></el-input></td>
          </tr>
          <tr>
            <td colspan="1">{{$t('All.检验项目') }}</td>
            <td colspan="6">{{$t('All.检验结果') }}</td>
          </tr>
          <tr>
            <td colspan="1">{{$t('All.随货文件') }}</td>
            <td colspan="6">
              <el-checkbox-group v-model="checkList">
                <el-checkbox label="Invoice"></el-checkbox>
                <el-checkbox label="Packing List"></el-checkbox>
                <el-checkbox :label="$t('All.FQC检验文件')"></el-checkbox>
                <el-checkbox :label="$t('All.其它')" disabled></el-checkbox>
              </el-checkbox-group>
            </td>
          </tr>
          <tr>
            <td colspan="1">{{$t('All.唛头内容') }}</td>
            <td colspan="6">
              <el-radio v-model="radio" label="1">{{$t('All.符合出货资料') }}</el-radio>
              <el-radio v-model="radio" label="2">{{$t('All.不符合出货资料') }}</el-radio>
            </td>
          </tr>
          <tr>
            <td colspan="1">{{$t('All.包装外观') }}</td>
            <td colspan="6">
              <el-checkbox-group v-model="checkList1">
                <el-checkbox :label="$t('All.整洁无破损')"></el-checkbox>
                <el-checkbox :label="$t('All.脏污')"></el-checkbox>
                <el-checkbox :label="$t('All.破损')"></el-checkbox>
                <el-checkbox :label="$t('All.其它')"></el-checkbox>
              </el-checkbox-group>
            </td>
          </tr>
          <tr>
            <td colspan="1">{{$t('All.箱体外观') }}</td>
            <td colspan="6">
              <el-checkbox-group v-model="checkList2">
                <el-checkbox :label="$t('All.整洁无破损')"></el-checkbox>
                <el-checkbox :label="$t('All.脏污')"></el-checkbox>
                <el-checkbox :label="$t('All.破损')"></el-checkbox>
                <el-checkbox :label="$t('All.其它')"></el-checkbox>
              </el-checkbox-group>
            </td>
          </tr>
          <tr>
            <td colspan="1">{{$t('All.外箱标签') }}</td>
            <td colspan="6">
              <el-radio v-model="radio1" label="1">{{$t('All.符合出货资料及包装图面要求') }}</el-radio>
              <el-radio v-model="radio1" label="2">{{$t('All.不符合出货资料及包装图面要求') }}</el-radio>
            </td>
          </tr>
          <tr>
            <td colspan="1">{{$t('All.判定结果') }}</td>
            <td colspan="6">
              <el-radio v-model="radio2" label="1">Accept</el-radio>
              <el-radio v-model="radio2" label="2">Reject</el-radio>
            </td>
          </tr>
          <tr>
            <td colspan="1">{{$t('All.备注') }}</td>
            <td colspan="6">
              <el-input ></el-input>
            </td>
          </tr>
          <tr>
            <td colspan="7">{{$t('All.出货图片') }}</td>
          </tr>
          <tr>
            <td colspan="7">
              <el-image :src="src"></el-image>
            </td>
          </tr>
           <tr style="border: 0 solid #fff0;">
              <td>{{$t('All.核准') }}</td>
              <td><el-input ></el-input></td>
              <td>{{$t('All.检验员') }}</td>
              <td><el-input ></el-input></td>
              <td></td>
              <td></td>
              <td>NYS2413-01 A</td>
          </tr>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      form: {
        Manufacturing: '', // 制造班别
        Customer: '', // 客户
        Productname: '', // 品名
        Three: '', // 三位吗
        Drawingnumber: '', // 图面编号
        Number: '', // 入库箱数
        Benchmark: '', // 抽箱基准
        Reference: '', // 参考文件
        Determine: '', // 判定
        Enteredby: this.$store.state.Login.Username // 录入姓名
      },
      dialogVisible: false,
      dialogImageUrl: '',
      filedata: {
        Workorder: ''
      },
      fileList1: [],
      fileList2: [],
      fileList3: [],
      fileList4: [],
      isdata: false,
      checkList: [],
      checkList1: [],
      checkList2: [],
      radio: '',
      radio1: '',
      radio2: '',
      src: 'https://cube.elemecdn.com/6/94/4d3ea53c084bad6931a56d5158a48jpeg.jpeg'
    }
  },
  created () {
  },
  mounted () {

  },
  methods: {
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
}
  .el-col {
    border: 1px solid;
    margin-right: -1px;
     margin-bottom: -1px;
    line-height: 4.5vw;
    font-size: 2vw;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space:nowrap;
  }
  .grid-content {
    min-height: 36px;
  }
  .bg-purple-light{

  }
  .row-bg {
    background-color: #f9fafc;
  }
</style>
