<template>
  <div class="wboby">
    <div class="neiboby">
      <el-row :gutter="20" style="margin-top: 10%;">
        <el-col :span="6"><div @click="FQC" class="grid-content"><samp style="line-height: 12vw; word-break:keep-all;iverflow:hidden;text-overflow:ellipsis;">{{this.$t('system.FQC数据')}}</samp></div></el-col>
        <el-col :span="6"><div @click="OQC" class="grid-content"><samp style="line-height: 12vw; word-break:keep-all;iverflow:hidden;text-overflow:ellipsis;">{{this.$t('system.OQC数据')}}</samp></div></el-col>
        <el-col :span="6"><div class="grid-content"><samp style="line-height: 12vw; word-break:keep-all;iverflow:hidden;text-overflow:ellipsis;">{{this.$t('system.IPQC数据')}}</samp></div></el-col>
        <el-col :span="6"><div @click="IQC" class="grid-content"><samp style="line-height: 12vw; word-break:keep-all;iverflow:hidden;text-overflow:ellipsis;">{{this.$t('system.IQC数据')}}</samp></div></el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      User: [],
      value: localStorage.getItem('locale') || ''
    }
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
    this.User = this.$store.state.Login
  },
  mounted () {

  },
  methods: {
    FQC () {
      this.$router.push('/FQCQuery')
    },
    OQC () {
      this.$router.push('/OQCQuery')
    },
    IQC () {
      this.$router.push('/IQCQuery')
    }
  }
}
</script>
<style lang="less" scoped>
  .wboby{
    left:0;
    right:0;
    top:0;
    bottom:0;
    margin:auto;
    position: fixed;
    width: 80%;
  }
  .grid-content {
    margin: 0 auto;
    left:0;
    right:0;
    top:0;
    bottom:0;
    font-size: 2vw;
    color: #0a3596;
    text-align: center;
    width: 12vw;
    height: 12vw;
    border-radius: 100%;
    padding: 10px;
    background-color: rgba(239, 243, 247, 0.40);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    cursor:default;
  }
  .grid-content:hover{
    margin: 0 auto;
    left:0;
    right:0;
    top:0;
    bottom:0;
    font-size: 2.1vw;
    color: #0a3596;
    text-align: center;
    line-height: 600%;
    width: 12vw;
    height: 12vw;
    border-radius: 100%;
    padding: 10px;
    background-color: rgba(6, 130, 255, 0.616);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    cursor:default;
  }
  .exit {
    color:#FFF;
    border: 1px solid rgba(249, 0, 0, 0.35);
    background-color: rgba(249, 0, 0, 0.35);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .exit:hover{
    color:#FFF;
    border: 1px solid rgba(249, 0, 0, 0.548);
    background-color: rgba(249, 0, 0, 0.548);
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
</style>
