<template>
  <div class="wbobye">
    <div :style="isqpisboot ? 'position: fixed;right: 10px;top: 5px z-index: 1000;': 'position: fixed; top: 55px;right: 10px; z-index: 1000;'">
      <el-button style="text-align: right;" size="mini"  type="primary" @click="listgoqr()">扫描</el-button>
    </div>
    <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
    <h4 style="text-align: center;">检验PASS单</h4>
    <div class="formclass">
      <el-form ref="elForm"  label-position="left" :model="formData" size="medium" label-width="90px">
      <el-form-item :label="$t('All.打印单号')">
        {{formData.Serialcode}}
      </el-form-item>
      <el-form-item :label="$t('All.操作日期')">
        {{formData.Date}}
      </el-form-item>
      <el-form-item :label="$t('All.状态')">
        <el-tag :type="formData.State === 0?'success':'danger'" effect="dark">{{ formData.State === 0?'有效':'失效' }}</el-tag>
      </el-form-item>
      <el-form-item :label="$t('All.检验日期')">
        {{formData.Time}}
      </el-form-item>
      <el-form-item :label="$t('All.料号')">
        {{formData.Item_No}}
      </el-form-item>
      <el-form-item :label="$t('All.采购单')">
        {{formData.Order_Number}}
      </el-form-item>
      <el-form-item :label="$t('All.供应商')">
        {{formData.Supplier}}
      </el-form-item>
      <el-form-item :label="$t('All.总数量')">
        {{formData.Warehousing}}
      </el-form-item>
      <el-form-item :label="$t('All.箱') +'/'+ $t('All.袋')">
        {{formData.Box}}
      </el-form-item>
      <el-form-item :label="$t('All.数量')">
        {{formData.Boxquantity}}
      </el-form-item>
      <el-form-item :label="$t('All.拆分次数')">
        {{formData.Count}}
      </el-form-item>
      <el-form-item v-if="false" :label="$t('All.打印机')">
          <el-select v-model="formData.Printer" :placeholder="$t('All.请选择打印机')">
            <el-option
              v-for="item in this.$store.state.PrinterData"
              :key="item.Name"
              :label="item.Wz"
              :value="item.Name">
          </el-option>
          </el-select>
        </el-form-item>
      <el-form-item v-if="false" size="large">
        <el-button type="primary" @click="NewTracefenbao" >{{ $t('All.提交') }}</el-button>
      </el-form-item>
      <el-form-item>
        <el-button style="width: 300px;" @click="listgo(formData.Numbers)" type="primary">查看入料检验单</el-button>
      </el-form-item>
    </el-form>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      formData: {},
      isqpisboot: false,
      isyl: false
    }
  },
  computed: {},
  watch: {},
  created () {
    this.$i18n.locale = 'zh'
    localStorage.setItem('locale', 'zh')
    this.Subcontractset(this.$store.state.Qcrode)
  },
  mounted () {},
  methods: {
    ...mapMutations(['TabComponentFun']),
    /*
    async Isfenbao () {
      this.formData.UserData = this.$store.state.Login
      const { data: res } = await this.$http.post('/api/Trace/Tracefenbao', this.formData)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.TabComponentFun('Subcontract')
    },
    async NewTracefenbao () {
      this.formData.UserData = this.$store.state.Login
      const { data: res } = await this.$http.post('/api/Trace/NewTracefenbao', this.formData)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.$message.success(res.msg)
      this.TabComponentFun('Subcontract')
    }, */
    async Subcontractset (row) {
      const { data: res } = await this.$http.post(`/api/Trace/Subcontractset?Serialcode=${row}`)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.formData = res.response
      if (this.formData.State === 1) {
        this.isyl = true
        return this.$message.error(this.$t('All.二维码已失效'))
      }
      this.$message.success(res.msg)
    },
    listgo (row) {
      this.$router.push('/vnewiqc/' + row)
    },
    listgoqr () {
      this.$router.push('/Qrcodeviews')
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobye{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin: 0 auto;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: rgba(255, 255, 255);
  min-width: 400px;
}
.formclass{
  width: 400px;
  margin: 0 auto;
}
</style>
