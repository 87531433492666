<template>
  <div class="zhadminadd" style="min-width: 1148px;max-width: 1368px;margin: 0 auto;">
    <div style="position: fixed;top: 5px;right: 30px; z-index: 1000;">
      <el-button size="small" style="text-align: right;"  type="primary" @click="$router.go(-1)">返回</el-button>
      <el-button size="small" style="text-align: right;"  type="primary" v-print="'#printTest'">打印</el-button>
      <el-button size="small" type="primary" v-if="isdisabled"   @click="IsUpload(3,FileList3)">{{this.$t('system.chakanfj')}}</el-button>
    </div>
     <h2 style="text-align: center;">{{this.$t('system.company')}}</h2>
     <h3 style="text-align: center;">{{this.$t('system.jinliaojy')}}</h3>
     <h4 style="text-align: center;">{{this.$t('system.lururen')}}：{{form.Enteredby}}</h4>
     <h4 style="text-align: center;">{{this.$t('system.系统单号')}}：{{form.Numbers}}</h4>
     <div style="text-align: center;">
      <span style="text-align: center;font-size: 28px;color: red;">{{$PublicJs.State(form.State)}}</span>
    </div>
     <p v-if="!isdisabled" style="text-align: right;padding-right: 20px;">进料日期：<el-date-picker
            v-model="form.Inspection_No"
            type="date"
            placeholder="选择日期"
            format="yyyy 年 MM 月 dd 日"
            value-format="yyyy-MM-dd">
          </el-date-picker>
      </p>
     <h4 v-if="isdisabled" style="text-align: right;padding-right: 20px; ">进料日期：{{formatDate(form.Inspection_No)}}</h4>
     <div style=" padding: 10px; text-align: center;">
       <table border="1"  align="center" cellpadding="10" style="table-layout: fixed; word-break: break-all; word-wrap: break-word;width: 100%;" >
           <tr>
              <td style="width: 202px;" colspan="2">{{this.$t('IQC.检验基准表')}}</td>
              <td style="width: 102px;">{{this.$t('IQC.工程图面')}}</td>
              <td style="width: 102px;">{{this.$t('IQC.承认书')}}</td>
              <td  colspan="2">{{this.$t('IQC.品名')}}</td>
              <td  colspan="2">{{this.$t('IQC.料号')}}</td>
              <td  colspan="2">{{this.$t('IQC.供应商')}}</td>

           </tr>
           <tr>
            <td colspan="2" class="tdd">
                <el-tooltip v-show="form.Inspection_Table !== ''" effect="dark" :content="form.Inspection_Table === '' && FileList0.length == 1 ? FileList0[0].name: form.Inspection_Table" placement="top-start">
                  <div style="width: 202px;overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;margin: 0 auto;">
                    <span @click="fileopen(FileList0[0].url)">{{FileList0.length == 1 ? FileList0[0].name: form.Inspection_Table}}</span>
                  </div>
                </el-tooltip>
              </td>
              <td class="tdd">
                <el-tooltip v-show="form.Drawing_Surface !== ''" effect="dark" :content="form.Drawing_Surface === '' && FileList1.length == 1 ? FileList1[0].name: form.Drawing_Surface" placement="top-start">
                    <div style="width: 102px;overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;margin: 0 auto;">
                      <span @click="fileopen(FileList1[0].url)">{{form.Drawing_Surface === '' && FileList1.length == 1 ? FileList1[0].name: form.Drawing_Surface}}</span>
                    </div>
                </el-tooltip>
              </td>
              <td class="tdd">
                <el-tooltip v-show="form.Acknowledgment !== ''" effect="dark" :content="form.Acknowledgment === '' && FileList2.length == 1 ? FileList2[0].name: form.Acknowledgment" placement="top-start">
                  <div style="width: 102px;overflow: hidden;white-space: nowrap;cursor: default;text-overflow: ellipsis;margin: 0 auto;">
                      <span @click="fileopen(FileList2[0].url)">{{form.Acknowledgment === '' && FileList2.length == 1 ? FileList2[0].name: form.Acknowledgment}}</span>
                  </div>
                </el-tooltip>
              </td>
            <td colspan="2" class="tdd">{{ form.Product_name }}</td>
            <td colspan="2" class="tdd">{{ form.Item_No }}</td>
            <td colspan="2" class="tdd">{{ form.Supplier }}</td>
           </tr>
           <tr>
             <td colspan="2">标准样品编号</td>
             <td >物料单位</td>
             <td >{{this.$t('IQC.交货数')}}</td>
             <td >{{this.$t('IQC.入库数')}}</td>
             <td >{{this.$t('IQC.抽样数')}}</td>
             <td colspan="2">{{this.$t('IQC.抽样类型')}}</td>
             <td colspan="2">{{this.$t('IQC.订单号')}}</td>
           </tr>
           <tr>
             <td  colspan="2" class="tdd">{{ form.Standard_sample }}</td>
             <td class="tdd">{{ form.Company }}</td>
             <td class="tdd">{{form.Delivery  }}</td>
             <td class="tdd">{{ form.Warehousing}}</td>
             <td class="tdd">{{ form.Sampling }}</td>
             <td  colspan="2" class="tdd">{{ form.Sampling_type }}</td>
             <td  colspan="2" class="tdd">{{ form.Order_Number }}</td>
           </tr>
           <tr>
             <td colspan="10"><el-input disabled v-model="form.Standard"><template slot="prepend">{{this.$t('IQC.执行标准')}}</template></el-input></td>
           </tr>
           <tr>
             <td >{{this.$t('IQC.检验项目')}}</td>
             <td colspan="2">{{this.$t('IQC.检验标准')}}</td>
             <td colspan="1">检验工具</td>
             <td colspan="2">判定基准</td>
             <td colspan="1">{{this.$t('All.实验频率') }}</td>
             <td colspan="1" class="tdd">{{this.$t('IQC.判定')}}</td>
             <td colspan="2">{{this.$t('IQC.备注')}}/{{ $t('All.测试报告') }}</td>
           </tr>
           <tr v-for="(item, index) in form.Testlist" :key="'Testlist' + index">
             <td style="text-align: left;"><span v-html="item.Title"></span></td> <!--:rowspan="item.Index" v-if="(item.Index !== 0)"-->
             <td colspan="2" style="text-align: left;"><span v-html="item.Standard"></span></td>
             <td colspan="1" style="text-align: left;"><span v-html="item.Tool"></span></td>
             <td colspan="2" style="text-align: left;"><span v-html="item.Benchmark"></span></td>
             <td colspan="1">{{$PublicJs.Frequencyfun(item.Frequency) }}</td>
             <td colspan="1">  <span v-html="item.Determine"></span></td>
             <td colspan="2">
                <el-input type="textarea" v-if="(item.Determinetype == 0)" :rows="3" v-model="item.Remarks"></el-input>
                <el-link @click="lookshiyan(item.Remarks,index)" type="primary" v-if="item.Determinetype == 1 && item.Remarks.length > 0 && item.Remarks != ''" >{{item.Remarks}}</el-link>
             </td>
            </tr>
           <tr>
               <td class="tdd" rowspan="2">{{this.$t('IQC.规格要求')}}</td>
               <td class="tdd" colspan="2">{{this.$t('IQC.量测仪器编号')}}</td>
               <td colspan="7" :disabled="isdisabled" class="tdd"><el-input :disabled="isdisabled" v-model="form.Measuring"></el-input></td>
           </tr>
           <tr>
              <td colspan="1" class="tdd" >单位/标注</td>
              <td colspan="5" class="tdd" >{{this.$t('IQC.尺寸检验记录')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最大值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.最小值')}}</td>
              <td colspan="1" class="tdd">{{this.$t('IQC.判定')}}</td>
           </tr>
           <tr v-for="(text, index) in form.NewIQCParcss" :key="index">
               <td class="tdd" style="min-width: 100px;">{{ text.Specification }}</td>
               <td class="tdd" style="min-width: 100px;">{{ text.Units_Dimensions }}</td>
               <td class="tdd" style="min-width: 100px;">{{ text.Text1 }}</td>
               <td class="tdd" style="min-width: 100px;">{{ text.Text2 }}</td>
               <td class="tdd" style="min-width: 100px;">{{ text.Text3 }}</td>
               <td class="tdd" style="min-width: 100px;">{{ text.Text4 }}</td>
               <td class="tdd" style="min-width: 100px;">{{text.Text5  }}</td>
               <td class="tdd" style="min-width: 100px;">{{ text.Max }}</td>
               <td class="tdd" style="min-width: 100px;">{{ text.Min }}</td>
               <td class="tdd">{{ text.Determine }}</td>
           </tr>
           <tr>
            <td colspan="10"><el-input type="textarea" disabled :rows="6" v-model="form.Beizhu"></el-input></td>
          </tr>
       </table>
       <el-row>
         <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.核准')}}: {{form.Approval}}</span></el-col>
         <el-col :span="12" style="border: 0px solid transparent;"><span style="font-size: 1.5vw;">{{this.$t('IQC.检验员')}}: {{form.Inspector}}</span></el-col>
       </el-row>
       <el-row>
         <el-col :span="12" style="border: 0px solid transparent;"><h6></h6></el-col>
         <el-col :span="12" style="border: 0px solid transparent;padding-right: 20px;text-align: right;"><span style="font-size: 1.5vw;">NYP1001-37-K</span></el-col>
       </el-row>
     </div>
    <iqcUpload  v-if="isboll" :Number = "form.Numbers" :Type = "Type" :FileList = "FileList" :FalseUpload="falseUpload" :Uphandsuccess="uphandsuccess"></iqcUpload>
  <NewiqcTestsheet v-if="Texttable" :formxin="form" :Test_Numbers="Test_Numbers" :Texttablebool="Texttablebool" :Test_Item="Test_Item" :Test_Index="Test_Index" :Test_Supplier="Test_Supplier" :Test_Product_name="Test_Product_name" :Texttablefunadd="Texttablefunadd"></NewiqcTestsheet>
<transition mode="out-in">
          <component :is="this.$store.state.ConductIs"></component>
        </transition>
</div>
 </template>
<script>
import iqcUpload from './Upload/NewiqcUserUpload.vue'
import NewiqcTestsheet from './Upload/NewiqcTestsheet'
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      form: {},
      isdisabled: true,
      isqpisboot: false,
      FileList: [],
      FileList0: [],
      FileList1: [],
      FileList2: [],
      FileList3: [],
      FileList4: [],
      Type: 0,
      Index: '',
      isboll: false,
      dialogVisibleprin: false,
      dialogVisibleprinjiji: false,
      Texttable: false,
      yzdialogVisible: false,
      chongying: false,
      Test_Item: '',
      Test_Index: '',
      Test_Supplier: '',
      Test_Product_name: '',
      Test_Numbers: '',
      Mz_Numbers: ''
    }
  },
  components: {
    iqcUpload,
    NewiqcTestsheet,
    ConductIs: () => import('../../views/ConductIs.vue')
  },
  created () {
    this.$i18n.locale = 'zh'
    localStorage.setItem('locale', 'zh')
    this.IQCUserGetAllFiles(this.$route.params.numbers)
    this.GetnewIQc(this.$route.params.numbers)
  },
  mounted () {
  },
  methods: {
    handleClose (done) {
      this.$confirm('确认关闭？')
        .then(_ => {
          done()
        })
        .catch(_ => {})
    },
    async GetnewIQc (row) {
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/GetnewIQc?Numbers=' + row)
        if (res.status !== 200) return this.$message.error('系统无检验表')
        this.form = res.response
      } catch (error) {
        alert(error)
      }
    },
    async GetSdaten (row) {
      try {
        const { data: res } = await this.$http.post('/api/Laboratory/GetSdaten?Numbers=' + row)
        if (res.status !== 200) return
        if (res.response == null) {
          return this.$message.error('系统暂无' + row + '的样品')
        } else {
          this.form.Standard_sample = res.response
        }
      } catch (error) {
        alert(error)
      }
    },
    IsUpload (type, filelist) {
      this.Type = type
      this.FileList = filelist
      this.isboll = true
    },
    falseUpload () {
      this.isboll = false
    },
    uphandsuccess (type, listdata, state) {
      switch (type) {
        case 0:
          this.form.Inspection_Table = state === 1 ? listdata[0].name : ''
          this.FileList0 = listdata
          break
        case 1:
          this.form.Drawing_Surface = state === 1 ? listdata[0].name : ''
          this.FileList1 = listdata
          break
        case 2:
          this.form.Acknowledgment = state === 1 ? listdata[0].name : ''
          this.FileList2 = listdata
          break
        case 3:
          this.FileList3 = listdata
          break
        default:
          break
      }
    },
    addDomain (item, index) {
      const contr = {
      //
        Number: item.Number,
        //
        Index: index + 1,
        //
        Specification: item.Specification,
        //
        Units_Dimensions: item.Units_Dimensions,
        //
        Text1: '',
        Text2: '',
        Text3: '',
        Text4: '',
        Text5: '',
        //
        Max: '',
        //
        Min: '',
        //
        Determine: ''
      }
      this.form.NewIQCParcss.splice(index + 1, 0, contr)
      for (let i = 0; i < this.form.NewIQCParcss.length; i++) {
        this.form.NewIQCParcss[i].Index = i
      }
    },
    removeDomain (item) {
      this.$confirm(this.$t('All.是否删除此节点'), this.$t('All.提示'), {
        confirmButtonText: this.$t('All.确认'),
        cancelButtonText: this.$t('All.取消'),
        type: 'warning'
      })
        .then(() => {
          var index = this.form.NewIQCParcss.indexOf(item)
          if (index !== -1) {
            this.form.NewIQCParcss.splice(index, 1)
          }
          for (let i = 0; i < this.form.NewIQCParcss.length; i++) {
            this.form.NewIQCParcss[i].Index = i
          }
        })
        .catch(() => {
        })
    },
    ...mapMutations(['UserData', 'TabComponentFun', 'ConductIs']),
    async Gettestbaogao () {
      try {
        const { data: res } = await this.$http.get('/api/Newiqc/Gettestbaogao?Numbers=' + this.Mz_Numbers)
        if (res.status !== 200) return this.$message.error(res.msg)
        this.form.Testlist[this.Mz_Index].Remarks = this.Mz_Numbers
        this.form.Testlist[this.Mz_Index].Determine = res.response.Results
        this.Mz_Numbers = ''
        this.Mz_Index = ''
        this.yzdialogVisible = false
      } catch (error) {
        alert(error)
      }
    },
    async IQCUserGetAllFiles (row) {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.get('/api/Newiqc/IQCUserGetAllFiles?Number=' + row + '&Factory=' + this.$store.state.Login.Factory)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        if (res.response.length > 0) {
          for (let index = 0; index < res.response.length; index++) {
            if (res.response[index].Type === 0) {
              this.FileList0.splice(0, 0, res.response[index])
            }
            if (res.response[index].Type === 1) {
              this.FileList1.splice(0, 0, res.response[index])
            }
            if (res.response[index].Type === 2) {
              this.FileList2.splice(0, 0, res.response[index])
            }
            if (res.response[index].Type === 3) {
              this.FileList3.splice(0, 0, res.response[index])
            }
            if (res.response[index].Type > 4) {
              this['FileList' + res.response[index].Type] = []
              this['FileList' + res.response[index].Type].splice(0, 0, res.response[index])
            }
          }
        }
      } catch (error) {
        this.$message.error(error)
        loadingInstance.close()
      }
    },
    async UserDataUp () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const { data: res } = await this.$http.post('/api/Newiqc/NewIQCuserup', this.form)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.msg)
        }
        loadingInstance.close()
        this.$message.success(res.msg)
        this.isdisabled = true
      } catch (error) {
        this.$message.error('服务器断开')
        loadingInstance.close()
      }
    },
    fileopen (url) {
      this.ConductIs('')
      this.$store.state.Url = url
      this.ConductIs('ConductIs')
      // window.open(url, '_blank')
    },
    Testbox (item, index) {
      this.Test_Item = item
      this.Test_Index = index
      this.Test_Supplier = this.form.Supplier
      this.Test_Product_name = this.form.Product_name
      this.Texttable = true
      this.Test_Numbers = ''
    },
    mzTestbox (item, index) {
      this.Mz_Index = index
      this.yzdialogVisible = true
    },
    Texttablebool () {
      this.Texttable = false
    },
    Texttablefunadd (index, row) {
      this.form.Testlist[index].Remarks = row
      // this.Texttable = false
    },
    lookshiyan (row, index) {
      if (row === '' || row.length < 0) return this.$message.error('请先填写送测单')
      if (this.isdisabled) {
        this.Test_Numbers = row
        this.Texttable = true
        return
      }
      this.$confirm('查看实验单？修改免做单？', this.$t('All.提示'), {
        cancelButtonText: '查看',
        confirmButtonText: '修改免做单号',
        type: 'warning'
      })
        .then(() => {
          this.Mz_Numbers = row
          this.Mz_Index = index
          this.yzdialogVisible = true
        })
        .catch(() => {
          this.Test_Numbers = row
          this.Texttable = true
        })
    },
    //  时间字符串格式化 yyyy-mm-dd
    formatDate (date) {
      var d = new Date(date)
      var month = '' + (d.getMonth() + 1)
      var day = '' + d.getDate()
      var year = d.getFullYear()
      if (month.length < 2) month = '0' + month
      if (day.length < 2) day = '0' + day
      return [year, month, day].join('-')
    }
  }
}
</script>
 <style lang="less" scoped>
   .wbobye{
   position:absolute/fixed;
   left:0;
   right:0;
   top:0;
   bottom:0;
   margin:auto;
   position: absolute;
   height: 100%;
   width: 100%;
   background-color: rgba(255, 255, 255);
   min-width: 903px;
 }
   .el-col {
     border: 1px solid;
     margin-right: -1px;
      margin-bottom: -1px;
     line-height: 4.5vw;
     font-size: 2vw;
     overflow: hidden;
     text-overflow: ellipsis;
     white-space:nowrap;
   }
   .grid-content {
     min-height: 36px;
   }
   .bg-purple-light{

   }
   .row-bg {
     background-color: #f9fafc;
   }
 </style>
