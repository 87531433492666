import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../components/Login.vue'
import Examine from '../components/Examine.vue'
import SeleFun from '../components/SeleFun.vue'
import FQCexaminedata from '../components/FQC/FQCexaminedata.vue'
import UserAdmin from '../components/UserAdmin.vue'
import FQCUser from '../components/FQC/FQCUser.vue'
import FQCAdmin from '../components/FQC/FQCAdmin.vue'
import FQCAdminData from '../components/FQC/FQCAdminData.vue'
import FQCSeleItem from '../components/FQC/FQCSeleItem.vue'
import FQCSeleItemAdmin from '../components/FQC/FQCSeleItemAdmin.vue'
import OQCUser from '../components/OQCUser.vue'
import OQCAdmin from '../components/OQCAdmin.vue'
import OQCAdminData from '../components/OQCAdminData.vue'
import OQCSeleItem from '../components/OQCSeleItem.vue'
import OQCSeleItemAdmin from '../components/OQCSeleItemAdmin.vue'
import SeleFunInput from '../components/SeleFunInput.vue'
import Query from '../components/Query.vue'
import OQCQuery from '../components/OQCQuery.vue'
import FQCQuery from '../components/FQC/FQCQuery.vue'
import IPQCAdmin from '../components/Ipqc/IPQCAdmin.vue'
import OQC1Admin from '../components/OQC1Admin.vue'
import IQC from '../components/IQC.vue'
import IQCUser from '../components/IQCUser.vue'
import IQCSeleItem from '../components/IQCSeleItem.vue'
import IQCSeleItemadmin from '../components/IQCSeleItemadmin.vue'
import IQCSeleItemadminfqc from '../components/IQCSeleItemadminfqc.vue'
import IQCQuery from '../components/IQCQuery.vue'
import IQCExamine from '../components/IQCExamine.vue'
import FQCUpload from '../components/FQC/Upload/FQCUpload.vue'
import FQCAdminUpload from '../components/FQC/Upload/FQCAdminUpload.vue'
import IQCUpload from '../components/Upload/IQCUpload.vue'
import IQCAdminUpload from '../components/Upload/IQCAdminUpload.vue'
import IQCAdmin from '../components/IQCAdmin.vue'
import IQCAdminFQC from '../components/IQCAdminFQC.vue'
import IQCAdminData from '../components/IQCAdminData.vue'
import IQCAdminDataFqc from '../components/IQCAdminDataFqc.vue'
import Main from '../components/Computer/Main.vue'
import Flowtable from '../views/Flowtable.vue'
import Flowxrftable from '../views/Flowxrftable.vue'
import Pass from '../views/Pass.vue'
import Qrcodeviews from '../views/Qrviews/Qrcodeviews.vue'
import Subcontractdataviews from '../views/Qrviews/Subcontractdataviews.vue'
import vnewiqc from '../views/IQCviews/vnewiqc.vue'
import Mobile from '../views/Mobile/Mobile.vue'
import CT from '../views/Biaoshika/CT.vue'
import ER from '../views/Biaoshika/ER.vue'
import ZL from '../views/Biaoshika/ZL.vue'
import CY from '../views/Biaoshika/CY.vue'
import ERP from '../views/ERP.vue'
import ERPDATA from '../views/ERPDATA.vue'
import Product from '../views/Product/Producttable.vue'
import Produchuizong from '../views/Product/Produchuizong.vue'
import Details from '../views/Product/Details.vue'
import Sancode from '../components/Sancode.vue'
import Jatvue from '../views/Jat/Jatvue.vue'
import Jatcustom from '../views/Jat/Jatcustom.vue'
import Loginyd from '../components/Loginyd.vue'
import Mobileyd from '../views/Mobile/Mobileyd.vue'
import FQCPass from '../views/Fqcdata/FQCPass.vue'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/Login'
  },
  {
    path: '/Loginyd',
    name: 'Loginyd',
    component: Loginyd
  },
  {
    path: '/Login',
    name: 'Login',
    component: Login
  },
  {
    path: '/FQCUser',
    name: 'FQCUser',
    component: FQCUser
  },
  {
    path: '/SeleFun',
    name: 'SeleFun',
    component: SeleFun
  },
  {
    path: '/FQCAdmin',
    name: 'FQCAdmin',
    component: FQCAdmin
  },
  {
    path: '/FQCSeleItem',
    name: 'FQCSeleItem',
    component: FQCSeleItem
  },
  {
    path: '/FQCSeleItemAdmin',
    name: 'FQCSeleItemAdmin',
    component: FQCSeleItemAdmin
  },
  {
    path: '/UserAdmin',
    name: 'UserAdmin',
    component: UserAdmin
  },
  {
    path: '/Examine',
    name: 'Examine',
    component: Examine
  },
  {
    path: '/FQCexaminedata',
    name: 'FQCexaminedata',
    component: FQCexaminedata
  }, {
    path: '/OQCAdmin',
    name: 'OQCAdmin',
    component: OQCAdmin
  }, {
    path: '/OQCUser',
    name: 'OQCUser',
    component: OQCUser
  }, {
    path: '/OQCSeleItem',
    name: 'OQCSeleItem',
    component: OQCSeleItem
  }, {
    path: '/OQCSeleItemAdmin',
    name: 'OQCSeleItemAdmin',
    component: OQCSeleItemAdmin
  }, {
    path: '/SeleFunInput',
    name: 'SeleFunInput',
    component: SeleFunInput
  }, {
    path: '/Query',
    name: 'Query',
    component: Query
  }, {
    path: '/OQCQuery',
    name: 'OQCQuery',
    component: OQCQuery
  }, {
    path: '/FQCQuery',
    name: 'FQCQuery',
    component: FQCQuery
  }, {
    path: '/FQCAdminData',
    name: 'FQCAdminData',
    component: FQCAdminData
  }, {
    path: '/OQCAdminData',
    name: 'OQCAdminData',
    component: OQCAdminData
  }, {
    path: '/IPQCAdmin',
    name: 'IPQCAdmin',
    component: IPQCAdmin
  }, {
    path: '/OQC1Admin',
    name: 'OQC1Admin',
    component: OQC1Admin
  }, {
    path: '/IQC',
    name: 'IQC',
    component: IQC
  }, {
    path: '/IQCUser',
    name: 'IQCUser',
    component: IQCUser
  }, {
    path: '/IQCSeleItem',
    name: 'IQCSeleItem',
    component: IQCSeleItem
  }, {
    path: '/IQCQuery',
    name: 'IQCQuery',
    component: IQCQuery
  }, {
    path: '/IQCExamine',
    name: 'IQCExamine',
    component: IQCExamine
  }, {
    path: '/FQCUpload',
    name: 'FQCUpload',
    component: FQCUpload
  }, {
    path: '/FQCAdminUpload',
    name: 'FQCAdminUpload',
    component: FQCAdminUpload
  }, {
    path: '/IQCUpload',
    name: 'IQCUpload',
    component: IQCUpload
  }, {
    path: '/IQCAdmin',
    name: 'IQCAdmin',
    component: IQCAdmin
  }, {
    path: '/IQCAdminFQC',
    name: 'IQCAdminFQC',
    component: IQCAdminFQC
  }, {
    path: '/IQCAdminData',
    name: 'IQCAdminData',
    component: IQCAdminData
  }, {
    path: '/IQCSeleItemadmin',
    name: 'IQCSeleItemadmin',
    component: IQCSeleItemadmin
  }, {
    path: '/IQCSeleItemadminfqc',
    name: 'IQCSeleItemadminfqc',
    component: IQCSeleItemadminfqc
  }, {
    path: '/IQCAdminDataFqc',
    name: 'IQCAdminDataFqc',
    component: IQCAdminDataFqc
  }, {
    path: '/IQCAdminUpload',
    name: 'IQCAdminUpload',
    component: IQCAdminUpload
  }, {
    path: '/Main',
    name: 'Main',
    component: Main
  }, {
    path: '/Flowtable/:numbers?',
    component: Flowtable
  }, {
    path: '/Flowxrftable/:numbers?',
    component: Flowxrftable
  }, {
    path: '/Pass/:numbers?',
    component: Pass
  }, {
    path: '/Qrcodeviews',
    component: Qrcodeviews
  }, {
    path: '/Subcontractdataviews',
    component: Subcontractdataviews
  }, {
    path: '/vnewiqc/:numbers?',
    component: vnewiqc
  }, {
    path: '/Mobile',
    name: 'Mobile',
    component: Mobile
  }, {
    path: '/Mobileyd',
    name: 'Mobileyd',
    component: Mobileyd
  }, {
    path: '/CT/:numbers?',
    component: CT
  }, {
    path: '/ER/:numbers?',
    component: ER
  }, {
    path: '/ZL/:numbers?',
    component: ZL
  }, {
    path: '/CY/:numbers?',
    component: CY
  }, {
    path: '/ERP/:numbers?',
    component: ERP
  }, {
    path: '/ERPDATA',
    name: 'ERPDATA',
    component: ERPDATA
  }, {
    path: '/Product',
    name: 'Product',
    component: Product
  }, {
    path: '/Produchuizong/:numbers?',
    component: Produchuizong
  }, {
    path: '/Details/:numbers?',
    component: Details
  }, {
    path: '/Sancode',
    name: 'Sancode',
    component: Sancode
  }, {
    path: '/Jatvue',
    name: 'Jatvue',
    component: Jatvue
  }, {
    path: '/Jatcustom',
    name: 'Jatcustom',
    component: Jatcustom
  }, {
    path: '/FQCPass/:numbers?',
    name: 'FQCPass',
    component: FQCPass
  }
]

const router = new VueRouter({
  routes
})
//  挂载路由导航守卫
router.beforeEach((to, from, next) => {
  // to 代表将要访问的路径
  // from 代表从哪个路径跳转而来
  // next 是一个函数，表示放行
  // next() 是放心 ，next('/login') 是强制跳转
  // mgindex/MJ1605510168
  if (to.path === '/login' || to.path === '/Login' || to.path === '/loginyd' || to.path === '/Loginyd' || to.path === '/Mobile' ||
          to.path === '/mobile' || to.path === '/vnewiqc/' + to.params.numbers ||
          to.path === '/Qrcodeviews' || to.path === '/qrcodeviews' ||
          to.path === '/Subcontractdataviews' || to.path === '/subcontractdataviews' ||
          to.path === '/flowtable/' + to.params.numbers || to.path === '/Flowtable/' + to.params.numbers ||
          to.path === '/flowxrftable/' + to.params.numbers || to.path === '/Flowxrftable/' + to.params.numbers ||
          to.path === '/Pass/' + to.params.numbers || to.path === '/pass/' + to.params.numbers ||
          to.path === '/FQCPass/' + to.params.numbers || to.path === '/fqcpass/' + to.params.numbers ||
          to.path === '/CT/' + to.params.numbers || to.path === '/CT/' + to.params.numbers ||
          to.path === '/ER/' + to.params.numbers || to.path === '/ER/' + to.params.numbers ||
          to.path === '/CY/' + to.params.numbers || to.path === '/CY/' + to.params.numbers ||
          to.path === '/ZL/' + to.params.numbers || to.path === '/ZL/' + to.params.numbers ||
          to.path === '/ERP/' + to.params.numbers || to.path === '/erp/' + to.params.numbers ||
          to.path.toLowerCase() === '/erpdata' || to.path.toLowerCase() === '/product' ||
          to.path === '/Produchuizong/' + to.params.numbers ||
          to.path === '/Details/' + to.params.numbers || to.path.toLowerCase() === '/sancode' || to.path.toLowerCase() === '/jatvue' || to.path.toLowerCase() === '/jatcustom'
  ) return next()
  // 获取token
  const tokenStr = window.sessionStorage.getItem('token')
  // 判断从客户端获取的token是否等于true，以下是判断tokenstr不等于true，意思就是false，如果等于false就跳转到登录页面
  if (!tokenStr) return next('/Login')
  // if (to.path === '/admin' || to.path === '/mgmould') {
  //  if (store.state.userjurisdiction > 3) return next(from.path)
  //  next()
  //  }
  next()
})
export default router
