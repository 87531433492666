<template>
  <div class="Examine">
    <div style="position: fixed;top: 55px;right: 10px; z-index: 1000;">
        <div id="wrappers">
          <div class="input-data">
              <input type="text" v-model="text" @keyup.enter="Setquer()" required>
              <div class="underline">
                <i class="el-icon-circle-close" v-show="text.length > 0" style="position: absolute;top: -30px;right: 60px;color:#9fa1a7;" @click="text = ''"></i>
                <el-button type="primary" style="position: absolute;top: -35px;right: 1px;" size="mini" icon="el-icon-search" @click="Setquer()"></el-button>
              </div>
              <label>{{$t('system.品名单号')}}</label>
          </div>
      </div>
    </div>
    <div>
      <template>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column align="center" :label="$t('All.序号')" type="index" width="90">
          </el-table-column>
          <el-table-column align="center" prop="Date" :label="$t('All.创建日期')" width="180">
          </el-table-column>
          <el-table-column align="center" prop="Numbers" :label="$t('All.系统单号')" width="180">
          </el-table-column>
          <el-table-column align="center" prop="Username" :label="this.$t('All.检验员')" >
          </el-table-column>
          <el-table-column align="center" prop="Product_name" :label="$t('All.品名规格')">
          </el-table-column>
          <el-table-column align="center" prop="Type" :label="this.$t('All.类型')" >
          </el-table-column>
          <el-table-column align="center" prop="State" :label="$t('All.状态')">
            <template slot-scope="scope">
                <samp>{{ $PublicJs.State(scope.row.State) }}</samp>
            </template>
          </el-table-column>
          <el-table-column align="center" :label="$t('All.操作')" width="100" fixed="right">
            <template slot-scope="scope">
              <el-button size="mini" v-if="scope.row.Type == 'OQC'" type="primary" @click="OQCChaYue(scope.row.Numbers)">Review</el-button>
            </template>
          </el-table-column>
        </el-table>
      </template>
    </div>
    <el-pagination
      style="text-align: center;"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageindex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="page_size"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Noitem: '',
      tableData: [],
      total: 0,
      page_size: 10,
      pageindex: 1,
      text: ''
    }
  },
  props: ['Addtab', 'RemoveTab', 'Renewtargename', 'Rendw'],
  created () {
    this.OQCQuery()
  },
  mounted () {

  },
  methods: {
    ...mapMutations(['UserData', 'TabComponentFun']),
    async OQCQuery () {
      const intfs = {
        Pageindex: this.pageindex,
        PageSize: this.page_size,
        UserData: this.$store.state.Login,
        Text: this.text
      }
      const { data: res } = await this.$http.post('/api/User/OQCQuery', intfs)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.total = res.response.TotalCount
      this.tableData = res.response.Pgapproval
    },
    Setquer () {
      this.pageindex = 1
      this.OQCQuery()
    },
    UserAdd () {
      this.TabComponentFun('FQCUser')
    },
    exit () {
      this.$router.push('/Login')
    },
    AdminAdd () {
      this.TabComponentFun('FQCAdmin')
    },
    async OQCChaYue (row) {
      const { data: res } = await this.$http.get('/api/User/OQCUserSelect?Numbers=' + row)
      if (res.status !== 200) return this.$message.error(res.msg)
      this.UserData(res.response)
      this.Addtab(this.$t('All.OQC记录') + '-' + row, 'OQCUser')
    },
    handleSizeChange (val) {
      this.page_size = val
      this.OQCQuery()
    },
    handleCurrentChange (val) {
      this.pageindex = val
      this.OQCQuery()
    }
  }
}
</script>
<style lang="less" scoped>
</style>
