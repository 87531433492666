<template>
  <div class="wbobyy">
    <div id="wrapper">
        <div class="input-data">
            <input type="text" v-model="Noitem" @keyup.enter="UserDataSelect(Noitem)" required>
            <div class="underline">
              <i class="el-icon-circle-close" v-show="Noitem.length > 0" style="position: absolute;top: -30px;right: 60px;color:#9fa1a7;" @click="Noitem = ''"></i>
              <el-button type="primary" style="position: absolute;top: -35px;right: 1px;" size="mini" icon="el-icon-search" @click="UserDataSelect(Noitem)"></el-button>
            </div>
            <label>{{$t('All.输入OQC品名')}}</label>
        </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from 'vuex'
export default {
  data () {
    return {
      Noitem: '',
      Numbers: ''
    }
  },
  created () {
    this.User = this.$store.state.Login
    this.QcCount()
  },
  mounted () {
  },
  methods: {
    ...mapMutations(['UserData', 'TabComponentFun']),
    async UserDataSelect (row) {
      const postdata = {
        Name: row,
        Factory: this.$store.state.Login.Factory
      }
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      const { data: res } = await this.$http.post('/api/Zh/AdminDataSelect', postdata)
      if (res.status !== 200) {
        loadingInstance.close()
        this.UserData(null)
        return this.$message.error(res.msg)
      }
      loadingInstance.close()
      this.$message.success(res.msg)
      res.response.Inspectiontime = this.$PublicJs.getCurrentTime()
      res.response.Numbers = this.Numbers
      res.response.Jyy = this.$store.state.Login.Username
      res.response.Department = this.$store.state.Login.Department
      res.response.State = null
      res.response.Hz = ''
      res.response.Region = this.$store.state.Login.Factory
      this.UserData(res.response)
      this.TabComponentFun('OQCUser')
    },
    async QcCount () {
      const loadingInstance = this.$loading({ text: this.$t('system.loading'), spinner: 'el-icon-loading', background: 'rgba(0, 0, 0, 0.8)' })
      try {
        const QcCountLogin = {
          Login: this.$store.state.Login,
          Type: 'OQC'
        }
        const { data: res } = await this.$http.post('/api/User/QcCount', QcCountLogin)
        if (res.status !== 200) {
          loadingInstance.close()
          return this.$message.error(res.response)
        }
        loadingInstance.close()
        this.Numbers = res.response
      } catch (error) {
        loadingInstance.close()
        alert(this.$t('All.网络异常'))
      }
    }
  }
}
</script>
<style lang="less" scoped>
  .wbobyy{
  position:absolute/fixed;
  left:0;
  right:0;
  top:0;
  bottom:0;
  margin:auto;
  position: absolute;
  height: 0px;
  width: 300px;
}
</style>
